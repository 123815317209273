import moment from "moment";
import React, { Component } from "react";
import CsvDownloader from "react-csv-downloader";

const file1Columns = [
  {
    id: "date",
    displayName: "Date (MM/DD/YYYY)",
  },
  {
    id: "NumOfVisits",
    displayName: "Number Of Visits",
  },
  {
    id: "AveSignToReg",
    displayName: "Average Sign in To Register",
  },
  {
    id: "AveRegToOrderActivation",
    displayName: "Average Register To Order Activation",
  },
  {
    id: "AveOrderActivationtoPendingCollection",
    displayName: "Average Order Activation To Pending Collection",
  },
  {
    id: "AvePendingCollectionToCallTime",
    displayName: "Average Pending Collection To Call Time",
  },
  {
    id: "AveCallTimetoCompletion",
    displayName: "Average Call Time To Completion",
  },
  {
    id: "AveTotalLabTime",
    displayName: "Average Total Lab Time",
  },
  {
    id: "AveTotalCompletion",
    displayName: "Average Total Completion",
  },
];

const file2Columns = [
  {
    id: "date",
    displayName: "Date (MM/DD/YYYY)",
  },
  {
    id: "RegisteredBY",
    displayName: "Registered By",
  },
  {
    id: "PFirstName",
    displayName: "Patient First Name",
  },
  {
    id: "PLastName",
    displayName: "Patient Last Name",
  },
  {
    id: "ReasonforVisit",
    displayName: "Reason for visit",
  },
];

class PatientsCSVDownloader extends Component {
  state = {};

  _generateFile1Data = () => {
    const { data: logs } = this.props;
    // Group by date
    const dateMappedObject = {};
    logs.forEach((log) => {
      const date = moment(log.patientData.UpdateDate).format("MM/DD/YYYY");
      if (!dateMappedObject[date]) {
        dateMappedObject[date] = [];
      }
      dateMappedObject[date].push({
        ...log,
        date,
      });
    });
    // Calculate avg time and total patient count
    const rows = [];
    Object.keys(dateMappedObject).forEach((date) => {
      const row = {
        date: date,
        NumOfVisits: dateMappedObject[date].length,
        AveSignToReg: this._calculateAverage(
          dateMappedObject[date],
          "signInToRegister"
        ),
        AveRegToOrderActivation: this._calculateAverage(
          dateMappedObject[date],
          "registerToOrderActivation"
        ),
        AveOrderActivationtoPendingCollection: this._calculateAverage(
          dateMappedObject[date],
          "orderActivationToPendingCollection"
        ),
        AvePendingCollectionToCallTime: this._calculateAverage(
          dateMappedObject[date],
          "pendingCollectionToCalled"
        ),
        AveCallTimetoCompletion: this._calculateAverage(
          dateMappedObject[date],
          "calledToCompleted"
        ),
        AveTotalLabTime: this._calculateAverage(
          dateMappedObject[date],
          "totalLabTime"
        ),
        AveTotalCompletion: this._calculateAverage(
          dateMappedObject[date],
          "totalCompletion"
        ),
      };
      rows.push(row);
    });
    // Sort rows
    const sortedRows = rows.sort((s1, s2) => {
      return moment.utc(s1.date).diff(moment.utc(s2.date));
    });
    return sortedRows;
  };

  _calculateAverage = (entries, timeKey) => {
    let totalTime = 0;
    entries.forEach((entry) => {
      if (entry.times[timeKey] && !isNaN(entry.times[timeKey])) {
        totalTime += entry.times[timeKey];
      }
    });
    return this._toFixedIfNecessary(totalTime / entries.length);
  };

  _generateFile2Data = () => {
    const { data: logs } = this.props;
    const rows = [];
    logs.forEach((log) => {
      const row = {
        date: moment(log.patientData.UpdateDate).format("MM/DD/YYYY"),
        RegisteredBY: log.registeredBy,
        PFirstName: log.firstName,
        PLastName: log.lastName,
        ReasonforVisit: "--",
      };
      rows.push(row);
    });
    // Sort rows
    const sortedRows = rows.sort((s1, s2) => {
      return moment.utc(s1.date).diff(moment.utc(s2.date));
    });
    return sortedRows;
  };

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  render() {
    const file1Data = this._generateFile1Data();
    const file2Data = this._generateFile2Data();
    return (
      <CsvDownloader
        filename="kiosk-patients-1"
        separator=","
        wrapColumnChar=""
        columns={file1Columns}
        datas={file1Data}
        text="DOWNLOAD"
      >
        <CsvDownloader
          filename="kiosk-patients-2"
          separator=","
          wrapColumnChar=""
          columns={file2Columns}
          datas={file2Data}
          text="DOWNLOAD"
        >
          {this.props.children}
        </CsvDownloader>
      </CsvDownloader>
    );
  }
}

export default PatientsCSVDownloader;
