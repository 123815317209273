import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./prompt.scss";

const Prompt = (props) => {
  const {
    isVisible = false,
    onCancel = () => {},
    onConfirm = () => {},
    headerText = "Confirmation",
    bodyText = "Are you sure to proceed?",
    confirmText = "Confirm",
    cancelText = "Cancel",
  } = props;
  return (
    <>
      <Modal
        visible={isVisible}
        width="400"
        height="280"
        effect="fadeInUp"
        onClickAway={onCancel}
      >
        <div id="promtWrapper">
          <h2>{headerText}</h2>
          <h4>{bodyText}</h4>
          <div className="actionWrapper">
            <button className="confirm" onClick={onConfirm}>
              {confirmText}
            </button>
            <button className="cancel" onClick={onCancel}>
              {cancelText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Prompt;
