import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToApp from "@material-ui/icons/ExitToApp";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import Button from "components/CustomButtons/Button.js";
import React, { useEffect, useState, useRef } from "react";
import MatHeader from "../../../../components/Header/Header.js";
import { authProvider } from "../../../../config/authProvider";
import Prompt from "../../../general/components/prompt/prompt";
import "./header.scss";
import { initiateDashboard } from "library-scripts/dashboard.js";
import { Link } from "react-router-dom";
import { extractQueryParams } from "helper-methods/index.js";
import { clearUserData } from "redux/actions/user-data.js";
import { store } from "redux/store.js";

const useStyles = makeStyles(navbarsStyle);

const Header = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLogoutPromptVisible, setISLogoutPromptVisible] = useState(false);
  const [params, setParams] = useState("");
  const classes = useStyles();
  const sidebarToggleBtnRef = useRef(null);

  const _logout = () => {
    setISLogoutPromptVisible(false);
    store.dispatch(clearUserData())
    authProvider.logout();
  };

  const _setParams = () => {
    const params = extractQueryParams();
    const clinicId = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "clinicid"
    );
    if (clinicId) {
      setParams(`?clinicId=${params[clinicId]}`);
    }
  };

  useEffect(() => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
    setTimeout(() => {
      initiateDashboard();
    }, 1000);
    setTimeout(() => {
      sidebarToggleBtnRef.current.click();
    }, 1100);
    _setParams();
  }, []);

  return (
    <div>
      <div className="wrapper ">
        <div
          className="sidebar"
          data-color="white"
          data-background-color="black"
          data-image="../assets/img/sidebar-1.jpg"
        >
          <div className="logo">
            <a href="#" className="simple-text logo-mini"></a>
            <a href="#" className="simple-text logo-normal" id="telehealthLogo">
              Keck
            </a>
          </div>
          <div className="sidebar-wrapper">
            <ul className="nav">
              <li className="nav-item active">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => e.preventDefault}
                >
                  <i className="material-icons">list</i>
                  <p> Registrations </p>
                </a>
              </li>
              <li className="nav-item">
                <Link to={"/orderactivation" + params} className="nav-link">
                  <i className="material-icons">how_to_reg</i>
                  <p> Order Activations </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/tv" + params}>
                  <i className="material-icons">tv</i>
                  <p> Tv Screen </p>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/manage-users" + params}>
                  <i className="material-icons">group</i>
                  <p> Manage Users </p>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="main-panel">
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
            <div className="container-fluid">
              <div className="navbar-wrapper">
                <div className="navbar-minimize">
                  <button
                    id="minimizeSidebar"
                    className="btn btn-just-icon btn-white btn-fab btn-round"
                    ref={sidebarToggleBtnRef}
                  >
                    <i className="material-icons text_align-center visible-on-sidebar-regular">
                      more_vert
                    </i>
                    <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">
                      view_list
                    </i>
                  </button>
                </div>
                <a className="navbar-brand" href="javascript:;">
                  Registrations
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
              </button>
              <div className="collapse navbar-collapse justify-content-end">
                <ul className="navbar-nav">
                  {userInfo && userInfo.account ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link"
                        href="javascript:;"
                        id="navbarDropdownProfile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">person</i>
                        {userInfo.account.name}
                        <p className="d-lg-none d-md-block">Account</p>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarDropdownProfile"
                      >
                        <a className="dropdown-item" href="#">
                          Profile
                        </a>
                        <a className="dropdown-item" href="#">
                          Settings
                        </a>
                        <div className="dropdown-divider" />
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setISLogoutPromptVisible(true);
                          }}
                        >
                          Log out
                        </a>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </nav>
          {/* End Navbar */}
          <div className="content">{props.children}</div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="#">Keck</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right"></div>
            </div>
          </footer>
        </div>
      </div>
      <Prompt
        isVisible={isLogoutPromptVisible}
        onCancel={() => setISLogoutPromptVisible(false)}
        onConfirm={_logout}
        bodyText={"Are you sure to logout?"}
        confirmText={"Logout"}
      />
    </div>
  );
};

export default Header;
