import React, { useEffect } from 'react';
import AzureAD from 'react-aad-msal';
import { authProvider } from 'config/authProvider';
import UserManagementTable from './containers/user-management-table/user-management-table';
import AccessGuard from 'guards/access-guard';

const UserManagementModule = props => {
  return (  
    <>
      <AzureAD provider={authProvider} forceLogin={true}>
      <AccessGuard accessLevel={['SuperAdmin', 'ClinicAdmin']}>
        <UserManagementTable />
        </AccessGuard>
      </AzureAD>
    </>
  );
}
 
export default UserManagementModule;