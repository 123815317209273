const korean = {
  firstname: "이름",
  lastname: "성",
  dob: "생년월일",
  dobformat: "날짜 형식: mm/dd/yyyy",
  phonenumber: "전화 번호",
  phoneformat: "전화 형식: 123-456-7890",
  hasappointment: "약속이 있습니까?",
  requiredfields: "* 필수 입력 사항",
  requiredwarning: "계속하기 전에 모든 필수 필드를 입력해야합니다",
  dobwarning:
    "생년월일이 잘못되었습니다. mm/dd/yyyy 형식으로 생년월일을 입력하십시오. 참고 : 연도 값은 1919 년과 올해 사이 여야합니다.",
  phonenowarning:
    "입력 한 전화 번호가 유효하지 않습니다. 유효한 전화 번호를 입력하세요",
  helpbtntext: "도움",
  homebutton: "집",
  scanid: "내 ID 스캔",
  next: "다음",
  providerinfotitle: "누구를 만나러 왔습니까?",
  providerinfotxt: "여기에있는 제공자를 선택하십시오.",
  successtitle: "성공적으로 로그인했습니다!",
  successtxt:
    "키오스크 시스템에 성공적으로 로그인했습니다. 직원이 곧 연락을 드릴 것이므로 자리에 앉으십시오.",
  helptitle: "키오스크 도움말",
  helptxt1: "도움이 필요하면 창으로 이동하여 전자 체크인을 취소해야합니다.",
  helptxt2: "전자 체크인을 계속하려면 닫기 버튼을 클릭하십시오.",
  helpclose: "닫기",
  visitReason: "방문 이유",
  previous: "이전",
  callnumberlabel: "4 자리 숫자 (서비스 알림을위한 고유 ID) ",
  callnowarning:
    "입력 한 전화 번호가 잘못되었습니다. 4 자리 전화 번호를 입력하세요.",
  startover: "다시 시작하다",
  donthavephonenumber: "전화 번호가 없습니다.",
  callnumberdisplaylabel: "귀하의 전화 번호는 ",
  waittext: "자리에 앉으시면 누군가 전화를드립니다",
};

export default korean;
