import {
  List,
  HowToReg,
  Group,
  Store,
  OndemandVideo,
  LibraryAddCheck,
  BrightnessHigh,
  AccountTree,
} from "@material-ui/icons";
export const faqData = [
  {
    tabButton: "Patient Checkin",
    tabIcon: List,
    tabData: [
      {
        title: "What information does a patient provide when checking in?",
        content:
          "Patients are asked to select a language and to enter for cell number, first name, last name, date of birth and reason for visit. Last four digits of cell number as used as the patients call number.",
      },
      {
        title: "What if patient does not have cell number?",
        content:
          "If a patient does not have a cell number, patient can enter a four digit code to act as their call number.",
      },
      {
        title: "What if the patient does not answer when called?",
        content:
          "If patient does not answer when called, the phlebotomist will mark the patient as 'No Answer'. When the patient returns and checks in again, the patient will be placed in the phlebotomist queue.",
      },
    ],
  },
  {
    tabButton: "Registration",
    tabIcon: HowToReg,
    tabData: [
      {
        title: "How do I know when a patient has checked in?",
        content:
          "When a patient checks in, their record will be listed in the Registration Portal under the 'To Be Registred' tab.",
      },
      {
        title: "How can I process the patient checkin?",
        content:
          "Registration staff can assign the patient visit to themselves by click on the button 'Assign to Myself' for the patient visit record in the tab 'To Be Registered'.",
      },
      {
        title: "How can I update the patient visit to be ready for order activation?",
        content:
          "Registration staff can update the patient visit record for order activation by entering a FIN, clicking on the gear icon in the patient visit record and selecting 'Ready For Order Activation'.",
      },
      {
        title: "What if I cannot complete the patient visit registraion?",
        content:
          "Registration staff can place the patient visit record back in the 'To Be Registered' queue by clicking on the gear icon in the patient visit record and selecting 'Return to registration queue'.",
      },
      {
        title: "What if there are issues with the order for the patient?",
        content:
          "Registration staff can update the patient visit record with order issues by clicking on the gear icon in the patient visit record and selecting 'Order Issues'. The patient visit record will stay the staff's registation queue.",
      },
    ],
  },
  {
    tabButton: "Order Activation",
    tabIcon: Group,
    tabData: [
      {
        title: "How do I know a patient's orders are ready to be activated?",
        content:
          "When registration staff updates the patient visit record to 'Ready For Order Activation', the patient visit record will be listed in the Order Activation portal.",
      },
      {
        title: "How Can I inform phlebotomist that the patient is ready for them to call?",
        content:
          "Order Activation staff can update the patient visit record with 'Pending Collection' by clicking on the gear icon in the patient visit record and selecting 'Pending Collection'",
      },
      {
        title: "What if there are order issues for Registration Staff to resolve?",
        content:
          "Order Activation staff can update the patient visit with 'Order Issues' by clicking on the gear icon in the patient visit record and selecting 'Order Issues'. The patient visit record will be returned to the registration queue and assigned to the staff member that registered patient.",
      },
      {
        title: "What if there are order issues for Order Activation Staff to resolve?",
        content:
          "Order Activation staff can update the patient visit with 'Lab Order Issues' by clicking on the gear icon in the patient visit record and selecting 'Lab Order Issues'. The patient visit record will stay in the Order Activation queue.",
      },
    ],
  },
  {
    tabButton: "Phlebotomist",
    tabIcon: Store,
    tabData: [
      {
        title: "How do I know patient is ready to be called?",
        content:
          "Go to the Phlebotomist portal and select your name. Patients ready to be called will be listed.",
      },
      {
        title: "How do I call a patient?",
        content:
          "Go to the Phlebotomist portal and select your name. Patients ready to be called will be listed. Click on the button 'Call' in the patient visit record.",
      },
      {
        title: "How do I complete the visit?",
        content:
         "Go to the Phlebotomist portal and select your name. Patient you called will be listed. Click on the button 'Complete' in the patient visit record.",
      },
      {
        title: "What if the patient does not answer?",
        content:
          "Go to the Phlebotomist portal and select your name. Patient you called will be listed. Click on the button 'No Answer' in the patient visit record.",
      },
    ],
  },
  {
    tabButton: "TV Screen",
    tabIcon: OndemandVideo,
    tabData: [
      {
        title: "What is shown on the TV Screen?",
        content:
          "Active(not completed) patient visits are displayed on the TV screen. They are broken up into two lists. Patient waiting to be called into the lab and patients in the lab.",
      },
      {
        title: "What is the difference between Stage Wait Time and Visit Wait Time?",
        content:
          "Stage wait time is the amount of time that has passed since the last status update for the patient visit. Visit wait time is the amount of time that has passed since the patient checkedin.",
      },
      {
        title: "Why can't I see complete patient names?",
        content:
          "Hospital policy dicitates that only the first three letters of the patient's first and last names are shown.",
      },
    ],
  },
  {
    tabButton: "Admin",
    tabIcon: AccountTree,
    tabData: [
      {
        title: "How can I be assigned additional roles (e.g phlebotomist, Registration, Order Activation)?",
        content:
          "Please email servicedesk for any requests.",
      },
      {
        title: "Where can I get more resources? ",
        content:
          "Visit for additional training",
      },
    ],
  },
];
