const tagalog = {
  firstname: "Pangalan",
  lastname: "Huling pangalan",
  dob: "Araw ng kapanganakan",
  dobformat: "Format ng Petsa: mm/dd/yyyy",
  phonenumber: "Numero ng telepono",
  phoneformat: "Format ng Telepono: 123-456-7890",
  hasappointment: "Magkaroon ng appointment?",
  requiredfields: "* Mga kailangang punan",
  requiredwarning:
    "Dapat mong ipasok ang lahat ng kinakailangang mga patlang bago magpatuloy",
  dobwarning:
    "Hindi wasto ang Petsa ng Kapanganakan. Mangyaring magpasok ng isang petsa ng kapanganakan gamit ang format ng: mm/dd/yyyy. Mangyaring tandaan: Ang halaga ng taon ay dapat na nasa pagitan ng 1919 at sa taong ito",
  phonenowarning:
    "Hindi wasto ang Numero ng Telepono. Mangyaring magpasok ng isang wastong numero ng telepono",
  helpbtntext: "Tulong",
  homebutton: "Bahay",
  scanid: "I-scan ang Aking Id",
  next: "Susunod",
  providerinfotitle: "Sino ka dito para makita?",
  providerinfotxt: "Mangyaring piliin ang provider na narito ka upang makita.",
  successtitle: "Matagumpay na nilagdaan Sa!",
  successtxt:
    "Matagumpay kang nag-sign in sa Kiosk System. Mangyaring magkaroon ng isang upuan dahil ang isang tao mula sa aming mga kawani ay makikipag-ugnay sa iyo sa madaling panahon!",
  helptitle: "Tulong sa Kiosk",
  helptxt1:
    "Kung kailangan mo ng tulong kailangan mong pumunta sa window at kanselahin ang iyong electronic checkin.",
  helptxt2:
    "Kung nais mong magpatuloy sa iyong elektronikong checkin, i-click ang pindutan ng Isara.",
  helpclose: "Isara",
  visitReason: "Dahilan para sa pagbisita",
  previous: "Dati",
  callnumberlabel:
    "Apat na digit na Numero (Natatanging ID na aabisuhan ka tungkol sa mga serbisyo) ",
  callnowarning:
    "Ang numero ng tawag na ipinasok ay hindi wasto. Mangyaring maglagay ng isang 4 na numero ng tawag",
  startover: "Start Over",
  donthavephonenumber: "WALA AKONG PHONE NUMBER",
  callnumberdisplaylabel: "Ang iyong numero ng tawag ay ",
  waittext: "Mangyaring paupo at may tumawag sa iyo",
};

export default tagalog;
