import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ClinicManagementModule from "./clinic-management-module";
import LabAssistantModule from "./lab-assistant-module/index";
import PhlebotomistModule from "./phlebotomist-module/index";
import RegistrationModule from "./registration-module/index";
import TvDisplayModule from "./tv-display";
import UserActivation from "./user-activation/index";
import UserManagementModule from "./user-management-module";
import VisitSystemsSettingsModule from "./visit-system-settings-module";
import DashboardModule from "./dashboard-module";
import StaticPage from "./general/pages/static-page/static-page";
import PatientStatusModule from "./patient-status-module";
import FaqModule from "./faq-module";
import ClinicSystemsSettingsModule from "./clinic-system-settings-module";
import TvTableCustomizer from "./tv-table-customizer";
import ScreenBuilder from "./screen-builder";
import DeviceDisplayModule from "./device-display-module";
import FLowCustomizer from "./flow-customizer";
import ManageAppsModule from "./manage-apps";

class Modules extends Component {
  state = {};
  render() {
    return (
      <>
        <Switch>
          <Route path="/index" component={RegistrationModule} />
          <Route path="/registration" component={LabAssistantModule} />
          <Route path="/patient-status" component={PatientStatusModule} />
          <Route path="/orderactivation" component={UserActivation} />
          <Route path="/phlebotomist" component={PhlebotomistModule} />
          <Route
            path="/manage-clinic-system-settings"
            component={ClinicSystemsSettingsModule}
            redirectRoute="/public/auth?type=login"
          />
          <Route
            path="/manage-flows"
            component={FLowCustomizer}
          />
          <Route path="/manage-users" component={UserManagementModule} />
          <Route path="/manage-clinics" component={ClinicManagementModule} />
          <Route path="/dashboard" component={DashboardModule} />
          <Route path="/tv" component={DeviceDisplayModule} />
          {/* <Route path="/device" component={DeviceDisplayModule} /> */}
          <Route path="/screen-builder" component={ScreenBuilder} />
          <Route path="/manage-tv-screen" component={TvTableCustomizer} />
          <Route path="/test" component={StaticPage} />
          <Route
            path="/manage-clinic-apps"
            component={ManageAppsModule}
          />
          <Route
            path="/manage-visit-options"
            component={VisitSystemsSettingsModule}
          />
          <Route path="/faqs" component={FaqModule} />
          <Route path="*" render={() => <Redirect to="/index" />} />
        </Switch>
      </>
    );
  }
}

export default Modules;
