import React, { Component } from "react";
import Modal from "react-awesome-modal";
import QuickPatientSearchPrompt from "modules/general/components/quick-patient-search-prompt/quick-patient-search-prompt";

class StaticPage extends Component {
  state = {};

  componentDidMount() {

  }
  render() {
    
    return (
      <>
        <QuickPatientSearchPrompt />
      </>
    );
  }
}

export default StaticPage;
