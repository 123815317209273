import React, { useEffect } from 'react';
import AzureAD from 'react-aad-msal';
import { authProvider } from 'config/authProvider';
import ClinicManagementTable from './containers/clinic-management-table/clinic-management-table';
import AccessGuard from 'guards/access-guard';
import PageRefresher from 'components/PageRefresher/PageRefresher';

const ClinicManagementModule = props => {
  return (  
    <>
      <AzureAD provider={authProvider} forceLogin={true}>
        <AccessGuard accessLevel={['SuperAdmin']}>
          <ClinicManagementTable {...props} />
        </AccessGuard>
      </AzureAD>
    </>
  );
}
 
export default ClinicManagementModule;