import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FlowEditor from "./pages/flow-editor/flow-editor";
import ManageFlows from "./pages/manage-flows/manage-flows";
import AccessGuard from '../../guards/access-guard';
import AzureAD from "react-aad-msal";
import { authProvider } from '../../config/authProvider';


class FlowCustomizer extends Component {
  state = {};
  componentDidMount() {
    this._determineViewFromParams();
  }

  _determineViewFromParams = () => {};

  render() {
    return (
      <>
      <AzureAD provider={authProvider} forceLogin={true}>
        <AccessGuard
          accessLevel={["SuperAdmin", "ClinicAdmin", "OrderActivation"]}
        >
        <Header>
          <Switch>
            <Route path="/manage-flows/builder" component={FlowEditor} />
            <Route path="/manage-flows" component={ManageFlows} />
            <Route path="*" render={() => <Redirect to="/manage-flows" />} />
          </Switch>
        </Header>
        </AccessGuard>
      </AzureAD>
      </>
    );
  }
}

export default FlowCustomizer;
