const armenian = {
  firstname: "Անուն",
  lastname: "Ազգանուն",
  dob: "Ծննդյան ամսաթիվ",
  dobformat: "Ամսաթվի ձևաչափը: mm/dd/yyyy",
  phonenumber: "Հեռախոսահամար",
  phoneformat: "Հեռախոսի ձևաչափը: 123-456-7890",
  hasappointment: "Նշանակո՞ւմ եք",
  requiredfields: "* պահանջվող դաշտերը",
  requiredwarning:
    "Շարունակելուց առաջ պետք է մուտքագրեք բոլոր պահանջվող դաշտերը",
  dobwarning:
    "Ծննդյան ամսաթիվը անվավեր է: Խնդրում ենք մուտքագրել ծննդյան ամսաթիվը ՝ օգտագործելով ձևաչափը : mm/dd/yyy Խնդրում ենք նկատի ունենալ, տարվա արժեքը պետք է լինի 1919-ից այս տարվա միջև",
  phonenowarning:
    "Մուտքագրված հեռախոսահամարը անվավեր է: Մուտքագրեք վավեր հեռախոսահամար",
  helpbtntext: "Օգնություն",
  homebutton: "տուն",
  scanid: "Scan իմ Id- ը",
  next: "Հաջորդը",
  providerinfotitle: "Ո՞վ եք այստեղ, որ տեսնեք:",
  providerinfotxt:
    "Խնդրում ենք ընտրել այն մատակարարին, որը դուք այստեղ եք, որպեսզի տեսնեք:",
  successtitle: "Հաջողությամբ ստորագրվեց:",
  successtxt:
    "Դուք հաջողությամբ մուտք եք գործել կրպակների համակարգ: Խնդրում ենք նստել, քանի որ մեր աշխատակիցներից որևէ մեկը շուտով կկապվի ձեզ հետ:",
  helptitle: "Կրպակի օգնություն",
  helptxt1:
    "Եթե ​​օգնության կարիք ունեք, ապա ձեզ հարկավոր է գնալ պատուհան և չեղարկել ձեր էլեկտրոնային գրանցումը:",
  helptxt2:
    "Եթե ​​ցանկանում եք շարունակել ձեր էլեկտրոնային գրանցումը, կտտացրեք Close կոճակը:",
  helpclose: "փակել",
  visitReason: "Այցելության պատճառ",
  previous: "Նախորդ",
  callnumberlabel:
    "Քառանիշ համար (եզակի ID ՝ ծառայությունների մասին ձեզ տեղեկացնելու համար) ",
  callnowarning:
    "Մուտքագրված զանգի համարն անվավեր է: Խնդրում ենք մուտքագրել 4 նիշ զանգի համար",
  startover: "Վերսկսել",
  donthavephonenumber: "ՀԵՌԱԽՈՍԱՅԻՆ ՀԱՄԱՐ ՉՈՒՆԵՄ",
  callnumberdisplaylabel: "Ձեր զանգի համարն է ",
  waittext: "Խնդրում եմ նստեք, և ինչ-որ մեկը ձեզ կզանգի",
};

export default armenian;
