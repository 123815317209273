import React, { Component } from "react";
import Header from "modules/general/containers/header/header";
import "./index.scss";
import ManageDevices from "./pages/manage-devices/manage-devices";
import ManageScreens from "./pages/manage-screens/manage-screens";
import { Redirect, Route, Switch } from "react-router-dom";
import ManageColumns from "./pages/manage-columns/manage-columns";
import AccessGuard from "guards/access-guard";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../config/authProvider";

class ScreenBuilder extends Component {
  state = {};
  componentDidMount() {
    this._determineViewFromParams();
  }

  _determineViewFromParams = () => {};

  render() {
    return (
      <>
        <AzureAD provider={authProvider} forceLogin={true}>
          <AccessGuard
            accessLevel={[
              "SuperAdmin",
              "ClinicAdmin",
              "Registration",
              "OrderActivation",
            ]}
          >
            <Header>
              <Switch>
                <Route
                  path="/screen-builder/manage-devices"
                  component={ManageDevices}
                />
                <Route
                  path="/screen-builder/manage-screens"
                  component={ManageScreens}
                />
                <Route
                  path="/screen-builder/manage-columns"
                  component={ManageColumns}
                />
                <Route
                  path="*"
                  render={() => (
                    <Redirect to="/screen-builder/manage-devices" />
                  )}
                />
              </Switch>
            </Header>
          </AccessGuard>
        </AzureAD>
      </>
    );
  }
}

export default ScreenBuilder;
