// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import pillsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/pillsStyle.js";
import Accordion from "components/Accordion/Accordion.js";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import NavPills from "components/NavPills/NavPills.js";
import { faqData } from "config/faq-data";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import "./faq-page.scss";
const useStyles = makeStyles(pillsStyle);

const FaqPage = (props) => {
  const classes = useStyles();

  useEffect(() => {
    props.hideLoader();
  }, []);

  return (
    <>
      <div className="faqWrapper">
        <NavPills
          color="primary"
          tabs={faqData.map((tab) => ({
            ...tab,
            tabContent: (
              <div className="accordianWrapper">
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Accordion
                      active={0}
                      activeColor="#990000"
                      collapses={tab.tabData}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            ),
          }))}
        />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(FaqPage);
