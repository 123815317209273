import { createBrowserHistory } from "history";
import BottomLoader from "modules/general/containers/bottom-loader/bottom-loader";
import React from "react";
import { Provider } from "react-redux";
import { Route, Router, Switch } from "react-router";
import { PersistGate } from "redux-persist/integration/react";
import {
  clearMsalCacheSession,
  setSessionRefresher,
} from "utils/session-helper";
import "./app.scss";
import FullPageLoader from "./modules/general/containers/full-page-loader";
import Modules from "./modules/index";
import MultiLang from "./multi-lang/index";
import arabic from "./multi-lang/langs/arabic";
import armenian from "./multi-lang/langs/armenian";
import chinese from "./multi-lang/langs/chinese";
import english from "./multi-lang/langs/english";
import korean from "./multi-lang/langs/korean";
import russian from "./multi-lang/langs/russian";
import spanish from "./multi-lang/langs/spanish";
import tagalog from "./multi-lang/langs/tagalog";
import vietnamese from "./multi-lang/langs/vietnamese";
import { persistor, store } from "./redux/store";
import QuickPatientSearchPrompt from "modules/general/components/quick-patient-search-prompt/quick-patient-search-prompt";

var hist = createBrowserHistory();

// Initialize multi lang
MultiLang.setLangModules({
  english,
  spanish,
  chinese,
  tagalog,
  vietnamese,
  korean,
  russian,
  arabic,
  armenian,
});
// Set default lang
MultiLang.setDefaultLang("english");
// Set current lang
MultiLang.setCurrentLang("english");

// After 30 minutes, clear msal cached session data
clearMsalCacheSession();
setSessionRefresher(30);

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate loading={<div></div>} persistor={persistor}>
          <Router history={hist}>
            <div>
              <FullPageLoader></FullPageLoader>
              <BottomLoader />
              <Switch>
                <Route path="*" component={Modules} />
              </Switch>
            </div>
            <QuickPatientSearchPrompt />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
