import React, { Component } from "react";
import "./PageRefresher.scss";
import { store } from "redux/store";
import { updateUserData } from "redux/actions/user-data";
import { updateUtilsData } from "redux/actions/utils-data";
import { showLoader } from "redux/actions/loader-data";

let intervalRef = null;

class PageRefresher extends Component {
  state = {
    secondCounter: -1,
    shouldShowMessage: false,
  };

  componentDidMount() {
    this._initiateTimer();
  }

  componentWillUnmount() {
    if (intervalRef) {
      clearInterval(intervalRef);
    }
  }

  _startTimer = () => {
    const { interval = 1, messageBefore = 1 } = this.props;

    intervalRef = setInterval(() => {
      let { secondCounter, shouldShowMessage } = this.state;

      if (secondCounter > (interval - messageBefore) * 60 && !shouldShowMessage) {
        this.setState({ shouldShowMessage: true });
      }

      if (secondCounter === interval * 60) {
        store.dispatch(showLoader("Please wait"));
        window.location.reload();
        clearInterval(intervalRef);
        return;
      }

      this.setState({ secondCounter: secondCounter + 1 });
      store.dispatch(updateUtilsData({ counter: secondCounter + 1 }));
    }, 1000);
  };

  _initiateTimer = () => {
    const reduxState = store.getState();
    if (reduxState.utilsData.counter && reduxState.utilsData.counter) {
      this.setState({ secondCounter: reduxState.utilsData.counter }, () => {
        this._startTimer();
      });
    } else {
      this._startTimer();
    }
  };

  render() {
    const { interval = 1 } = this.props;
    const { secondCounter, shouldShowMessage } = this.state;

    const remainingSeconds = interval * 60 - secondCounter;
    return (
      <>
        {shouldShowMessage ? (
          <div className="pageRefreshWrapper">
            Page will be refreshed in &nbsp;{" "}
            <span className="seconds">{remainingSeconds} seconds</span>
            <button
              className="refreshNow"
              onClick={(e) => {
                window.location.reload();
              }}
            >
              Refresh Now
            </button>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export default PageRefresher;
