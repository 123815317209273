import { getClinicAppSettings } from "../../http-calls/index";

export const loadClinicApps = () => {
  return async (dispatch, getState) => {
    try {
      const { userData } = getState();
      if (userData?.selectedClinic?.ClinicID) {
        const clinicApps = await getClinicAppSettings(
          userData?.selectedClinic?.ClinicID
        );
        dispatch(setClinicApps(clinicApps));
      }
    } catch (error) {
      console.log("error :>> ", error);
      dispatch(setClinicApps([]));
    }
  };
};

export const setClinicApps = (apps) => {
  return {
    type: "SET_CLINIC_APPS",
    payload: {
      apps,
    },
  };
};

export const clearClinicApps = () => {
  return {
    type: "CLEAR_CLINIC_APPS",
  };
};
