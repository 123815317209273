import { authProvider } from "config/authProvider";
import React from "react";
import AzureAD from "react-aad-msal";
import "./style.scss";
import OrderActivationView from "./pages/order-activation-view/order-activation-view";
import AccessGuard from "guards/access-guard";

const UserActivation = (props) => {
  return (
    <>
      <AzureAD provider={authProvider} forceLogin={true}>
        <AccessGuard
          accessLevel={["SuperAdmin", "ClinicAdmin", "OrderActivation"]}
        >
           <OrderActivationView />
        </AccessGuard>
      </AzureAD>
    </>
  );
};

export default UserActivation;
