import { authProvider } from "config/authProvider";
import { getClinicDetails, getSTaffUserInfo } from "http-calls";
import AccountClinicSelector from "modules/general/components/account-clinic-selector/account-clinic-selector";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import { updateUserData, clearUserData } from "redux/actions/user-data";
import { store } from "redux/store";
import { sleepTime } from "helper-methods";
import { signin } from "http-calls";
import PageRefresher from "components/PageRefresher/PageRefresher";
import { clearMsalCacheSession } from "utils/session-helper";
import { refreshToken } from "utils/session-helper";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";
import { loadClinicApps } from "redux/actions/clinic-settings";

const AccessGuard = (props) => {
  const [allowedClinics, setAllowedClinics] = useState([]);
  const [isChildVisible, setIsChildVisible] = useState(false);
  const [isClinicSelectorVisible, setIsClinicSelectorVisible] = useState(false);

  const _fetchStaffInfo = async (userInfo) => {
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
        let clinics = [];
        let fetchCalls = [];
        if (res.length) {
          res.forEach((clinic) => {
            fetchCalls.push(getClinicDetails(clinic.ClinicID));
            // fetchCalls.push(getClinicDetails(clinic.ClinicID));
          });
          clinics = await Promise.all(fetchCalls);
          clinics = clinics.map((c) => c[0]);
        }
        return clinics;
      } else {
        props.hideBottomLoader();
        throw "error";
      }
    } catch (error) {
      props.hideBottomLoader();
      console.log("error :>> ", error);
      // _logout();
    }
  };

  const _filterClinics = async (allClinics) => {
    const { accessLevel } = props;
    const userInfo = authProvider.getAccountInfo();
    const allowedAccess = await getSTaffUserInfo(userInfo.account.userName);
    const allowedClinicIds = [];
    allowedAccess.forEach((access) => {
      if (accessLevel.indexOf(access.UserAdminRole) > -1) {
        allowedClinicIds.push(access.ClinicID);
      }
    });
    let filteredClinics = allClinics.filter((clinic) => {
      if (allowedClinicIds.indexOf(clinic.ClinicID) > -1) {
        return true;
      }
    });
    const mergedClinics = {};
    filteredClinics.forEach(filteredClinic => {
      if (!mergedClinics[filteredClinic.ClinicID]) {
        mergedClinics[filteredClinic.ClinicID] = filteredClinic;
      }
    })
    return Object.values(mergedClinics);
  };

  const _initiateCheck = async () => {
    props.showLoader("Please wait");
    // Get staff info from azure auth
    clearMsalCacheSession();
    await sleepTime(200);
    const userInfo = authProvider.getAccountInfo();
    // store.dispatch(updateUserData({ token: userInfo.jwtIdToken }));
    await refreshToken();
    // Offset delay to set the store
    await sleepTime(200);
    signin();
    // Fetch staff info
    const allowedClinics = await _fetchStaffInfo(userInfo);
    const allowedClinicsForCurrentRoute = await _filterClinics(allowedClinics);
    if (allowedClinicsForCurrentRoute.length) {
      setAllowedClinics(allowedClinicsForCurrentRoute);
    } else {
      // No access
      _logout();
    }
  };

  const _logout = () => {
    authProvider.logout();
  };

  const _selectClinic = async (clinic) => {
    props.updateUserData({
      selectedClinic: clinic,
    });
    props.loadClinicApps(clinic.ClinicID);
    // ClinicStateHelper.fetchClinicStates({clinicId: clinic.ClinicID, forceRefresh: true});
    clearMsalCacheSession();
    setIsClinicSelectorVisible(false);
    setIsChildVisible(true);
  };

  useEffect(() => {
    if (allowedClinics.length) {
      if (allowedClinics.length === 1) {
        // One clinic available
        // Select it as default
        props.updateUserData({
          selectedClinic: allowedClinics[0],
        });
        props.loadClinicApps(allowedClinics[0].ClinicID);
        setIsClinicSelectorVisible(false);
        setIsChildVisible(true);
      } else {
        // More than one clinic available
        if (props.userData.selectedClinic) {
          props.hideLoader();
          setIsChildVisible(true);
        } else {
          props.hideLoader();
          setIsClinicSelectorVisible(true);
        }
      }
    }
  }, [allowedClinics]);

  useEffect(() => {
    _initiateCheck();
  }, []);

  useEffect(() => {
    if (props?.userData?.selectedClinic?.ClinicID) {
      ClinicStateHelper.fetchClinicStates({clinicId: props?.userData?.selectedClinic?.ClinicID, forceRefresh: true});
    }
  }, [props.userData.selectedClinic])

  let currentlySelectedClinicName = "Loading";
  if (
    props.userData &&
    props.userData.selectedClinic &&
    props.userData.selectedClinic.Name
  ) {
    currentlySelectedClinicName = props.userData.selectedClinic.Name;
  }

  return (
    <>
      {isChildVisible && (
        <>
        {props.children}
        {/* <PageRefresher interval={2} messageBefore={1} /> */}
        </>
        )}
      {allowedClinics && allowedClinics.length > 0 ? (
            <div className="clinicSwitcherWrapper">
              <div className="clinicSwitcher">
                <i class="fa fa-building" aria-hidden="true"></i>

                <span className="clinicLabel">
                  {currentlySelectedClinicName}
                </span>
                {allowedClinics && allowedClinics.length > 1 ? (
                  <button
                    className="switcher"
                    onClick={(e) => {
                      setIsClinicSelectorVisible(true);
                      setIsChildVisible(false);
                    }}
                  >
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </button>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

      <AccountClinicSelector
        clinics={allowedClinics}
        isVisible={isClinicSelectorVisible}
        onClinicSelect={_selectClinic}
        onLogout={_logout}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
    clearUserData: () => dispatch(clearUserData()),
    loadClinicApps: (clinicId) => dispatch(loadClinicApps(clinicId)),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessGuard);
