import React, { useState, useEffect } from "react";

const SearchInput = (props) => {
  const {
    placeHolderText = "Search",
    onChange = () => {},
    value: initialValue,
  } = props;
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue]);

  const _clearInput = () => {
    setInputValue("");
  }

  return (
    <div className="searchWrapper">
      <div className="searchIconWRapper">
        <i className="fa fa-search" aria-hidden="true" />
      </div>
      <input
        type="text"
        placeholder={placeHolderText}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
      />
      <div className="clearBtn" onClick={_clearInput}>
        {
          inputValue && inputValue.length? <i className="fa fa-times" aria-hidden="true" />: <></>
        }
      </div>
    </div>
  );
};

export default SearchInput;
