import { SHOW_LOADER, HIDE_LOADER, SHOW_BOTTOM_LOADER, HIDE_BOTTOM_LOADER } from "../actions/action-types";

export const loaderDataReducer = (
  state = { isVisible: false, loaderText: "Processing", isBottomLoaderVisible: false, bottomLoaderText: "Syncing" },
  action
) => {
  let newState = { ...state };
  switch (action.type) {
    case SHOW_LOADER: {
      newState = {
        isVisible: true,
        loaderText: action.payload.loaderText,
      };
      break;
    }
    case HIDE_LOADER: {
      newState = {
        isVisible: false,
        isBottomLoaderVisible: false,
        loaderText: "Loading",
      };
      break;
    }
    case SHOW_BOTTOM_LOADER: {
      newState = {
        isBottomLoaderVisible: true,
        bottomLoaderText: action.payload.loaderText,
      };
      break;
    }
    case HIDE_BOTTOM_LOADER: {
      newState = {
        isBottomLoaderVisible: false,
        isVisible: false,
        bottomLoaderText: "Syncing",
      };
      break;
    }
    default: {
    }
  }
  return newState;
};
