import React, { Component } from "react";
import MultiLang from "./index";
import { connect } from "react-redux";

class LanguageParsedText extends Component {
  state = {};
  componentDidMount() {}
  render() {
    const languageParser = new MultiLang();
    const { value } = this.props;
    return (
      <React.Fragment>
        {languageParser.getValueFromCurrentLang(value)}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    languageData: state.languageData,
  };
};

export default connect(mapStateToProps, null)(LanguageParsedText);

export const getValueFromCurrentLang = (keyString, dynamicVariables = {}) => {
  const multiLang = new MultiLang();
  return multiLang.getValueFromCurrentLang(keyString, dynamicVariables);
};
