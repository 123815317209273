import { LoginType, MsalAuthProvider } from "react-aad-msal";

// Msal Configurations
const config = {
  auth: {
    authority: process.env.REACT_APP_MSAL_CONFIGS_AUTHORITY,
    clientId: process.env.REACT_APP_MSAL_CONFIGS_CLIENT_ID,
    redirectUri: process.env.REACT_APP_MSAL_CONFIGS_REDIRECT_URI,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

// Authentication Parameters
const authenticationParameters = {
  //scopes: ["openid", "AccessReview.Read.All"],
  scopes: ["user.read", "openid", "profile"],
  forceRefresh: true
};

// Options
const options = {
  loginType: LoginType.Redirect,
  // tokenRefreshUri: window.location.href,
};
export const authProvider = new MsalAuthProvider(
  config,
  authenticationParameters,
  options
);