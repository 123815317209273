const initialState = {
    isWindowActive: true,
    isTokenRefreshPending: false
}

export const utilsDataReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case 'UPDATE_UTILS_DATA': {
            newState = {
              ...newState,
              ...action.payload.utilsData
            }
            break;
        }
        case 'CLEAR_UTILS_DATA': {
            newState = initialState;
            break;
        }
        default: {
            
        }
    }
    return newState;
}
