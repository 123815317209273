import { deepClone, showToast } from "helper-methods";
import {
  getAllVisitSystemSettingsOptions,
  getVisitSystemSettingsOptions,
  setClinicVisitSettingOptions,
} from "http-calls";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import "./visit-system-settings-page.scss";
import ManageVisitReasons from "modules/visit-system-settings-module/components/manage-visit-reasons/manage-visit-reasons";
import { getVisitReasons } from "http-calls";
import { updateVisitReasons } from "http-calls";

const initialState = {
  allSettings: [],
  currentSettings: [],
  clinicId: null,
  systemSettings: [],
  settingsUpdatePrompt: {
    isVisible: false,
    selectedSettingsTypeId: null,
  },
  visitReasonsManagementPrompt: {
    isVisible: false,
  },
  visitReasons: [],
};

class VisitSystemSettingsPage extends Component {
  state = deepClone(initialState);

  async componentDidMount() {
    this.props.showLoader("Loading");
    await this._setInitialValues();
    try {
      await this._fetchAllSettingsOptions();
      await this._fetchClinicSettings();
      await this._fetchVisitReasons();
      await this._prepareSettings();
      this.props.hideLoader();
    } catch (error) {
      showToast("Server error", "error");
      this.props.hideLoader();
    }
  }

  _fetchVisitReasons = () => {
    return new Promise(async (resolve, reject) => {
      let visitReasons = [];
      try {
        const { clinicId } = this.state;
        const response = await getVisitReasons(clinicId);
        if (response && response.length) {
          visitReasons = response.map((reason) => reason.ReasonforVisit);
        }
      } catch (error) {}
      this.setState({ visitReasons }, () => {
        resolve();
      });
    });
  };

  _setInitialValues = () => {
    return new Promise((resolve, reject) => {
      const {
        selectedClinic: { ClinicID },
      } = this.props.userData;
      this.setState({ clinicId: ClinicID }, () => {
        resolve();
      });
    });
  };

  _fetchAllSettingsOptions = () => {
    return new Promise(async (resolve, reject) => {
      const allSettings = await getAllVisitSystemSettingsOptions();
      this.setState({ allSettings }, () => {
        resolve();
      });
    });
  };

  _fetchClinicSettings = () => {
    return new Promise(async (resolve, reject) => {
      const { clinicId } = this.state;
      try {
        const currentSettings = await getVisitSystemSettingsOptions(clinicId);
        this.setState({ currentSettings }, () => {
          resolve();
        });
      } catch (error) {
        this.setState({ currentSettings: [] }, () => {
          resolve();
        });
      }
    });
  };

  _prepareSettings = () => {
    return new Promise((resolve, reject) => {
      const { currentSettings, allSettings } = this.state;
      let systemSettings = allSettings.map((setting) => {
        let systemSetting = {
          ...setting,
          isEnabled: false,
        };
        // Check if system setting is available for current clinic
        const currentSetting = currentSettings.find(
          (s) => s.PatientVisitOptionID === setting.PatientVisitOptionID
        );
        if (currentSetting) {
          systemSetting.isEnabled = true;
        }
        return systemSetting;
      });
      this.setState({ systemSettings }, () => {
        resolve();
      });
    });
  };

  _toggleSettings = async (setting) => {
    const { systemSettings } = this.state;
    const systemSettingIndex = systemSettings.findIndex(
      (systemSetting) =>
        systemSetting.PatientVisitOptionID === setting.PatientVisitOptionID
    );
    const {
      selectedClinic: { ClinicID },
    } = this.props.userData;
    if (!systemSettings[systemSettingIndex].isEnabled) {
      // Not enabled
      // So enable
      const preparedSettings = {
        clinicID: ClinicID,
        ClinicVisitSettings: [],
      };
      systemSettings.forEach((setting) => {
        if (setting.isEnabled) {
          preparedSettings.ClinicVisitSettings.push({
            PatientVisitOptionID: setting.PatientVisitOptionID,
          });
        }
      });
      preparedSettings.ClinicVisitSettings.push({
        PatientVisitOptionID:
          systemSettings[systemSettingIndex].PatientVisitOptionID,
      });
      await this._updateSystemSettings(preparedSettings);
    } else {
      // Enabled
      // So disbale
      const preparedSettings = {
        clinicID: ClinicID,
        ClinicVisitSettings: [],
      };
      systemSettings.forEach((setting, settingIndex) => {
        if (setting.isEnabled && settingIndex !== systemSettingIndex) {
          preparedSettings.ClinicVisitSettings.push({
            PatientVisitOptionID: setting.PatientVisitOptionID,
          });
        }
      });
      await this._updateSystemSettings(preparedSettings);
    }
  };

  _showUpdatePrompt = (setting) => {
    const { settingsUpdatePrompt } = this.state;
    settingsUpdatePrompt.isVisible = true;
    settingsUpdatePrompt.selectedSettingsTypeId =
      setting.ClinicSystemSettingTypeID;
    this.setState({ settingsUpdatePrompt });
  };

  _hideUpdatePrompt = () => {
    const { settingsUpdatePrompt } = this.state;
    settingsUpdatePrompt.isVisible = false;
    settingsUpdatePrompt.selectedSettingsTypeId = null;
    this.setState({ settingsUpdatePrompt });
  };

  _showVisitReasonsPrompt = () => {
    const { visitReasonsManagementPrompt } = this.state;
    visitReasonsManagementPrompt.isVisible = true;
    this.setState({ visitReasonsManagementPrompt });
  };

  _hideVisitReasonsPrompt = () => {
    const { visitReasonsManagementPrompt } = this.state;
    visitReasonsManagementPrompt.isVisible = false;
    this.setState({ visitReasonsManagementPrompt });
  };

  _updateSystemSettings = async (updatedSettings) => {
    try {
      this.props.showLoader("Updating");
      await setClinicVisitSettingOptions(updatedSettings);
      await this._fetchClinicSettings();
      await this._prepareSettings();
      this.props.hideLoader();
    } catch (error) {
      showToast("Unable to update settings", "error");
      this.props.hideLoader();
    }
  };

  _updateVisitReasons = async (newReasons) => {
    const { clinicId } = this.state;
    const formattedReasons = {
      clinicID: clinicId,
      ClinicReasonForVisitSettings: newReasons.map((reasonText) => ({
        ReasonForVisit: reasonText,
        ClinicID: clinicId,
      })),
    };
    this._hideVisitReasonsPrompt();
    try {
      this.props.showLoader("Updating");
      await updateVisitReasons(formattedReasons);
      await this._fetchVisitReasons();
      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
    }
  };

  render() {
    const {
      systemSettings,
      visitReasons,
      visitReasonsManagementPrompt,
    } = this.state;

    return (
      <>
        <div className="visitSystemSettingsWrapper">
          <div className="systemSettingsWrapperCard">
            <div className="settingOptions">
              {systemSettings.map((systemSetting, systemSettingIndex) => (
                <div className="option" key={systemSettingIndex}>
                  <div className="label">
                    {systemSetting.PatientVisitOption}
                  </div>
                  <div className="actions">
                    <input
                      id={"c1" + systemSettingIndex}
                      type="checkbox"
                      onClick={(e) => this._toggleSettings(systemSetting)}
                      checked={systemSetting.isEnabled}
                    />
                  </div>
                </div>
              ))}
              <div className="manageVisitOptions">
                <button onClick={this._showVisitReasonsPrompt}>
                  Manage Visit Reasons
                </button>
                <div className="optionsLabel">
                  {visitReasons && visitReasons.length ? (
                    <>
                      {`${visitReasons.length} option${
                        visitReasons.length === 1 ? "" : "s"
                      } available`}
                    </>
                  ) : (
                    <>No Options set</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ManageVisitReasons
          options={visitReasons}
          dismiss={this._hideVisitReasonsPrompt}
          isVisible={visitReasonsManagementPrompt.isVisible}
          onOptionsUpdate={this._updateVisitReasons}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VisitSystemSettingsPage);
