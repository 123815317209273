import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import React, { Component } from "react";
import Modal from "react-awesome-modal";
import { deepClone } from "../../../../helper-methods";
import "./clinic-editor.scss";

const initialState = {
  formFields: {
    clinicName: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
    clinicLocation: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
};

class ClinicEditor extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    if (this.props.editMode) {
      this._initializeFields();
    } else {
      this._resetFields();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // Opened
      if (this.props.editMode) {
        this._initializeFields();
      } else {
        this._resetFields();
      }
    }
  }

  _initializeFields = () => {
    if (this.props.selectedClinic && Object.keys(this.props.selectedClinic)) {
      const { formFields } = this.state;
      formFields.clinicName.value = this.props.selectedClinic.Name;
      formFields.clinicLocation.value = this.props.selectedClinic.Location;
      this.setState({ formFields });
    }
  };

  _resetFields = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "clinicName": {
            if (formFields.clinicName.value.length >= 1) {
              formFields.clinicName.isValid = true;
            } else {
              formFields.clinicName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "clinicLocation": {
            if (formFields.clinicLocation.value.length >= 1) {
              formFields.clinicLocation.isValid = true;
            } else {
              formFields.clinicLocation.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _save = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
      try {
        // Format data
        if (this.props.editMode) {
          const updatedClinic = {
            ClinicID: this.props.selectedClinic.ClinicID,
            Name: formFields.clinicName.value,
            Location: formFields.clinicLocation.value,
          };
          this.props.onClinicUpdate(updatedClinic);
        } else {
          // Create mode
          const newClinic = {
            Name: formFields.clinicName.value,
            Location: formFields.clinicLocation.value,
          };
          this.props.onCreateNewClinic(newClinic);
        }
      } catch (error) {}
    }
  };

  _discard = () => {
    this.props.onDiscard();
  };

  render() {
    const { formFields } = this.state;
    return (
      <>
        <Modal
          visible={this.props.isVisible}
          width="400"
          height="380"
          effect="fadeInUp"
          onClickAway={this._discard}
        >
          <div id="modalFormWrapper">
            <div className="crossWrapper" onClick={this._discard}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <h3>
              {this.props.editMode ? "Update clinic details" : "Add new clinic"}
            </h3>
            <div className="promptFormWrapper">
              <CustomInput
                labelText="Clinic Name"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: formFields.clinicName.value,
                  onChange: (e) =>
                    this._updateFieldValue("clinicName", e.target.value),
                  onBlur: () => this._markAsDirty("clinicName"),
                }}
              />
              <p className="formErrorText">
                {formFields.clinicName.isDirty && !formFields.clinicName.isValid
                  ? "Please provide valid clinic name"
                  : ""}
              </p>
              <CustomInput
                labelText="Clinic Location"
                id="float"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: formFields.clinicLocation.value,
                  onChange: (e) =>
                    this._updateFieldValue("clinicLocation", e.target.value),
                  onBlur: () => this._markAsDirty("clinicLocation"),
                }}
              />
              <p className="formErrorText">
                {formFields.clinicLocation.isDirty &&
                !formFields.clinicLocation.isValid
                  ? "Please provide valid clinic location"
                  : ""}
              </p>
              <div className="formActionWrapper">
                <Button
                  color="success"
                  className="saveButton"
                  onClick={this._save}
                >
                  {this.props.editMode ? "Save" : "Create"}
                </Button>
                <Button
                  color="danger"
                  className="discardButton"
                  onClick={this._discard}
                >
                  Discard
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default ClinicEditor;
