import { deepClone } from "helper-methods";
import {
  getAvailableFlowStates,
  getClinicCustomStates,
  setClinicCustomStates,
} from "http-calls";

class ClinicStateHelper {
  static selectedClinicId = -1;
  static standardStates = [];
  static clinicCustomStates = [];

  /****************************** Public methods start here *************************/
  /**
   * To set active clinic
   * @param {*} clinicId
   */
  static setActiveClinicId(clinicId) {
    ClinicStateHelper.selectedClinicId = clinicId;
  }
  /**
   * To fetch active clinic states (merged state)
   * @param {*}
   */
  static async fetchClinicStates({ clinicId = -1, forceRefresh = false } = {}) {
    let shouldLoad = false;
    if (
      clinicId &&
      clinicId > -1 &&
      ClinicStateHelper.selectedClinicId !== clinicId
    ) {
      ClinicStateHelper.selectedClinicId = clinicId;
      shouldLoad = true;
    }
    if (forceRefresh) {
      shouldLoad = true;
    }
    if (shouldLoad) {
      await Promise.all([
        ClinicStateHelper._fetchStandardStates(),
        ClinicStateHelper._fetchClinicCustomStates(),
      ]);
    }
    let mergedStates = ClinicStateHelper._mergeStates();
    console.log("mergedStates :>> ", mergedStates);
    return mergedStates;
  }
  /**
   * To set clinic custom states
   * @param {*} customStates
   */
  static async setClinicCustomStates(customStates) {
    const payload = {
      ClinicID: ClinicStateHelper.selectedClinicId,
      ClinicCustomStatusnames: customStates.map((state) => ({
        ...state,
        ClinicID: ClinicStateHelper.selectedClinicId,
      })),
    };
    try {
      await setClinicCustomStates(payload);
    } catch (error) {
      console.log("error :>> ", error);
    }
  }
  /****************************** Public methods end here *************************/
  /****************************** Private methods start here *************************/
  /**
   * To fetch all available standard states
   */
  static async _fetchStandardStates() {
    if (!ClinicStateHelper.standardStates?.length) {
      try {
        ClinicStateHelper.standardStates = await getAvailableFlowStates();
      } catch (error) {
        console.log("error :>> ", error);
        ClinicStateHelper.standardStates = [];
      }
    }
  }
  /**
   * To fetch all clinic custom states
   */
  static async _fetchClinicCustomStates() {
    try {
      ClinicStateHelper.clinicCustomStates = await getClinicCustomStates(
        ClinicStateHelper.selectedClinicId
      );
    } catch (error) {
      console.log("error :>> ", error);
      ClinicStateHelper.clinicCustomStates = [];
    }
  }
  /**
   * To merge states
   */
  static _mergeStates() {
    const statusIdMappedCustomStates = {};
    ClinicStateHelper.clinicCustomStates.forEach((state) => {
      statusIdMappedCustomStates[state.StatusID] = state.CustomStatusName;
    });
    const mergedStates = deepClone(ClinicStateHelper.standardStates).map(
      (state) => {
        state.standardState = state.Status;
        if (statusIdMappedCustomStates[state.StatusID]) {
          state.Status = statusIdMappedCustomStates[state.StatusID];
          state.isCustom = true;
        }
        return state;
      }
    );
    return mergedStates;
  }
  /****************************** Private methods end here *************************/
}

export default ClinicStateHelper;
