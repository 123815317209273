import Button from "components/CustomButtons/Button.js";
import Numpad from "modules/general/components/numpad/numpad";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import LanguageParsedText from "../../../../multi-lang/language-parser";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import "./call-number-input.scss";

const CallNumberInput = (props) => {
  const [callNumber, setCallNumber] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const _updatePhoneNumber = (maskedValue) => {
    let unMaskedCallNumber = maskedValue.replace("(", "");
    unMaskedCallNumber = unMaskedCallNumber.replace(")", "");
    unMaskedCallNumber = unMaskedCallNumber.replace(" ", "");
    unMaskedCallNumber = unMaskedCallNumber.replace("-", "");
    unMaskedCallNumber = unMaskedCallNumber.split("_").join("");
    setCallNumber(unMaskedCallNumber);
  };

  const _parseCallNumber = (patientAddResponse) => {
    const parts = patientAddResponse.split("CallNumber: ");
    return parts[1];
  };

  const _submit = async (e) => {
    e.preventDefault();
    setIsTouched(true);
    _validate();
    // Validate if number is proper
    if (isValid) {
      props.updateWizardData({
        callNumber,
      });
      props.proceedToStep("personalDataInput");
    }
  };

  const _validate = () => {
    if (callNumber.length !== 4) {
      // Not valid
      setIsValid(false);
      return;
    } else {
      // Valid
      setIsValid(true);
    }
  };

  useEffect(() => {
    _validate();
  }, [callNumber]);

  useEffect(() => {
    // On enter
    // Scroll to top
    window.scrollTo(0, 0);
  }, []);

  const _isNativeKeyboardAllowed = () => {
    if (window.screen.height <= "510" || window.screen.width <= "768" ) {
      return true;
    } 
  }

  const _appendCharToCall = (number) => {
    let _callNumber = callNumber;
    if (callNumber.length < 4) {
      _callNumber += `${number}`;
      setCallNumber(_callNumber);
    }
  };

  const _performDeleteCharOnCallNumber = () => {
    let _callNumber = callNumber.slice(0, -1);
    setCallNumber(_callNumber);
  };


  return (
    <div id="phoneInputOuterWrapper">
      <div id="phoneInputInnerWrapper">
        <p>
          <LanguageParsedText value="requiredfields" />
        </p>
        <h3>
          <LanguageParsedText value="callnumberlabel" /> &nbsp; *
        </h3>
        <div className="inputWrapper">
          {/* <input type="number" step="0.01" /> */}
          <InputMask
            {...props}
            mask="(9999)"
            // alwaysShowMask
            value={callNumber}
            onChange={(e) => _updatePhoneNumber(e.target.value)}
            placeholder={"(____)"}
            onBlur={(e) => setIsTouched(true)}
            readOnly={_isNativeKeyboardAllowed()? "": "readonly"}
          />
        </div>
        {!isValid && isTouched ? (
          <p className="formError">
            <LanguageParsedText value="callnowarning" />
          </p>
        ) : null}
        <Button color="success" size="lg" onClick={_submit} className="nextBtn">
          <LanguageParsedText value="next" />
        </Button>
      </div>
      <Numpad
        onDelete={(e) => _performDeleteCharOnCallNumber()}
        onKeyPress={(number) => _appendCharToCall(number)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallNumberInput);
