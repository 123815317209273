import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader } from "redux/actions/loader-data";
import { authProvider } from "../../../../config/authProvider";
import { deepClone, showToast } from "../../../../helper-methods/index";
import {
  getAllCheckins,
  updatePatientStatus,
  getVisitReasons,
  getNoAnswerPatients,
} from "../../../../http-calls/index";
import {
  hideBottomLoader,
  showBottomLoader,
  showLoader,
} from "../../../../redux/actions/loader-data";
import RegisterPatientPrompt from "../register-patient-prompt/register-patient-prompt";

let intervalRef = null;

const NoAnswerView = (props) => {
  const [checkins, setCheckins] = useState([]);
  const [filteredCheckins, setFilteredCheckins] = useState([]);
  const [visitReasons, setVisitReasons] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isPatientAddModalVisible, setIsPatientAddModalVisible] = useState(
    false
  );
  const [userInfo, setUserInfo] = useState(null);

  const _loadCheckins = async () => {
    try {
      _initiateBackgroundRefresher();
      const checkins = await getNoAnswerPatients(props.clinicId);
      setCheckins(checkins);
    } catch (error) {
      console.log("error :>> ", error);
      // Error while loading, so unset checkins
      setCheckins([]);
    }
  };

  const _sendToPendingCollection = async (checkin) => {
    try {
      props.showBottomLoader("Updating");
      const {
        PatientID,
        PatientVisitID,
        internalcomments = "",
        publiccomments = "",
        FIN = null,
      } = checkin;
      await updatePatientStatus({
        PatientID,
        PatientVisitID,
        internalcomments,
        publiccomments,
        FIN,
        UpdatedBy: userInfo.account.userName,
        PatientStatusTypeID: 4,
      });
      // props.setActiveView("myRegistraions");
      await _loadCheckins();
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      props.hideLoader();
      showToast("Error while assigning");
    }
  };

  const _loadData = async () => {
    await _loadCheckins();
    props.hideLoader();
  };

  const _filterRows = () => {
    let filteredCheckins = deepClone(checkins);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredCheckins = filteredCheckins.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredCheckins(filteredCheckins);
  };

  const _loadUserData = () => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadData();
      }, 5000);
    }
  };

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = response.map((reason) => reason.ReasonforVisit);
      }
      setVisitReasons(visitReasons);
    } catch (error) {}
  };

  useEffect(() => {
    if (props.activeView === "noAnswer") {
      props.showBottomLoader("Loading");
      _loadVisitReasons();
      _loadData();
    } else {
      setSearchValue("");
    }
  }, [props.activeView]);

  useEffect(() => {
    props.showBottomLoader("Loading");
    _loadData();
    _loadUserData();
    return () => {
      clearInterval(intervalRef);
      intervalRef = null;
    };
  }, []);

  useEffect(() => {
    _filterRows();
  }, [searchValue]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(checkins.length) && props.setPatientCount) {
      props.setPatientCount(checkins.length)
    }
  }, [checkins]);

  if (props.activeView === "noAnswer") {
    return (
      <div id="labAssistantView">
        <div className="tableActions spaceBetween">
          <SearchInput value={searchValue} onChange={setSearchValue} />
        </div>
        <div className="tableInternalScroll">
        <table>
          <thead>
            <tr>
              <th>Call Number</th>
              <th>Name</th>
              <th>Reason for Visit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCheckins.map((checkin) => (
              <tr key={checkin.PatientID}>
                <td data-column="Call Number">{checkin.CallNumber}</td>
                <td data-column="Name">
                  {checkin.FirstName + " " + checkin.LastName}
                </td>
                <td data-column="Reason for Visit">
                  {checkin.VisitType.toUpperCase()}
                </td>
                <td data-column="action">
                  <button
                    className="registerBtn"
                    onClick={(e) => _sendToPendingCollection(checkin)}
                  >
                    Pending Collection
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {filteredCheckins.length === 0 && (
          <div id="noCell">No Patients</div>
        )}
        </div>
       
      </div>
    );
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(NoAnswerView);
