import AccessGuard from "guards/access-guard";
import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { AzureAD } from "react-aad-msal";
import { Redirect, Route, Switch } from "react-router-dom";
import { authProvider } from "../../config/authProvider";
import VisitSystemSettingsPage from "./pages/visit-system-settings-page/visit-system-settings-page";

class VisitSystemsSettingsModule extends Component {
  state = {};

  render() {
    return (
      <>
        <AzureAD provider={authProvider} forceLogin={true}>
          <AccessGuard
            accessLevel={[
              "SuperAdmin",
              "ClinicAdmin"
            ]}
          >
            <Header>
              <Switch>
                <Route
                  path="/manage-visit-options"
                  component={VisitSystemSettingsPage}
                />
                <Route
                  path="*"
                  render={() => <Redirect to="/manage-visit-options" />}
                />
              </Switch>
            </Header>
          </AccessGuard>
        </AzureAD>
      </>
    );
  }
}

export default VisitSystemsSettingsModule;
