import { FlowChart } from "@mrblenny/react-flow-chart";
import { deepClone, extractQueryParams, showToast } from "helper-methods";
import { getAllWorkflows, setWorkflow } from "http-calls";
import CustomizedStatesModal from "modules/flow-customizer/components/customize-states-modal/customize-states-modal";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";

import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./flow-editor.scss";

const NodeInnerCustom = ({ node, config }) => {
  if (node.type === "output-only") {
    return (
      <div>
        <p>Use Node inner to customise the content of the node</p>
      </div>
    );
  } else {
    return (
      <div className="customNode">
        <p>{node.name}</p>
      </div>
    );
  }
};

const initialState = {
  allStates: [
    // { StatusID: 1, Status: "SignedIn" },
    // { StatusID: 2, Status: "Registered" },
    // { StatusID: 3, Status: "Ready For Order Activation" },
    // { StatusID: 4, Status: "Pending Collection" },
    // { StatusID: 5, Status: "Called" },
    // { StatusID: 6, Status: "Completed" },
    // { StatusID: 7, Status: "Order Issues" },
    // { StatusID: 8, Status: "No Answer" },
    // { StatusID: 9, Status: "Lab Order Issues" },
    // { StatusID: 10, Status: "Cancelled" },
  ],
  flowStates: {
    offset: {
      x: 0,
      y: 0,
    },
    nodes: {},
    links: {},
    selected: {},
    hovered: {},
    scale: 0.8,
  },
  currentFlowId: 1,
  nextNodePosition: {
    x: 200,
    y: 200,
  },
  selectedElement: null,
  initialFlowState: {},
  isCustomStatesModalVisible: false,
};

class FlowEditor extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    this._initialize();
  }

  _toggleCustomStatesModal = (
    isVisible = !this.state.isCustomStatesModalVisible
  ) => {
    this.setState({ isCustomStatesModalVisible: isVisible });
  };

  _onLabelUpdate = async () => {
    await this._initialize();
    this._save();
    Swal.fire({
      icon: "info",
      title: "Reload needed",
      text: `In order to make this changes affective, need to reload the application`,
      showDenyButton: true,
      confirmButtonText: "Reload",
      denyButtonText: `Skip`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload();
      } else if (result.isDenied) {
      }
    });
  };

  /**
   * To load all related data from server
   */
  _fetchData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { currentFlowId, flowStates } = this.state;
        const {
          selectedClinic: { ClinicID },
        } = this.props.userData;
        // Fetch all flow states to show in sidebar
        const allStates = await ClinicStateHelper.fetchClinicStates();
        console.log("allStates :>> ", allStates);
        // Fetch all flows
        const allFlows = await getAllWorkflows(ClinicID);
        // Find current flow from it
        const currentFlow = allFlows.find(
          (flow) => flow.WorkFlowID == currentFlowId
        );
        const workFlowJson = JSON.parse(currentFlow.WorkFlow_Json);
        // Check if workflow json has the initial values or empty
        // If it's empty default state value will be used
        if (Object.keys(workFlowJson).length) {
          // workflow json is not empty
          // So use it
          flowStates = workFlowJson;
        }
        const formattedFlowStates = this._formatFlowStates(
          flowStates,
          allStates
        );
        console.log("formattedFlowStates :>> ", formattedFlowStates);
        // Store
        this.setState(
          {
            currentFlow,
            flowStates: formattedFlowStates,
            initialFlowState: deepClone(formattedFlowStates),
            allStates,
          },
          () => {
            resolve();
          }
        );
      } catch (error) {
        console.log("error", error);
        resolve();
      }
    });
  };

  _formatFlowStates = (flowStates, allStates) => {
    const idMappedCustomStates = {};
    const idMappedStandardStates = {};
    allStates.forEach((state) => {
      if (state.isCustom) {
        idMappedCustomStates[state.StatusID] = state.Status;
      }
    });

    const standardStates = deepClone(ClinicStateHelper.standardStates);

    standardStates.forEach((state) => {
      idMappedStandardStates[state.StatusID] = state.Status;
    });

    Object.keys(flowStates.nodes).forEach((statusId) => {
      // First reset
      flowStates.nodes[statusId].name = idMappedStandardStates[statusId];
      flowStates.nodes[statusId].type = idMappedStandardStates[statusId];
      if (idMappedCustomStates[statusId]) {
        flowStates.nodes[statusId].name = idMappedCustomStates[statusId];
        flowStates.nodes[statusId].type = idMappedCustomStates[statusId];
      }
    });
    return flowStates;
  };

  _setFlowId = async () => {
    return new Promise((resolve, reject) => {
      const urlParms = extractQueryParams();
      if (urlParms.flowId && urlParms.flowId.length) {
        this.setState({ currentFlowId: urlParms.flowId }, () => {
          resolve();
        });
      } else {
        this.props.history.push("/screen-builder");
      }
    });
  };

  _prepareBuilder = async () => {
    // const { flowStates } = this.state;
  };

  _initialize = async () => {
    this.props.showLoader("Preparing builder");
    await this._setFlowId();
    await this._fetchData();
    await this._prepareBuilder();
    this.props.hideLoader();
  };

  /**
   * To add a state in the builder
   */
  _appendState = (state) => {
    let { flowStates, nextNodePosition } = this.state;
    // Check if flowstates is initialized before
    if (!flowStates || !Object.keys(flowStates).length) {
      // Not initialized
      flowStates = {
        offset: {
          x: 0,
          y: 0,
        },
        nodes: {},
        links: {},
        selected: {},
        hovered: {},
        scale: 0.8,
      };
    }
    const nodeId = state.StatusID;
    flowStates.nodes[nodeId] = {
      id: nodeId,
      name: state.Status,
      type: state.Status,
      statusId: state.StatusID,
      position: deepClone(nextNodePosition),
      ports: {
        input: {
          id: "input",
          type: "input",
        },
        output: {
          id: "output",
          type: "output",
        },
      },
    };
    nextNodePosition.x = nextNodePosition.x + 25;
    nextNodePosition.y = nextNodePosition.y + 25;
    this.setState({ flowStates, nextNodePosition });
  };

  rotate = (center, current, angle) => {
    const radians = (Math.PI / 180) * angle;
    const cos = Math.cos(radians);
    const sin = Math.sin(radians);
    const x =
      cos * (current.x - center.x) + sin * (current.y - center.y) + center.x;
    const y =
      cos * (current.y - center.y) - sin * (current.x - center.x) + center.y;
    return { x, y };
  };

  chartActions = () => {
    const onDragNode = (e) => {
      const { flowStates } = this.state;
      const { data, id } = e;
      // Find out that node
      flowStates.nodes[id] = {
        ...flowStates.nodes[id],
        position: {
          x: data.lastX,
          y: data.lastY,
        },
      };
      this.setState({ flowStates });
    };
    const onDragNodeStop = (e) => {
      const { flowStates } = this.state;
      const { data, id } = e;
      // Find out that node
      flowStates.nodes[id] = {
        ...flowStates.nodes[id],
        position: {
          x: data.lastX,
          y: data.lastY,
        },
      };
      this.setState({ flowStates });
    };
    const onDragCanvas = (e) => {};
    const onDragCanvasStop = (e) => {
      let { flowStates } = this.state;
      const { data } = e;
      flowStates = {
        ...flowStates,
        offset: {
          x: data.positionX,
          y: data.positionY,
        },
        scale: data.scale,
      };
      this.setState({ flowStates });
    };
    const onCanvasDrop = (e) => {
      let { flowStates } = this.state;
      const { data, position } = e;
      // Check if flowstates is initialized before
      if (!flowStates || !Object.keys(flowStates).length) {
        // Not initialized
        flowStates = {
          offset: {
            x: 0,
            y: 0,
          },
          nodes: {},
          links: {},
          selected: {},
          hovered: {},
          scale: 0.8,
        };
      }
      const nodeId = data.properties.node.StatusID;
      flowStates.nodes[nodeId] = {
        id: nodeId,
        name: data.properties.node.Status,
        type: data.properties.node.Status,
        statusId: data.properties.node.StatusID,
        position: position,
        ports: data.ports,
      };
      this.setState({ flowStates });
    };
    const onLinkStart = (e) => {
      let { flowStates } = this.state;
      const { linkId, fromNodeId, fromPortId, toPosition } = e;
      if (
        !flowStates.links[linkId] ||
        (flowStates.links[linkId] &&
          !Object.keys(flowStates.links[linkId]).length)
      ) {
        flowStates.links[linkId] = {};
      }
      flowStates.links[linkId] = {
        ...flowStates.links[linkId],
        id: linkId,
        from: {
          nodeId: fromNodeId,
          portId: fromPortId,
        },
        to: {
          position: toPosition,
        },
      };
      this.setState({ flowStates });
    };
    const onLinkMove = (e) => {
      let { flowStates } = this.state;
      const { linkId, fromNodeId, fromPortId, toPosition } = e;
      if (
        !flowStates.links[linkId] ||
        (flowStates.links[linkId] &&
          !Object.keys(flowStates.links[linkId]).length)
      ) {
        flowStates.links[linkId] = {};
      }
      flowStates.links[linkId] = {
        ...flowStates.links[linkId],
        id: linkId,
        from: {
          nodeId: fromNodeId,
          portId: fromPortId,
        },
        to: {
          position: toPosition,
        },
      };
      this.setState({ flowStates });
    };
    const onLinkComplete = (e) => {
      let { flowStates } = this.state;
      const { linkId, fromNodeId, fromPortId, toNodeId, toPortId } = e;
      flowStates.links[linkId] = {
        id: linkId,
        from: {
          nodeId: fromNodeId,
          portId: fromPortId,
        },
        to: {
          nodeId: toNodeId,
          portId: toPortId,
        },
      };
      this.setState({ flowStates });
    };
    const onLinkCancel = (e) => {
      let { flowStates } = this.state;
      const { linkId } = e;
      delete flowStates.links[linkId];
      this.setState({ flowStates });
    };
    const onPortPositionChange = (chart) => {
      let { flowStates } = this.state;
      // rotate the port's position based on the node's orientation prop (angle)
      const { node: nodeToUpdate, port, el, nodesEl } = chart;
      if (nodeToUpdate.size) {
        // rotate the port's position based on the node's orientation prop (angle)
        const center = {
          x: nodeToUpdate.size.width / 2,
          y: nodeToUpdate.size.height / 2,
        };
        const current = {
          x: el.offsetLeft + nodesEl.offsetLeft + el.offsetWidth / 2,
          y: el.offsetTop + nodesEl.offsetTop + el.offsetHeight / 2,
        };
        const angle = nodeToUpdate.orientation || 0;
        const position = this.rotate(center, current, angle);

        const node = flowStates.nodes[nodeToUpdate.id];
        node.ports[port.id].position = {
          x: position.x,
          y: position.y,
        };
        flowStates.nodes[nodeToUpdate.id] = { ...node };
      }
      this.setState({ flowStates });
    };
    const onLinkMouseEnter = (e) => {};
    const onLinkMouseLeave = (e) => {};
    const onLinkClick = (e) => {
      const { linkId } = e;
      let { flowStates } = this.state;
      let selectedElement = {};
      selectedElement.type = "link";
      selectedElement.id = linkId;
      selectedElement.label = `Between ${
        flowStates.nodes[flowStates.links[linkId].from.nodeId].name
      } and ${flowStates.nodes[flowStates.links[linkId].to.nodeId].name}`;
      flowStates.selected = {
        type: "link",
        id: linkId,
      };
      this.setState({ selectedElement, flowStates });
    };
    const onCanvasClick = (e) => {
      let { flowStates } = this.state;
      flowStates.selected = {};
      this.setState({ selectedElement: null, flowStates });
    };
    const onDeleteKey = (e) => {};
    const onNodeClick = (e) => {
      const { nodeId } = e;
      let { flowStates } = this.state;
      let selectedElement = {};
      selectedElement.type = "node";
      selectedElement.id = nodeId;
      selectedElement.id = nodeId;
      selectedElement.label = `${flowStates.nodes[nodeId].name}`;
      flowStates.selected = {
        type: "node",
        id: nodeId,
      };
      this.setState({ selectedElement, flowStates });
    };
    const onNodeDoubleClick = (e) => {};
    const onNodeMouseEnter = (e) => {};
    const onNodeMouseLeave = (e) => {};
    const onNodeSizeChange = (e) => {
      let { flowStates } = this.state;
      const { nodeId, size } = e;
      if (!flowStates.nodes[nodeId]) {
        flowStates.nodes[nodeId] = {};
      }
      flowStates.nodes[nodeId] = {
        ...flowStates.nodes[nodeId],
        size,
      };
      this.setState({ flowStates });
    };
    const onZoomCanvas = (e) => {
      let { flowStates } = this.state;
      const { data } = e;
      flowStates = {
        ...flowStates,
        offset: {
          x: data.positionX,
          y: data.positionY,
        },
        scale: data.scale,
      };
      this.setState({ flowStates });
    };
    return {
      onDragNode,
      onDragNodeStop,
      onDragCanvas,
      onDragCanvasStop,
      onCanvasDrop,
      onLinkStart,
      onLinkMove,
      onLinkComplete,
      onLinkCancel,
      onPortPositionChange,
      onLinkMouseEnter,
      onLinkMouseLeave,
      onLinkClick,
      onCanvasClick,
      onDeleteKey,
      onNodeClick,
      onNodeDoubleClick,
      onNodeMouseEnter,
      onNodeMouseLeave,
      onNodeSizeChange,
      onZoomCanvas,
    };
  };

  _deleteElement = () => {
    let { selectedElement, flowStates } = this.state;
    if (selectedElement.type === "node") {
      // Delete node
      delete flowStates.nodes[selectedElement.id];
      // Also delete all associated links
      let allLinks = Object.values(flowStates.links).filter((l) => {
        if (
          l.from.nodeId == selectedElement.id ||
          l.to.nodeId == selectedElement.id
        ) {
          return true;
        }
      });
      allLinks.forEach((link) => {
        delete flowStates.links[link.id];
      });
    } else {
      // Delete link
      delete flowStates.links[selectedElement.id];
    }
    this.setState({ flowStates, selectedElement: null });
  };

  _getDroppableNodeData = (node) => {
    return {
      type: node.Status,
      ports: {
        input: {
          id: "input",
          type: "input",
        },
        output: {
          id: "output",
          type: "output",
        },
      },
      properties: {
        node,
      },
    };
  };

  _save = async () => {
    const { flowStates, currentFlow } = this.state;
    const {
      selectedClinic: { ClinicID },
    } = this.props.userData;
    try {
      this.props.showLoader("Saving");
      await setWorkflow({
        ClinicID: ClinicID,
        WorkFlow: currentFlow.WorkFlow,
        WorkFlow_Json: JSON.stringify(flowStates),
        WorkFlowID: currentFlow.WorkFlowID,
        Transitions: this._prepareTransitions(flowStates),
      });
      this.props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      showToast("Unable to save", "error");
      this.props.hideLoader();
    }
  };

  _prepareTransitions = (flowStates) => {
    let transitions = [];
    Object.values(flowStates.nodes).forEach((node) => {
      let transition = {
        StatusID: node.id,
        connections: [],
      };
      // Check which other nodes are connected
      const connectedNodeIds = {};
      Object.values(flowStates.links).forEach((link) => {
        if (link.from.nodeId == node.id) {
          connectedNodeIds[link.to.nodeId] = true;
        } else if (link.to.nodeId == node.id) {
          connectedNodeIds[link.from.nodeId] = true;
        }
      });
      Object.keys(connectedNodeIds).forEach((nodeId) => {
        transition.connections.push({ statusid: parseInt(nodeId) });
      });
      transitions.push(transition);
    });
    return transitions;
  };

  _reset = () => {
    if (window.confirm("Are you sure to reset all current changes?")) {
      const { initialFlowState } = this.state;
      if (Object.keys(initialFlowState).length) {
        this.setState({ flowStates: initialFlowState });
      }
    }
  };

  _isModified = () => {
    const { initialFlowState, flowStates } = this.state;
    return JSON.stringify(initialFlowState) !== JSON.stringify(flowStates);
  };

  _getLeftStates = () => {
    const { allStates, flowStates } = this.state;
    const currentlyUsedStates = {};
    Object.values(flowStates.nodes).forEach((node) => {
      currentlyUsedStates[node.statusId] = true;
    });
    const filteredStates = allStates.filter(
      (state) => !currentlyUsedStates[state.StatusID]
    );
    return filteredStates;
  };

  render() {
    const {
      allStates,
      flowStates,
      selectedElement,
      isCustomStatesModalVisible,
    } = this.state;
    return (
      <>
        <CustomizedStatesModal
          isVisible={isCustomStatesModalVisible}
          onDismiss={() => this._toggleCustomStatesModal(false)}
          onUpdate={this._onLabelUpdate}
        />
        <div className="flowCustomizerWrapper">
          <div className="advancedActionsWrapper">
            <button onClick={this._toggleCustomStatesModal}>
              <i class="fas fa-cog"></i> &nbsp;Customize states
            </button>
          </div>
          <div className="editorActions">
            {this._isModified() ? (
              <>
                <button className="updateBtn" onClick={this._save}>
                  Save
                </button>
                <button className="resetBtn" onClick={this._reset}>
                  Reset
                </button>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="dragDropCustomizerWrapper">
            <FlowChart
              config={{ smartRouting: true }}
              chart={flowStates}
              callbacks={this.chartActions()}
              Components={{
                NodeInner: NodeInnerCustom,
              }}
            />
          </div>
          <div className="sideBar">
            <div className="sidebarHeader">States</div>
            <div className="sidebarBody">
              {this._getLeftStates().map((state, stateIndex) => (
                <div
                  className="option"
                  draggable={true}
                  key={state.Status}
                  onDragStart={(event) => {
                    event.dataTransfer.setData(
                      "react-flow-chart",
                      JSON.stringify(this._getDroppableNodeData(state))
                    );
                  }}
                >
                  <div className="label">{state.Status}</div>
                  <div
                    className="addBtn"
                    onClick={(e) => this._appendState(state)}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>
                  </div>
                </div>
              ))}
            </div>
            {selectedElement ? (
              <div className="actionBar">
                <div className="nodeAction">
                  <div className="labelPart">
                    <div className="label">Selected {selectedElement.type}</div>
                    <div className="value">{selectedElement.label}</div>
                  </div>
                  <div className="deleteBtnWrapper">
                    <button onClick={this._deleteElement}>
                      <i className="fa fa-trash" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowEditor);
