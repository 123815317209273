import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader } from "redux/actions/loader-data";
import { authProvider } from "../../../../config/authProvider";
import { showToast, deepClone } from "../../../../helper-methods/index";
import {
  getAllCheckins,
  updatePatientStatus,
  getVisitReasons,
} from "../../../../http-calls/index";
import {
  hideBottomLoader,
  showBottomLoader,
  showLoader,
} from "../../../../redux/actions/loader-data";
import RegisterPatientPrompt from "../register-patient-prompt/register-patient-prompt";
import "./checkins-view.scss";
import SearchInput from "modules/general/components/search-input/search-input";

let intervalRef = null;
const userLogs = {};

const CheckinsView = (props) => {
  const [checkins, setCheckins] = useState([]);
  const [filteredCheckins, setFilteredCheckins] = useState([]);
  const [visitReasons, setVisitReasons] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isPatientAddModalVisible, setIsPatientAddModalVisible] = useState(
    false
  );
  const [userInfo, setUserInfo] = useState(null);

  const _notify = (checkins) => {
    let shouldPlaySound = false;
    for (let checkin of checkins) {
      if (!userLogs[checkin.PatientID]) {
        shouldPlaySound = true;
        userLogs[checkin.PatientID] = true;
      }
    }
    if (shouldPlaySound) {
      _playSound();
    }
  }

  const _playSound = () => {
    const audio = new Audio(
      require("../../../../assets/sounds/kiosk-notification.wav")
    );
    audio.play();
  };

  const _loadCheckins = async () => {
    try {
      _initiateBackgroundRefresher();
      const checkins = await getAllCheckins(props.clinicId);
      _notify(checkins);
      setCheckins(checkins);
    } catch (error) {
      console.log("error :>> ", error);
      // Error while loading, so unset checkins
      setCheckins([]);
    }
  };

  const _assignToSelf = async (checkin) => {
    try {
      props.showBottomLoader("Updating");
      const {
        PatientID,
        PatientVisitID,
        internalcomments = "",
        publiccomments = "",
        FIN = null,
      } = checkin;
      await updatePatientStatus({
        PatientID,
        PatientVisitID,
        internalcomments,
        publiccomments,
        FIN,
        UpdatedBy: userInfo.account.userName,
        PatientStatusTypeID: 2,
      });
      props.setActiveView("myRegistraions");
      await _loadCheckins();
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      props.hideLoader();
      showToast("Error while assigning");
    }
  };

  const _refresh = async () => {
    props.showBottomLoader("Refreshing list");
    await _loadCheckins();
    props.hideLoader();
  };

  const _loadData = async () => {
    await _loadCheckins();
    props.hideLoader();
  };

  const _loadUserData = () => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadData();
      }, 5000);
    }
  };

  const _filterRows = () => {
    let filteredCheckins = deepClone(checkins);
    const lowerCasedSearchValue = searchValue.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredCheckins = filteredCheckins.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if ((`${row.FirstName} ${row.LastName}`).toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
      });
    }
    setFilteredCheckins(filteredCheckins);
  };

  useEffect(() => {
    if (props.activeView === "allCheckins") {
      props.showBottomLoader("Loading");
      _loadVisitReasons();
      _loadData();
    } else {
      setSearchValue("");
    }
  }, [props.activeView]);

  useEffect(() => {
    props.showBottomLoader("Loading");
    _loadData();
    _loadUserData();
    return () => {
      clearInterval(intervalRef);
      intervalRef = null;
    };
  }, []);

  const _loadVisitReasons = async () => {
    try {
      let visitReasons = [];
      const response = await getVisitReasons(props.clinicId);
      if (response && response.length) {
        visitReasons = response.map((reason) => reason.ReasonforVisit);
      }
      setVisitReasons(visitReasons);
    } catch (error) {}
  };


  useEffect(() => {
    _filterRows();
  }, [searchValue]);

  useEffect(() => {
    _filterRows();
    if (!isNaN(checkins.length) && props.setPatientCount) {
      props.setPatientCount(checkins.length)
    }
  }, [checkins])

  if (props.activeView === "allCheckins") {
    return (
      <div id="labAssistantView">
        <div className="tableActions spaceBetween">
          <SearchInput 
            value={searchValue}
            onChange={setSearchValue}
          />
          <button
            className="addPatient"
            onClick={(e) => setIsPatientAddModalVisible(true)}
          >
            <i class="fa fa-plus" aria-hidden="true"></i>
            &nbsp; Register Patient
          </button>
        </div>
        <div className="tableInternalScroll">
        <table>
          <thead>
            <tr>
              <th>Call Number</th>
              <th>Name</th>
              <th>Reason for Visit</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredCheckins.map((checkin) => (
              <tr key={checkin.PatientID}>
                <td data-column="Call Number">{checkin.CallNumber}</td>
                <td data-column="Name">
                  {checkin.FirstName + " " + checkin.LastName}
                </td>
                <td data-column="Reason for Visit">
                  {checkin.VisitType.toUpperCase()}
                </td>
                <td data-column="action">
                  <button
                    className="registerBtn"
                    onClick={(e) => _assignToSelf(checkin)}
                  >
                    Assign to Myself
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {checkins.length === 0 && <div id="noCell">No Checkins Available</div>}
        </div>
        <RegisterPatientPrompt
          onDismiss={(e) => setIsPatientAddModalVisible(false)}
          isVisible={isPatientAddModalVisible}
          clinicId={props.clinicId}
          visitReasons={visitReasons}

        />
      </div>
    );
  } else {
    return <></>;
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(CheckinsView);
