import {
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_BOTTOM_LOADER,
  HIDE_BOTTOM_LOADER,
} from "./action-types";

export const showLoader = (loaderText = "Loading") => {
  return {
    type: SHOW_LOADER,
    payload: {
      loaderText,
    },
  };
};

export const hideLoader = () => {
  return {
    type: HIDE_LOADER,
  };
};

export const showBottomLoader = (loaderText = "Syncing") => {
  return {
    type: SHOW_BOTTOM_LOADER,
    payload: {
      loaderText,
    },
  };
};

export const hideBottomLoader = () => {
  return {
    type: HIDE_BOTTOM_LOADER,
  };
};
