import React, { Component } from "react";
import "./summarized-stats.scss";
import DashboardStat from "../dashboard-stat/dashboard-stat";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { showToast } from "helper-methods";
import { getAvgWaitTime, getDailyVisits, getLanguageStats } from "http-calls";
import moment from "moment";
import Loader from "react-loader-spinner";

class SummarizedStats extends Component {
  state = {
    values: [],
    isLoaded: false,
    isLoading: false,
    languageStats: {
      isVisible: false,
    },
  };

  componentDidMount() {
    this._fetchStats();
  }

  _toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  _formatLanguageStats = (stats) => {
    const formattedStats = {
      languages: [],
      totalVisits: [],
      avgAge: [],
      isVisible: false,
    };
    if (stats?.length) {
      // Extract available languages
      formattedStats.isVisible = true;
      stats.forEach((stat) => {
        formattedStats.languages.push(stat?.Language || "NA");
        formattedStats.totalVisits.push(stat?.VisitCnt || "--");
        formattedStats.avgAge.push(this._toFixedIfNecessary(stat?.Age) || "--");
      });
    } else if (Object.keys(stats)) {
      formattedStats.isVisible = true;
      formattedStats.languages.push(stats?.Language || "NA");
      formattedStats.totalVisits.push(stats?.VisitCnt || "--");
      formattedStats.avgAge.push(this._toFixedIfNecessary(stats?.Age) || "--");
    }
    return formattedStats;
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.dateRange) !==
      JSON.stringify(this.props.dateRange)
    ) {
      this._fetchStats();
    }
  }

  _fetchStats = async () => {
    this._toggleLoader(true);
    try {
      const { dateRange } = this.props;
      const StartDate = moment(dateRange[0]).format("MM/DD/YYYY");
      const EndDate = moment(dateRange[1]).format("MM/DD/YYYY");
      const ClinicID =
        this.props.userData && this.props.userData.selectedClinic
          ? this.props.userData.selectedClinic.ClinicID
          : 1;
      const allResponses = await Promise.all([
        getDailyVisits({
          StartDate,
          EndDate,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 1,
          EndStage: 2,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 1,
          EndStage: 3,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 3,
          EndStage: 4,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 4,
          EndStage: 5,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 5,
          EndStage: 6,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 2,
          EndStage: 6,
          ClinicID,
        }),
        getAvgWaitTime({
          StartDate,
          EndDate,
          StartStage: 1,
          EndStage: 6,
          ClinicID,
        }),
      ]);
      const values = [];
      allResponses.forEach((response, responseIndex) => {
        if (responseIndex === 0) {
          values.push(response && response.VisitCnt ? response.VisitCnt : 0);
        } else {
          values.push(
            response && response.AVGTime
              ? this._toFixedIfNecessary(response.AVGTime / 60)
              : 0
          );
        }
      });
      const languageStatsResponse = await getLanguageStats({
        StartDate,
        EndDate,
        ClinicID,
      });
      const languageStats = this._formatLanguageStats(languageStatsResponse);
      this.setState({ isLoaded: true, values, languageStats }, () => {
        this._toggleLoader(false);
      });
    } catch (error) {
      this._toggleLoader(false);
      showToast("Server error", "error", {
        position: "bottom-right",
      });
    }
  };

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  render() {
    const { values, isLoading, languageStats } = this.state;
    const { isActive } = this.props;

    if (isActive) {
      if (isLoading) {
        return (
          <div className="dashboardLoaderWrapper">
            <Loader type="ThreeDots" color="black" height={50} width={50} />
          </div>
        );
      } else {
        return (
          <>
            <div className="statsWarpper">
              <DashboardStat
                headerLabel="Total # of Visits"
                value={values[0]}
              />
              <DashboardStat
                headerLabel="Sign-In To Register"
                value={`${values[1]} mins`}
                subHeaderLabel="Average of Sign To Register"
              />
              <DashboardStat
                headerLabel="Sign-In To Fin Creation"
                value={`${values[2]} mins`}
                subHeaderLabel="Average of Register To OrderActivation"
              />
              <DashboardStat
                headerLabel="OrderActivation to Pending Collection"
                value={`${values[3]} mins`}
                subHeaderLabel="Average of OrderActivation to Pending Collection"
                type="secondary"
              />
              <DashboardStat
                headerLabel="Pending Collection To Call Time"
                value={`${values[4]} mins`}
                subHeaderLabel="Average of Pending Collection To Call Time"
                type="secondary"
              />
              <DashboardStat
                headerLabel="Call Time to Completion"
                value={`${values[5]} mins`}
                subHeaderLabel="Average of Call Time to Completion"
                type="secondary"
              />
              <DashboardStat
                headerLabel="Total Lab Time"
                value={`${values[6]} mins`}
                subHeaderLabel="Average of Total Lab Time"
                type="secondary"
              />
              <DashboardStat
                headerLabel="Total Visit Time"
                value={`${values[7]} mins`}
                subHeaderLabel="Average of Total Completion"
                type="secondary"
              />
            </div>
            {languageStats?.isVisible ? (
              <div id="language-table" class="language-table">
                <table id="main-table" class="main-table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      {languageStats.languages.map(
                        (language, languageIndex) => (
                          <th scope="col" key={languageIndex}>
                            {language}
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Total Visit Count</th>
                      {languageStats.totalVisits.map((visit, visitIndex) => (
                        <td key={visitIndex}>{visit}</td>
                      ))}
                    </tr>
                    <tr>
                      <th>Average Age </th>
                      {languageStats.avgAge.map((age, ageIndex) => (
                        <td key={ageIndex}>{age}</td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </>
        );
      }
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummarizedStats);
