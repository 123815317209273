const chinese = {
  firstname: "名字",
  lastname: "姓",
  dob: "出生日期",
  dobformat: "日期格式: mm/dd/yyyy",
  phonenumber: "电话号码",
  phoneformat: "手机格式: 123-456-7890",
  hasappointment: "有一个预约？",
  requiredfields: "* 必填项",
  requiredwarning: "您必须输入所有必填字段，然后才能继续",
  dobwarning:
    "出生日期无效。请使用以下格式输入出生日期：mm/dd/yyyy。请注意：年份值必须介于1919和今年之间",
  phonenowarning: "输入的电话号码无效。请输入一个有效的电话号码",
  helpbtntext: "救命",
  homebutton: "家",
  scanid: "扫描我的ID",
  next: "下一个",
  providerinfotitle: "你来找谁",
  providerinfotxt: "请选择您在这里看到的提供者。",
  successtitle: "成功登录！",
  successtxt: "您已成功登录信息亭系统。请坐，因为我们工作人员会尽快与您联系！",
  helptitle: "信息亭帮助",
  helptxt1: "如果您需要帮助，则需要转到窗口并取消电子签到。",
  helptxt2: "如果您希望继续进行电子签到，请单击“关闭”按钮。",
  helpclose: "关",
  visitReason: "参观原因",
  previous: "以前",
  callnumberlabel: "四位数的号码（用于通知您服务的唯一ID） ",
  callnowarning: "输入的电话号码无效。请输入一个四位数的电话号码",
  startover: "重来",
  donthavephonenumber: "我没有电话号码",
  callnumberdisplaylabel: "您的电话号码是 ",
  waittext: "请坐下，有人会打给你",
};

export default chinese;
