import DRP from "@wojtekmaj/react-daterange-picker";
import React, { Component } from "react";
import "./date-range-picker.scss";

class DateRangePicker extends Component {
  state = {
    dateRange: [null, null],
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.dateRange) !==
      JSON.stringify(this.props.dateRange)
    ) {
      this._handleSelect(this.props.dateRange);
    }
  }
  _handleSelect = (dateRange) => {
    this.setState({ dateRange }, () => {
      if (this.props.onChange) {
        this.props.onChange(dateRange);
      }
    });
  };
  render() {
    const { dateRange } = this.state;
    return (
      <>
        <div className="dateRangerPickerWrapper">
          <DRP onChange={this._handleSelect} value={dateRange} />
        </div>
      </>
    );
  }
}

export default DateRangePicker;
