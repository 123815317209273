import React, { Component } from "react";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { showBottomLoader } from "redux/actions/loader-data";
import { hideBottomLoader } from "redux/actions/loader-data";
import ScreenAddEditDialog from "modules/screen-builder/components/screen-add-edit-dialog/screen-add-edit-dialog";
import { deepClone } from "helper-methods";
import { getAllScreensOfADevice, setScreen } from "http-calls";
import { showToast } from "helper-methods";
import { extractQueryParams } from "helper-methods";
import BreadCrumbNavigator from "modules/tv-table-customizer/components/breadcrumb-navigator/breadcrumb-navigator";

const initialState = {
  screens: [],
  screenAddEditPrompt: {
    isVisible: false,
    editMode: false,
    selectedScreen: null,
  },
  deviceId: 1,
  breadcrumbLinks: []
};

class ManageDevices extends Component {
  state = deepClone(initialState);

  async componentDidMount() {

    this.props.showLoader("Loading available screens");
    await this._setDeviceId();
    this._generateBreadCrumbLinks();
    await this._loadAllScreens();
    this.props.hideLoader();
  }

  _generateBreadCrumbLinks = () => {
    const { deviceId } = this.state;
    const breadcrumbLinks = [{
      path: "/screen-builder/manage-devices",
      label: "All Devices",
      icon: <i className="fas fa-home"></i>
    }, {
      path: `/screen-builder/manage-screens?deviceId=${deviceId}`,
      label: "Screens",
      icon: <i className="fa fa-desktop" aria-hidden="true"></i>,
      isActive: true
    }];
    this.setState({ breadcrumbLinks });
  }

  _setDeviceId = async () => {
    return new Promise((resolve, reject) => {
      const urlParms = extractQueryParams();
    if (urlParms.deviceId && urlParms.deviceId.length) {
      this.setState({ deviceId: urlParms.deviceId }, () => {
        resolve();
      });
    } else {
      this.props.history.push("/screen-builder");
    }
    });
  }

  _loadAllScreens = () => {
    return new Promise(async (resolve, reject) => {
      const { deviceId } = this.state;
      
      try {
        const screens = await getAllScreensOfADevice(
          deviceId
        );
        console.log("screens :>> ", screens);
        this.setState({ screens }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _showScreenAddPrompt = () => {
    let { screenAddEditPrompt } = this.state;
    screenAddEditPrompt.isVisible = true;
    screenAddEditPrompt.editMode = false;
    screenAddEditPrompt.selectedScreen = null;
    this.setState({ screenAddEditPrompt });
  };

  _editScreen = (screen) => {
    let { screenAddEditPrompt } = this.state;
    screenAddEditPrompt.isVisible = true;
    screenAddEditPrompt.editMode = true;
    screenAddEditPrompt.selectedScreen = screen;
    this.setState({ screenAddEditPrompt });
  };

  _hideScreenAddPrompt = () => {
    let { screenAddEditPrompt } = this.state;
    screenAddEditPrompt.isVisible = false;
    screenAddEditPrompt.editMode = false;
    screenAddEditPrompt.selectedScreen = null;
    this.setState({ screenAddEditPrompt });
  };

  _addNewScreen = async (screen) => {
    try {
      this.props.showLoader("Adding screen");
      await setScreen(screen);
      await this._loadAllScreens();
      this._hideScreenAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding screen :>> ", error);
      showToast("Unable to add screen");
      this.props.hideLoader();
    }
  };

  _updateScreen = async (screen) => {
    try {
      this.props.showLoader("Updating screen");
      await setScreen(screen);
      await this._loadAllScreens();
      this._hideScreenAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding screen :>> ", error);
      showToast("Unable to add screen");
      this.props.hideLoader();
    }
  };

  _manageColumns = (screen) => {
    const { deviceId } = this.state;
    
    this.props.history.push(
      `/screen-builder/manage-columns?screenId=${screen.DeviceScreenID}&deviceId=${deviceId}`
    );
  };

  render() {
    const { screens, screenAddEditPrompt, breadcrumbLinks } = this.state;

    return (
      <>
      <BreadCrumbNavigator
        links={breadcrumbLinks}
        {...this.props}
      />
        <div id="labAssistantView" className="userActivationWrapper">
          <div className="clinicTableAction">
            <button className="newButton" onClick={this._showScreenAddPrompt}>
              <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; &nbsp;
              Add New Screen
            </button>
          </div>
          <div className="tableInternalScroll">
          <table>
            <thead>
              <tr>
                <th>Screen Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {screens.map((screen) => (
                <tr>
                  <td>{screen.Screen}</td>
                  <td>
                    <button
                      className="editButton"
                      onClick={(e) => this._editScreen(screen)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;
                      Edit
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="editButton"
                      onClick={(e) => this._manageColumns(screen)}
                    >
                      <i className="fa fa-list" aria-hidden="true"></i> &nbsp;
                      Manage Columns
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {screens.length === 0 && <div id="noCell">No Screens Available</div>}
          </div>
        </div>
        <ScreenAddEditDialog
          deviceId={this.state.deviceId}
          editMode={screenAddEditPrompt.editMode}
          isVisible={screenAddEditPrompt.isVisible}
          selectedScreen={screenAddEditPrompt.selectedScreen}
          onDiscard={this._hideScreenAddPrompt}
          onScreenAdd={this._addNewScreen}
          onScreenUpdate={this._updateScreen}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevices);
