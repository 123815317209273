import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.js";
import React from "react";
import "./normal-select.scss";

const useStyles = makeStyles(basicsStyle);

const NormalSelect = (props) => {
  const {
    label = "Select",
    options = [],
    onSelect = () => {},
    value = "",
    disabled = false
  } = props;
  const classes = useStyles();
  return (
    <>
      {/*Select with pure css*/}
      <div className="select">
        <select
          className="select-text"
          required
          value={value}
          onChange={onSelect}
          disabled={disabled}
        >
          <option value={null} disabled selected />
          {options.map((option, index) => (
            <option value={option.key} key={index}>
              {option.label}
            </option>
          ))}
        </select>
        <span className="select-highlight" />
        <span className="select-bar" />
        <label className="select-label">{label}</label>
      </div>
      {/*Select with pure css*/}
    </>
  );
};

export default NormalSelect;
