import { authProvider } from "config/authProvider";
import React, { useEffect } from "react";
import Modal from "react-awesome-modal";
import "./logout-notifier.scss";
import { store } from "redux/store";
import { clearUserData } from "redux/actions/user-data";

let isTriggered = false;

const LogoutNotifier = (props) => {
  const { isVisible = false, reasonText = "" } = props;

  useEffect(() => {
    if (props.isVisible && !isTriggered) {
      isTriggered = true;
      setTimeout(() => {
        authProvider.logout();
        store.dispatch(clearUserData())
      }, 3000);
    }
  }, [props.isVisible]);

  return (
    <>
      <Modal
        visible={props.isVisible}
        width="400"
        height="280"
        effect="fadeInUp"
        onClickAway={() => {}}
      >
        <div id="logoutNotiferWrapper">
          <h2>Access denied</h2>
          <div className="bottomLoader">
            <div className="spinnerWrapper">
              <div class="loader"></div>
            </div>
            <h3>Logging you out</h3>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutNotifier;
