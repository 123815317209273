import React, { Component } from "react";
import LabAssistantView from "./pages/lab-assistant-view/lab-assistant-view";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../config/authProvider";
import Header from "./containers/header/header";
import AccessGuard from "guards/access-guard";

class LabAssistantModule extends Component {
  state = {};

  componentDidMount() {
  }

  render() {
    return (
      <>
        <AzureAD provider={authProvider} forceLogin={true}>
          <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin", "Registration"]}>
            <LabAssistantView {...this.props} />
          </AccessGuard>
        </AzureAD>
      </>
    );
  }
}

export default LabAssistantModule;
