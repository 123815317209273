import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const allOptions = [
  "First Name",
  "Last Name",
  "Reason for visit",
  "Status",
  "Wait time per stage",
  "Wait time of visit",
];

class SigninPatientsTableCustomizer extends Component {
  state = {
    tableOptions: {
      availableOptions: [],
      selectedOptions: [],
    },
  };

  componentDidMount() {
    this._initialize();
  }

  _initialize = () => {
    const { tableOptions } = this.state;
    tableOptions.availableOptions = allOptions.slice(4, 6);
    tableOptions.selectedOptions = allOptions.slice(0, 3);
    this.setState({ tableOptions });
  };

  _getTabClass = (tabIndex) => {
    const { activeTabIndex } = this.state;
    let classname = "tab ";
    if (tabIndex === activeTabIndex) {
      classname += "active";
    }
    return classname;
  };

  _setActiveTab = (index) => {
    this.setState({ activeTabIndex: index });
  };

  _onDragEnd = (result) => {
    const { tableOptions } = this.state;
    if (
      result.source &&
      result.source.droppableId &&
      result.destination &&
      result.destination.droppableId
    ) {
      if (result.source.droppableId === result.destination.droppableId) {
        // Re order in same array
        const [reorderedItem] = tableOptions[result.source.droppableId].splice(
          result.source.index,
          1
        );
        tableOptions[result.source.droppableId].splice(
          result.destination.index,
          0,
          reorderedItem
        );
      } else {
        // Transfer
        // Cut it from source array
        const [reorderedItem] = tableOptions[result.source.droppableId].splice(
          result.source.index,
          1
        );
        // Paste it to destination array
        tableOptions[result.destination.droppableId].splice(
          result.destination.index,
          0,
          reorderedItem
        );
      }
      this.setState({ tableOptions });
    }
  };
  render() {
    const { tableOptions } = this.state;

    return (
      <>
        <DragDropContext onDragEnd={this._onDragEnd}>
          <div className="availableOptions">
            <p>Available Options</p>
            <Droppable droppableId="availableOptions" direction="horizontal">
              {(provided) => (
                <div
                  className="optionsWrapper"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tableOptions.availableOptions.map(
                    (availableOption, availableOptionIndex) => (
                      <Draggable
                        draggableId={availableOption}
                        index={availableOptionIndex}
                        key={availableOptionIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="option"
                          >
                            {availableOption}
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
          <div className="selectedOptions">
            <p>Selected Options</p>
            <Droppable droppableId="selectedOptions" direction="horizontal">
              {(provided) => (
                <div
                  className="optionsWrapper"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {tableOptions.selectedOptions.map(
                    (availableOption, availableOptionIndex) => (
                      <Draggable
                        draggableId={availableOption}
                        index={availableOptionIndex}
                        key={availableOptionIndex}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="option"
                          >
                            {availableOption}
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </>
    );
  }
}

export default SigninPatientsTableCustomizer;
