import React, { Component } from 'react';
import "./breadcrumb-navigator.scss";

class BreadCrumbNavigator extends Component {
  state = {  }

  _navigate = (path) => {
    if (this.props.history) {
      this.props.history.push(path)
    }
  }

  _getItemClass = item => {
    let itemClass = "navItem";
    if (item.isActive) {
      itemClass += " active"
    }
    return itemClass;
  }

  render() { 
    const { links } = this.props;
    
    return (  
      <div className="screenNavigatorWrapper">
          <div className="screenNavigator">
            {
              links.map((link, linkIndex) => (
                <>
                <div className={this._getItemClass(link)} onClick={e => this._navigate(link.path)} key={linkIndex}>
                {/* <i className="fas fa-home"></i> */}
                {link.icon}
                {link.label}
                </div>
                {
                  (linkIndex + 1) < links.length? (
                    <div className="navSeparator">
                <i className="fas fa-caret-right"></i>
                </div>
                  ): <></>
                }
                </>
              ))
            }
          </div>
        </div>
    );
  }
}
 
export default BreadCrumbNavigator;