import React, { useState } from "react";
import "./progress-bar.scss";

const timeLineBalls = (n, onClick, current) =>
  Array(n)
    .fill(0)
    .map((i, index) => (
      <div
        key={index}
        className={`timeline__ball ${current >= index ? "active" : null}`}
      >
        {index + 1}
      </div>
    ));

const ProgressBar = (props) => {
  const { totalSteps, currentStep } = props;
  const [width, setWidth] = useState(2);
  const [shouldIncrement, setShouldIncrement] = useState(true);
  const intermediaryBalls = totalSteps - 2;
  const calculatedWidth = (currentStep / (intermediaryBalls + 1)) * 100;
  return (
    <div className="progressBarWrapper">
      <div className="timeline">
        <div
          className="timeline__progress"
          style={{ width: `${calculatedWidth}%` }}
        />
        {timeLineBalls(intermediaryBalls + 2, setWidth, currentStep)}
      </div>
      <div className="stepLabel">Step {currentStep + 1}</div>
    </div>
  );
};

export default ProgressBar;
