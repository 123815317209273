import { deepClone } from "helper-methods";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";
import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import Loader from "react-loader-spinner";
import "./customize-states-modal.scss";

const CustomizedStatesModal = ({
  isVisible = false,
  onDismiss = () => {},
  onUpdate,
}) => {
  const [idMappedStateInputs, setIdMappedStateInputs] = useState({});
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const _resetAll = () => {
    const copyOfIdMappedStateInputs = deepClone(idMappedStateInputs);
    Object.keys(copyOfIdMappedStateInputs).forEach((stateId) => {
      copyOfIdMappedStateInputs[stateId].inputValue = "";
    });
    setIdMappedStateInputs(copyOfIdMappedStateInputs);
  };

  const _prepareInputs = async () => {
    // First fetch merged states
    setIsLoaderVisible(true);
    const mergedStates = await ClinicStateHelper.fetchClinicStates();
    setIsLoaderVisible(false);
    const idMappedStateInputs = {};
    mergedStates.forEach((state) => {
      idMappedStateInputs[state.StatusID] = {
        inputValue: state.isCustom ? state.Status : "",
        label: state.standardState,
        statusId: state.StatusID,
      };
    });
    setIdMappedStateInputs(idMappedStateInputs);
  };

  const _onInputChange = (value, statusId) => {
    const copyOfIdMappedStateInputs = deepClone(idMappedStateInputs);
    copyOfIdMappedStateInputs[statusId].inputValue = value;
    setIdMappedStateInputs(copyOfIdMappedStateInputs);
  };

  const _onSave = async () => {
    setIsLoaderVisible(true);
    const status = [];
    Object.keys(idMappedStateInputs).forEach((statusId) => {
      if (idMappedStateInputs[statusId].inputValue.length) {
        status.push({
          CustomStatusName: idMappedStateInputs[statusId].inputValue,
          StatusID: statusId,
        });
      }
    });
    await ClinicStateHelper.setClinicCustomStates(status);
    await ClinicStateHelper.fetchClinicStates({ forceRefresh: true });
    setIsLoaderVisible(false);
    onDismiss();
    onUpdate();
  };

  useEffect(() => {
    if (isVisible) {
      _prepareInputs();
    }
  }, [isVisible]);

  const stateInputs = [];

  Object.keys(idMappedStateInputs).forEach((statusId) => {
    stateInputs.push({
      statusId,
      ...idMappedStateInputs[statusId],
    });
  });

  return (
    <>
      <Modal
        visible={isVisible}
        width="1100"
        height="90%"
        effect="fadeInUp"
        onClickAway={onDismiss}
      >
        <div className="customizeStateModalInnerWrapper">
          {isLoaderVisible ? (
            <div className="loaderWrapper">
              <Loader type="ThreeDots" color="#212121" height={60} width={60} />
            </div>
          ) : (
            <div className="csmTableWrapper">
              <h3>Manage custom states</h3>
              <div class="table">
                <div class="table-header">
                  <div class="header__item">
                    <a id="name" class="filter__link" href="#">
                      Original state label
                    </a>
                  </div>
                  <div class="header__item">
                    <a
                      id="wins"
                      class="filter__link filter__link--number"
                      href="#"
                    >
                      Custom state label
                    </a>
                  </div>
                </div>
                <div class="table-content">
                  {stateInputs?.map((state) => (
                    <div class="table-row" key={state.statusId}>
                      <div class="table-data">{state.label}</div>
                      <div class="table-data">
                        <input
                          value={state.inputValue}
                          onChange={(e) =>
                            _onInputChange(e.target.value, state.statusId)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="modalActions">
                <button className="confirm" onClick={_onSave}>
                  Save
                </button>
                <button className="resetAll" onClick={_resetAll}>
                  Reset all
                </button>
                <button className="cancel" onClick={onDismiss}>
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default CustomizedStatesModal;
