const arabic = {
  firstname: "الاسم الاول",
  lastname: "الكنية",
  dob: "تاريخ الولادة",
  dobformat: "صيغة التاريخ: mm/dd/yyyy",
  phonenumber: "رقم الهاتف",
  phoneformat: "تنسيق الهاتف: 123-456-7890",
  hasappointment: "لديك موعد؟",
  requiredfields: "* الحقول المطلوبة",
  requiredwarning: "يجب إدخال جميع الحقول المطلوبة قبل المتابعة",
  dobwarning:
    "تاريخ الميلاد غير صالح. يرجى إدخال تاريخ الميلاد باستخدام تنسيق: mm/dd/yyyy. يرجى ملاحظة: يجب أن تكون قيمة السنة بين 1919 وهذا العام",
  phonenowarning:
    "رقم الهاتف الذي تم إدخاله غير صالح. يرجى إدخال رقم هاتف صالح",
  helpbtntext: "مساعدة",
  homebutton: "الصفحة الرئيسية",
  scanid: "مسح هويتي",
  next: "التالى",
  providerinfotitle: "من أنت هنا لترى؟",
  providerinfotxt: "يرجى تحديد الموفر الذي تراه هنا.",
  successtitle: "وقعت بنجاح في!",
  successtxt:
    "لقد سجلت بنجاح في نظام الكشك. يرجى الحصول على مقعد حيث سيقوم شخص من موظفينا بالاتصال بك قريبًا!",
  helptitle: "كشك المساعدة",
  helptxt1:
    "إذا كنت بحاجة إلى مساعدة ، فستحتاج إلى الانتقال إلى النافذة وإلغاء تسجيل الوصول الإلكتروني.",
  helptxt2:
    '"إذا كنت ترغب في متابعة تسجيل الوصول الإلكتروني ، انقر فوق الزر "إغلاق"."',
  helpclose: "قريب",
  visitReason: "سبب الزيارة",
  previous: "السابق",
  callnumberlabel: "رقم مكون من أربعة أرقام (معرف فريد لإعلامك بالخدمات) ",
  callnowarning:
    "رقم الاتصال الذي تم إدخاله غير صالح. الرجاء إدخال رقم اتصال مكون من 4 أرقام",
    startover: "ابدأ من جديد",
  donthavephonenumber: "ليس لدي رقم هاتف",
  callnumberdisplaylabel: "رقم مكالمتك هو ",
  waittext: "يرجى الجلوس وسيتصل بك شخص ما"
};

export default arabic;
