import Header from "modules/general/containers/header/header";
import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../config/authProvider";
import AccessGuard from "guards/access-guard";
import ClinicSystemSettingsPage from "./pages/clinic-system-settings-page/clinic-system-settings-page";

class ClinicSystemsSettingsModule extends Component {
  state = {};

  render() {
    return (
      <>
        <AzureAD provider={authProvider} forceLogin={true}>
          <AccessGuard accessLevel={["SuperAdmin", "ClinicAdmin"]}>
            <Header>
              <Switch>
                <Route
                  path="/manage-clinic-system-settings"
                  component={ClinicSystemSettingsPage}
                />
                <Route
                  path="*"
                  render={() => (
                    <Redirect to="/manage-clinic-system-settings" />
                  )}
                />
              </Switch>
            </Header>
          </AccessGuard>
        </AzureAD>
      </>
    );
  }
}

export default ClinicSystemsSettingsModule;
