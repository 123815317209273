import React, { Component } from "react";
import "./auto-paginated-table.scss";

class AutoPaginatedTable extends Component {
  state = {
    rowsInView: [],
    totalIndexCount: 0,
    activeIndex: -1,
    isInitialLoadComplete: true,
  };
  intervalRef = null;

  componentDidMount() {
    this._initializeAutoPagination();
  }

  componentDidUpdate(prevProps) {
    const { isInitialLoadComplete } = this.state;
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
      isInitialLoadComplete
    ) {
      this.setState({ isInitialLoadComplete: false });
      this._updateView();
    }
  }

  _initializeAutoPagination = async () => {
    const { refreshIntervalInSeconds = 3 } = this.props;
    setInterval(() => {
      this._updateView();
    }, refreshIntervalInSeconds * 1000);
  };

  _updateView = () => {
    return new Promise((resolve, reject) => {
      let { rowsInView, totalIndexCount, activeIndex } = this.state;
      const { tableRows = [], rowsPerView = 3 } = this.props;
      // Calculate total avaiable indexes / pages for pagination
      totalIndexCount = Math.ceil(tableRows.length / rowsPerView);
      // Increase active index by 1
      activeIndex++;
      // Check if next index is still available or not (Index start from 0)
      if (activeIndex > totalIndexCount - 1) {
        // Then reset it
        activeIndex = 0;
      }
      // Populate rows for current index
      const startIndex = rowsPerView * activeIndex;
      let endIndex = parseInt(startIndex) + parseInt(rowsPerView);
      if (endIndex > tableRows.length - 1) {
        endIndex = tableRows.length;
      }
      rowsInView = tableRows.slice(startIndex, endIndex);
      this.setState({ rowsInView, activeIndex, totalIndexCount }, () => {
        resolve();
      });
    });
  };

  render() {
    const {
      tableHeaders = [],
      noPatientMessage = "No Patients",
      tableRows = [],
      tableTitle = "Patients",
    } = this.props;
    const { rowsInView } = this.state;

    return (
      <>
        <div className="table">
          <div className="tableHeader">{tableTitle}</div>
          <table>
            <thead>
              <tr>
                {tableHeaders.map((tableHeader, tableHeaderIndex) => (
                  <th key={tableHeaderIndex}>{tableHeader}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rowsInView.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={row.rowStyleObject ? row.rowStyleObject : {}}
                >
                  {row.cells.map((cell, cellIndex) => (
                    <td key={`${rowIndex}_${cellIndex}`}>{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {!tableRows.length ? <div id="noCell">{noPatientMessage}</div> : null}
        </div>
      </>
    );
  }
}

export default AutoPaginatedTable;
