export const updateWizardData = wizardData => {
    return {
        type: "UPDATE_WIZARD_DATA",
        payload: {
            wizardData
        }
    }
}

export const clearWizardData = () => {
    return {
        type: "CLEAR_WIZARD_DATA"
    }
}