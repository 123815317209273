import { deepClone, showToast } from "helper-methods";
import { getAllWorkflows, setWorkflow } from "http-calls";
import FlowAddEditPrompt from "modules/flow-customizer/components/flow-add-edit-prompt";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./manage-flows.scss";

const initialState = {
  flows: [],
  flowAddEditPrompt: {
    isVisible: false,
    editMode: false,
    selectedFlow: null,
  },
};

class ManageFlows extends Component {
  state = deepClone(initialState);

  async componentDidMount() {
    this.props.showLoader("Loading available flows");
    await this._loadAllFlows();
    this.props.hideLoader();
  }

  _loadAllFlows = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {
          selectedClinic: { ClinicID },
        } = this.props.userData;

        const flows = await getAllWorkflows(ClinicID);
        this.setState({ flows }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _showFlowAddPrompt = () => {
    let { flowAddEditPrompt } = this.state;
    flowAddEditPrompt.isVisible = true;
    flowAddEditPrompt.editMode = false;
    flowAddEditPrompt.selectedFlow = null;
    this.setState({ flowAddEditPrompt });
  };

  _editFlow = (flow) => {
    let { flowAddEditPrompt } = this.state;
    flowAddEditPrompt.isVisible = true;
    flowAddEditPrompt.editMode = true;
    flowAddEditPrompt.selectedFlow = flow;
    this.setState({ flowAddEditPrompt });
  };

  _hideFlowAddPrompt = () => {
    let { flowAddEditPrompt } = this.state;
    flowAddEditPrompt.isVisible = false;
    flowAddEditPrompt.editMode = false;
    flowAddEditPrompt.selectedFlow = null;
    this.setState({ flowAddEditPrompt });
  };

  _addNewFlow = async (flow) => {
    try {
      this.props.showLoader("Adding flow");
      await setWorkflow(flow);
      await this._loadAllFlows();
      this._hideFlowAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding flow :>> ", error);
      showToast("Unable to add flow");
      this.props.hideLoader();
    }
  };

  _updateFlow = async (flow) => {
    try {
      this.props.showLoader("Updating flow");
      await setWorkflow(flow);
      await this._loadAllFlows();
      this._hideFlowAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding flow :>> ", error);
      showToast("Unable to add flow");
      this.props.hideLoader();
    }
  };

  _openEditor = (flow) => {
    this.props.history.push(`/manage-flows/builder?flowId=${flow.WorkFlowID}`);
  };

  render() {
    const { flows, flowAddEditPrompt } = this.state;

    return (
      <>
        <div id="labAssistantView" className="userActivationWrapper">
          <div className="clinicTableAction manageFlowActions">
            {
              flows?.length? <></>: (
            <button className="newButton" onClick={this._showFlowAddPrompt}>
              <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; &nbsp;
              Add New Flow
            </button>

              )
            }
          </div>
          <table>
            <thead>
              <tr>
                <th>Flow Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {flows.map((flow) => (
                <tr>
                  <td>{flow.WorkFlow}</td>
                  <td>
                    <button
                      className="editButton"
                      onClick={(e) => this._editFlow(flow)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;
                      Edit
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="editButton"
                      onClick={(e) => this._openEditor(flow)}
                    >
                      <i className="fa fa-list" aria-hidden="true"></i> &nbsp;
                      Launch Builder
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {flows.length === 0 && <div id="noCell">No Flows Available</div>}
        </div>
        <FlowAddEditPrompt
          editMode={flowAddEditPrompt.editMode}
          isVisible={flowAddEditPrompt.isVisible}
          selectedFlow={flowAddEditPrompt.selectedFlow}
          onDiscard={this._hideFlowAddPrompt}
          onFlowAdd={this._addNewFlow}
          clinicId={this.props.userData.selectedClinic.ClinicID}
          onFlowUpdate={this._updateFlow}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageFlows);
