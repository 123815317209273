import React, { Component } from "react";
import "./patients-visit-details-table.scss";
import PatientLogViewerModal from "../patient-log-viewer-modal/patient-log-viewer-modal";

class PatientsVisitDetailsTable extends Component {
  state = {
    logPrompt: {
      isVisible: false,
      selectedPatient: null,
    },
  };

  _calculateAverageOf = (stage) => {
    const { tableData } = this.props;
    let sum = 0;
    tableData.forEach((row) => {
      if (row.times[stage] && !isNaN(row.times[stage])) {
        sum += parseFloat(row.times[stage]);
      }
    });
    return this._toFixedIfNecessary(sum / tableData.length);
  };

  _showLogPrompt = (patient) => {
    this.setState({
      logPrompt: {
        isVisible: true,
        selectedPatient: patient,
      },
    });
  };

  _hideLogPrompt = () => {
    this.setState({
      logPrompt: {
        isVisible: false,
        selectedPatient: null,
      },
    });
  };

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  render() {
    const { logPrompt } = this.state;
    const { tableData } = this.props;
    return (
      <>
        <PatientLogViewerModal
          isVisible={logPrompt.isVisible}
          patient={logPrompt.selectedPatient}
          onDismiss={this._hideLogPrompt}
        />
        <div className="patientVisitDetailsTable">
          <div id="table-scroll" class="table-scroll">
            <table id="main-table" class="main-table">
              <thead>
                <tr>
                  <th scope="col">Registered By</th>
                  <th scope="col">Called By</th>
                  <th scope="col">Call Number</th>
                  <th scope="col">First Name</th>
                  <th scope="col">Last Name</th>
                  <th scope="col">Sign To Register</th>
                  <th scope="col">Register to OrderActivation</th>
                  <th scope="col">OrderActivation to PendColl</th>
                  <th scope="col">PendColl To CallTime</th>
                  <th scope="col">CallTime to Complete</th>
                  <th scope="col">Total Completion</th>
                  <th scope="col">No Answer Time</th>
                  <th scope="col">Order Issue Time</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    onClick={(e) => {
                      this._showLogPrompt(row.patientData);
                    }}
                  >
                    <td>{row.registeredBy}</td>
                    <td>{row.calledBy}</td>
                    <td>{row.patientCallNumber}</td>
                    <td>{row.firstName}</td>
                    <td>{row.lastName}</td>
                    <td>{row.times.signInToRegister}</td>
                    <td>{row.times.registerToOrderActivation}</td>
                    <td>{row.times.orderActivationToPendingCollection}</td>
                    <td>{row.times.pendingCollectionToCalled}</td>
                    <td>{row.times.calledToCompleted}</td>
                    <td>{row.times.totalCompletion}</td>
                    <td>{row.times.noAnswer}</td>
                    <td>{row.times.orderIssueTime}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th>Total Visit Count</th>
                  <td>
                    {" "}
                    <strong>{tableData.length}</strong>{" "}
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <strong>Avg</strong>
                  </td>
                  <td>{this._calculateAverageOf("signInToRegister")}</td>
                  <td>
                    {this._calculateAverageOf("registerToOrderActivation")}
                  </td>
                  <td>
                    {this._calculateAverageOf(
                      "orderActivationToPendingCollection"
                    )}
                  </td>
                  <td>
                    {this._calculateAverageOf("pendingCollectionToCalled")}
                  </td>
                  <td>{this._calculateAverageOf("calledToCompleted")}</td>
                  <td>{this._calculateAverageOf("totalCompletion")}</td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </>
    );
  }
}

export default PatientsVisitDetailsTable;
