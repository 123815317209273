const vietnamese = {
  firstname: "Tên đầu tiên",
  lastname: "Họ",
  dob: "Ngày sinh",
  dobformat: "Định dạng ngày tháng: mm/dd/yyyy",
  phonenumber: "Số điện thoại",
  phoneformat: "Định dạng điện thoại: 123-456-7890",
  hasappointment: "Có một cuộc hẹn?",
  requiredfields: "* Phần bắt buộc",
  requiredwarning:
    "Bạn phải nhập tất cả các trường bắt buộc trước khi tiếp tục",
  dobwarning:
    "Ngày sinh không hợp lệ. Vui lòng nhập ngày sinh bằng định dạng: mm/dd/yyyy. Xin lưu ý: Giá trị năm phải nằm trong khoảng từ 1919 đến năm nay",
  phonenowarning:
    "Số điện thoại đã nhập không hợp lệ. Xin vui lòng nhập một số điện thoại hợp lệ",
  helpbtntext: "Cứu giúp",
  homebutton: "Trang Chủ",
  scanid: "Quét Id của tôi",
  next: "Kế tiếp",
  providerinfotitle: "Bạn ở đây để xem ai?",
  providerinfotxt: "Vui lòng chọn nhà cung cấp bạn đang ở đây để xem.",
  successtitle: "Đăng nhập thành công!",
  successtxt:
    "Bạn đã đăng nhập thành công vào Hệ thống kiosk. Xin vui lòng có chỗ ngồi vì ai đó từ nhân viên của chúng tôi sẽ liên lạc với bạn trong thời gian ngắn!",
  helptitle: "Ki-ốt Trợ giúp",
  helptxt1:
    "Nếu bạn cần giúp đỡ, bạn sẽ cần đến cửa sổ và hủy đăng ký điện tử của bạn.",
  helptxt2:
    "Nếu bạn muốn tiếp tục đăng ký điện tử của mình, hãy nhấp vào nút Đóng.",
  helpclose: "Đóng",
  visitReason: "Lý do đến thăm",
  previous: "Trước",
  callnumberlabel:
    "Số bốn chữ số (ID duy nhất để thông báo cho bạn về các dịch vụ) ",
  callnowarning:
    "Số cuộc gọi đã nhập không hợp lệ. Vui lòng nhập số điện thoại gồm 4 chữ số",
  startover: "Bắt đầu lại",
  donthavephonenumber: "TÔI KHÔNG CÓ SỐ ĐIỆN THOẠI",
  callnumberdisplaylabel: "Số cuộc gọi của bạn là ",
  waittext: "Vui lòng ngồi xuống và sẽ có người gọi cho bạn",
};

export default vietnamese;
