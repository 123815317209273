import React, { Component } from "react";
import "./sidebar-filter.scss";

class SidebarFilter extends Component {
  state = { selectedOptions: [] };

  _updateOptionStatus = (option) => {
    const { selectedOptions } = this.state;
    const selectedOptionIndex = selectedOptions.findIndex((o) => o === option);
    if (selectedOptionIndex > -1) {
      selectedOptions.splice(selectedOptionIndex, 1);
    } else {
      selectedOptions.push(option);
    }
    this.setState({ selectedOptions }, () => {
      if (this.props.onOptionsUpdate) {
        this.props.onOptionsUpdate(selectedOptions);
      }
    });
  };

  render() {
    const { headerLabel, allOptions } = this.props;
    const { selectedOptions } = this.state;

    return (
      <div className="dashboardFilterWrapper">
        <div className="filterHeader">{headerLabel}</div>
        <div className="filterBody">
          {allOptions.map((option, optionIndex) => (
            <div className="filterOptionWRapper" key={optionIndex}>
              <input
                type="checkbox"
                onChange={(e) => this._updateOptionStatus(option)}
              />{" "}
              {option}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default SidebarFilter;
