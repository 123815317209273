import React from "react";
import Modal from "react-awesome-modal";
import "./account-clinic-selector.scss";

const AccountClinicSelector = (props) => {
  const {
    isVisible = false,
    onClinicSelect = (selectedClinic) => {},
    onLogout = () => {},
    clinics = [],
  } = props;
  return (
    <>
      <Modal
        visible={isVisible}
        width="400"
        height="500"
        effect="fadeInUp"
        onClickAway={() => {}}
      >
        <div id="accountClinicSelectorWrapper">
          <h3>Please select a clinic to continue</h3>
          <div className="clinics">
            {clinics.map((clinic) => (
              <div
                className="clinic"
                key={clinic.ClinicID}
                onClick={(e) => onClinicSelect(clinic)}
              >
                <div className="iconWrapper">
                  <i className="fa fa-building" aria-hidden="true"></i>
                </div>
                &nbsp; &nbsp; {clinic.Name}
              </div>
            ))}
          </div>
          <div className="logout" onClick={onLogout}>
            <i className="fa fa-sign-out" aria-hidden="true"></i>
            &nbsp; &nbsp; Logout
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountClinicSelector;
