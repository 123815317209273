import moment from "moment";
import React, { useState } from "react";
import { EventEmitter } from "utils/event-emitter";

const actions = [
  {
    label: "Select an Action",
    key: null,
  },
  {
    label: "Return to registration desk",
    key: "Return to registration desk",
  },
  {
    label: "Pending Collection",
    key: "Pending Collection",
  },
  {
    label: "Ready for order activation",
    key: "Ready for order activation",
  },
];

const MyRegistrationRow = (props) => {
  const {
    registration,
    onChange,
    onStatusUpdate,
    columns,
    isSmsCommunicationEnabled,
  } = props;
  const [fin, setFin] = useState("");
  const _updateComment = (comment) => {
    onChange({
      ...registration,
      internalcomments: comment,
    });
  };

  const _updateStatus = (status) => {
    props.onStatusUpdate(status);
  };

  const _updateFin = (fin) => {
    onChange({
      ...registration,
      FIN: fin,
    });
  };

  const _editPatient = () => {
    EventEmitter.dispatch("edit-patient", registration);
  };
  const _renderCells = (columnName, registration) => {
    switch (columnName) {
      case "Call Number": {
        return <td data-column="Call Number">{registration.CallNumber}</td>;
      }
      case "FirstName": {
        return (
          <td data-column="Name" className="nameCell">
            <p>{registration.FirstName}</p>
          </td>
        );
      }
      case "LastName": {
        return (
          <td data-column="Name" className="nameCell">
            <p>{registration.LastName}</p>
          </td>
        );
      }
      case "DOB": {
        return (
          <td data-column="DOB">
            {moment(registration.DOB).format(process.env.REACT_APP_DATE_FORMAT)}
          </td>
        );
      }
      case "FIN": {
        return (
          <td data-column="FIN" className="finCell">
            <input
              placeholder="Enter FIN No"
              value={registration.FIN}
              onChange={(e) => _updateFin(e.target.value)}
            />
          </td>
        );
      }
      case "Reason For Visit": {
        return (
          <td data-column="RV">
            {registration.VisitType ? registration.VisitType : "--"}
          </td>
        );
      }
      case "Reason for Visit": {
        return (
          <td data-column="RV">
            {registration.VisitType ? registration.VisitType : "--"}
          </td>
        );
      }
      case "Mobile Number": {
        return (
          <td data-column="RV">
            {registration.MobileNumber ? registration.MobileNumber : "--"}
          </td>
        );
      }
      case "Status": {
        return <td data-column="Status">Registered</td>;
      }
      case "Wait Time Per Stage": {
        return <td data-column="WTPS">--</td>;
      }
      case "Wait Time of Visit": {
        return <td data-column="WTOV">--</td>;
      }
      case "Provider": {
        return <td data-column="P">--</td>;
      }
    }
  };
  console.log("columns", columns);
  return (
    <tr>
      {columns?.map((column, columnIndex) => (
        <React.Fragment key={columnIndex}>
          {_renderCells(column, registration)}
        </React.Fragment>
      ))}
      <td data-column="Comments">
        <textarea
          className="customInput"
          value={registration.internalcomments}
          onChange={(e) => _updateComment(e.target.value)}
        />
      </td>
      <td data-column="Status">
        <div className="buttonsWrapper">
          <button className="actions" onClick={props.showActionModal}>
            <i className="fa fa-cog" aria-hidden="true"></i>
          </button>

          {isSmsCommunicationEnabled ? (
            <button className="messages" onClick={props.showCommunicationModal}>
              {/* <div className="unreadNotification"></div> */}
              <i className="fa fa-envelope-open" aria-hidden="true"></i>
            </button>
          ) : (
            <></>
          )}
          <button className="editBtn" onClick={_editPatient}>
            {/* <div className="unreadNotification"></div> */}
            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
            &nbsp; Edit
          </button>
        </div>
      </td>
    </tr>
  );
};

export default MyRegistrationRow;
