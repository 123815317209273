import React, { Component } from "react";
import { connect } from "react-redux";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { showBottomLoader } from "redux/actions/loader-data";
import { hideBottomLoader } from "redux/actions/loader-data";
import DeviceAddEditDialog from "modules/screen-builder/components/device-add-edit-dialog/device-add-edit-dialog";
import { deepClone } from "helper-methods";
import { getAllDevicesOfAClinic, setClinicDevice } from "http-calls";
import { showToast } from "helper-methods";
import BreadCrumbNavigator from "modules/tv-table-customizer/components/breadcrumb-navigator/breadcrumb-navigator";

const DEVICE_TYPE_ID_MAPPINGS = {
  1: 'Public',
  2: 'Registrations',
  3: 'Order Activation',
}

const initialState = {
  devices: [],
  deviceAddEditPrompt: {
    isVisible: false,
    editMode: false,
    selectedDevice: null,
  },
  breadcrumbLinks: []
};

class ManageDevices extends Component {
  state = deepClone(initialState);

  async componentDidMount() {
    this._generateBreadCrumbLinks();
    this.props.showLoader("Loading available devices");
    await this._loadAllDevices();
    this.props.hideLoader();
  }


_generateBreadCrumbLinks = () => {
  const breadcrumbLinks = [{
    path: "/screen-builder/manage-devices",
    label: "All Devices",
    icon: <i className="fas fa-home"></i>,
    isActive: true
  }];
  this.setState({ breadcrumbLinks });
}

  _loadAllDevices = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const devices = await getAllDevicesOfAClinic(
          this.props.userData.selectedClinic.ClinicID
        );
        console.log("devices :>> ", devices);
        this.setState({ devices }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _showDeviceAddPrompt = () => {
    let { deviceAddEditPrompt } = this.state;
    deviceAddEditPrompt.isVisible = true;
    deviceAddEditPrompt.editMode = false;
    deviceAddEditPrompt.selectedDevice = null;
    this.setState({ deviceAddEditPrompt });
  };

  _editDevice = (device) => {
    let { deviceAddEditPrompt } = this.state;
    deviceAddEditPrompt.isVisible = true;
    deviceAddEditPrompt.editMode = true;
    deviceAddEditPrompt.selectedDevice = device;
    this.setState({ deviceAddEditPrompt });
  };

  _hideDeviceAddPrompt = () => {
    let { deviceAddEditPrompt } = this.state;
    deviceAddEditPrompt.isVisible = false;
    deviceAddEditPrompt.editMode = false;
    deviceAddEditPrompt.selectedDevice = null;
    this.setState({ deviceAddEditPrompt });
  };

  _addNewDevice = async (device) => {
    try {
      this.props.showLoader("Adding device");
      await setClinicDevice(device);
      await this._loadAllDevices();
      this._hideDeviceAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding device :>> ", error);
      showToast("Unable to add device");
      this.props.hideLoader();
    }
  };

  _updateDevice = async (device) => {
    try {
      this.props.showLoader("Updating device");
      await setClinicDevice(device);
      await this._loadAllDevices();
      this._hideDeviceAddPrompt();
      this.props.hideLoader();
    } catch (error) {
      console.log("Error while adding device :>> ", error);
      showToast("Unable to add device");
      this.props.hideLoader();
    }
  };

  _manageScreens = (device) => {
    this.props.history.push(
      `/screen-builder/manage-screens?deviceId=${device.DeviceID}`
    );
  };

  render() {
    const { devices, deviceAddEditPrompt, breadcrumbLinks } = this.state;

    return (
      <>
      <BreadCrumbNavigator
        links={breadcrumbLinks}
        {...this.props}
      />
        <div id="labAssistantView" className="userActivationWrapper tvBuilder">
          <div className="clinicTableAction">
            <button className="newButton" onClick={this._showDeviceAddPrompt}>
              <i className="fa fa-plus" aria-hidden="true"></i> &nbsp; &nbsp;
              Add New Device
            </button>
          </div>
          <table>
            <thead>
              <tr>
                <th>Device Name</th>
                <th>Device Location</th>
                <th>Device Id</th>
                <th>Device Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {devices.map((device) => (
                <tr>
                  <td>{device.Device}</td>
                  <td>{device.ClinicDeviceLocation}</td>
                  <td>{device.DeviceID}</td>
                  <td>{DEVICE_TYPE_ID_MAPPINGS[device.DeviceTypeID]}</td>
                  <td>
                    <button
                      className="editButton"
                      onClick={(e) => this._editDevice(device)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> &nbsp;
                      Edit
                    </button>
                    &nbsp; &nbsp;
                    <button
                      className="editButton"
                      onClick={(e) => this._manageScreens(device)}
                    >
                      <i className="fa fa-tv" aria-hidden="true"></i> &nbsp;
                      Manage Screens
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {devices.length === 0 && <div id="noCell">No Devices Available</div>}
        </div>
        {/* props.userData.selectedClinic.ClinicID */}
        <DeviceAddEditDialog
          clinicId={this.props.userData.selectedClinic.ClinicID}
          editMode={deviceAddEditPrompt.editMode}
          isVisible={deviceAddEditPrompt.isVisible}
          selectedDevice={deviceAddEditPrompt.selectedDevice}
          onDiscard={this._hideDeviceAddPrompt}
          onDeviceAdd={this._addNewDevice}
          onDeviceUpdate={this._updateDevice}
          deviceOptions={Object.keys(DEVICE_TYPE_ID_MAPPINGS).map(type => ({
            label: DEVICE_TYPE_ID_MAPPINGS[type],
            key: type
          }))}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDevices);
