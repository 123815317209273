import { authProvider } from "config/authProvider";
import { store } from "redux/store";

export const handleErrorIfAvailable = (httpResponse) => {
  switch (httpResponse.status) {
    case 401: {
      const state = store.getState();
      if (
        !(state && state.utilsData && state.utilsData.isTokenRefreshPending)
      ) {
        // Token expired
        alert("Sorry you are not authorized to access the portal");
        authProvider.logout();
      }
    }
  }
};
