

const INITIAL_STATE = {
    clinicApps: []
}

export const clinicSettingsReducer = (state = INITIAL_STATE, action) => {
    let newState = { ...state };
    switch (action.type) {
        case 'SET_CLINIC_APPS': {
            newState = {...newState, clinicApps: action.payload.apps}
            break;
        }
        case 'CLEAR_CLINIC_APPS': {
            newState = INITIAL_STATE;
            break;
        }
        default: {
            
        }
    }
    return newState;
}
