import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ExitToApp from "@material-ui/icons/ExitToApp";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ListIcon from "@material-ui/icons/List";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import Button from "components/CustomButtons/Button.js";
import React from "react";
import MatHeader from "../../../../components/Header/Header.js";
import "./header.scss";

const useStyles = makeStyles(navbarsStyle);

const Header = props => {
  const classes = useStyles();
  
  return (
    <>
      <MatHeader
        brand="Phlebotomist"
        color="#990000"
        links={
          <List className={classes.list + " " + classes.mlAuto}>
            {props.selectedUser ? (
              <>
                <ListItem className={classes.listItem}>
                  <div className={classes.navLink + " userWrapper"}>
                    <AccountCircle />
                    {props.selectedUser.FirstName + " " + props.selectedUser.LastName}
                  </div>
                </ListItem>
                <ListItem className={classes.listItem}>
                  <Button
                    className={classes.navLink + " " + classes.navLinkActive}
                    onClick={() => props.logout()}
                    color="transparent"
                  >
                    <ExitToApp />
                  </Button>
                </ListItem>
              </>
            ) : null}
          </List>
        }
      />
    </>
  );
};

export default Header;
