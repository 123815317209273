import React from "react";
import { useState } from "react";

const AppBox = (props) => {
  const [isEnabled, setIsEnabled] = useState(props.app.isEnabled);

  return (
    <div className="appWrapper">
      <div className="switchBtn">
        <div className="tg-list-item">
          <input
            className="tgl tgl-light"
            id={`cb${props.app.AppAddOnID}`}
            type="checkbox"
            checked={isEnabled}
            onChange={(e) => {
              if (isEnabled) {
                // Disable
                props.disableApp({AppAddOnID: props.app.AppAddOnID, AppAddOn: props.app.AppAddOn});
              } else {
                // Enable
                props.enableApp({AppAddOnID: props.app.AppAddOnID, AppAddOn: props.app.AppAddOn});
              }
              setIsEnabled(!isEnabled);
            }}
          />
          <label className="tgl-btn" htmlFor={`cb${props.app.AppAddOnID}`} />
        </div>
      </div>
      <div className="appBody">
        <i className="fas fa-cogs"></i>
        <p>{props.app.AppAddOn}</p>
      </div>
      <div className="appFooter">
        {isEnabled ? <div className="enabled">Enabled</div> : <></>}
      </div>
    </div>
  );
};

export default AppBox;
