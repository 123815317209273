import { makeStyles } from "@material-ui/core/styles";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";
import { extractQueryParams } from "helper-methods/index.js";
import { initiateDashboard } from "library-scripts/dashboard.js";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { authProvider } from "../../../../config/authProvider";
import Prompt from "../../../general/components/prompt/prompt";
import "./header.scss";
import { getSTaffUserInfo } from "http-calls";
import { clearUserData } from "redux/actions/user-data";
import { store } from "redux/store";
import { connect } from "react-redux";
import { EventEmitter } from "utils/event-emitter";
import { getCurrentClinicDetails } from "http-calls";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";

const useStyles = makeStyles(navbarsStyle);

const sideLinks = [
  {
    path: "/dashboard",
    label: "Dashboard",
    passClinicId: false,
    icon: <i class="fa fa-bar-chart" aria-hidden="true"></i>,
    hasAccess: (userInfo) => {
      return true;
    },
  },
  {
    path: "/registration",
    label: "Registrations",
    statusId: 2,
    passClinicId: false,
    icon: <i className="material-icons">list</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin" ||
          userInfo.UserAdminRole === "Registration")
      ) {
        return true;
      }
    },
  },
  {
    path: "/orderactivation",
    label: "Order Activations",
    statusId: 3,
    passClinicId: false,
    icon: <i className="material-icons">how_to_reg</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin" ||
          userInfo.UserAdminRole === "OrderActivation")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-clinic-apps",
    label: "Manage Apps",
    passClinicId: false,
    icon: <i className="material-icons">library_add_check</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-users",
    label: "Manage Users",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">group</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-clinics",
    label: "Manage Clinics",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">store</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        userInfo.UserAdminRole === "SuperAdmin"
      ) {
        return true;
      }
    },
  },
  {
    path: "/screen-builder",
    label: "Manage Screens",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">video_settings</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-flows",
    label: "Manage flows",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">account_tree</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-visit-options",
    label: "Manage Visit Options",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">library_add_check</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/manage-clinic-system-settings",
    label: "Manage System Settings",
    passClinicId: false,
    isManageSection: true,
    icon: <i className="material-icons">brightness_high</i>,
    hasAccess: (userInfo) => {
      if (
        userInfo &&
        userInfo.UserAdminRole &&
        (userInfo.UserAdminRole === "SuperAdmin" ||
          userInfo.UserAdminRole === "ClinicAdmin")
      ) {
        return true;
      }
    },
  },
  {
    path: "/faqs",
    label: "Documentation",
    passClinicId: false,
    additionalClass: "bottomElements",
    icon: <i className="fa fa-question-circle-o" aria-hidden="true"></i>,
    hasAccess: (userInfo) => {
      return true;
    },
  },
];

const Header = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [formattedSidelinks, setFormattedSidelinks] = useState([]);
  const [isLogoutPromptVisible, setISLogoutPromptVisible] = useState(false);
  const classes = useStyles();
  const [params, setParams] = useState("");
  const [userAccess, setUserAccess] = useState(null);
  const [clinicDetails, setClinicDetails] = useState(null);
  const sidebarToggleBtnRef = useRef(null);

  const _logout = () => {
    setISLogoutPromptVisible(false);
    store.dispatch(clearUserData());
    authProvider.logout();
  };

  const _showQuickSearch = () => {
    EventEmitter.dispatch("onQuickSearch");
  };

  const _setParams = () => {
    const params = extractQueryParams();
    const clinicId = Object.keys(params).find(
      (param) => param.toLocaleLowerCase() === "clinicid"
    );
    if (clinicId) {
      setParams(`?clinicId=${params[clinicId]}`);
    }
  };

  const _getUserInfo = async () => {
    const userInfo = authProvider.getAccountInfo();
    // console.log('userInfo :>> ', userInfo);
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
        setUserAccess(res[0]);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  useEffect(() => {
    const userInfo = authProvider.getAccountInfo();
    setUserInfo(userInfo);
    setTimeout(() => {
      initiateDashboard();
    }, 1000);
    setTimeout(() => {
      if (sidebarToggleBtnRef.current) {
        sidebarToggleBtnRef.current.click();
      }
    }, 1100);
    _getUserInfo();
    _setParams();
  }, []);

  useEffect(() => {
    if (props.userData.selectedClinic.ClinicID) {
      _fetchClinicDetails();
    }
  }, [props.userData.selectedClinic]);

  const _fetchClinicDetails = async () => {
    try {
      const clinicDetails = await getCurrentClinicDetails(props.userData.selectedClinic.ClinicID);
      if (clinicDetails && clinicDetails.length && clinicDetails[0]) {
        setClinicDetails(clinicDetails[0]);
      }
    } catch (error) {
      
    }
  }

  const _isLinkActive = (link) => {
    if (window.location.pathname.indexOf(link) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const _showActiveLinkLabel = () => {
    const activeLink = sideLinks.find(
      (link) => window.location.pathname.indexOf(link.path) > -1
    );
    return activeLink ? activeLink.label : "";
  };

  const _formatSidelinks = async () => {
    try {
      await ClinicStateHelper.fetchClinicStates();
      const { clinicCustomStates } = ClinicStateHelper;
      sideLinks.forEach(link => {
        if (link.statusId) {
          const customStatus = clinicCustomStates.find(s => s.StatusID === link.statusId);
          if (customStatus) {
            link.label = customStatus.CustomStatusName;
          }
        }
      })
      setFormattedSidelinks(sideLinks);
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    _formatSidelinks();
  }, []);

  return (
    <div>
      <div className="wrapper ">
        <div
          className="sidebar"
          data-color="white"
          data-background-color="black"
          data-image="../assets/img/sidebar-1.jpg"
        >
          <div className="logo">
            <a href="#" className="simple-text logo-mini"></a>
            <a href="#" className="simple-text logo-normal" id="telehealthLogo">
              Keck
            </a>
          </div>
          <div className="sidebar-wrapper">
            <ul className="nav">
              {sideLinks.map((link, linkIndex) => (
                <>
                  {link.hasAccess(userAccess) && !link.isManageSection ? (
                    <li
                      key={linkIndex}
                      className={
                        "nav-item " +
                        (link.additionalClass ? link.additionalClass : "") +
                        (_isLinkActive(link.path) ? " active " : "")
                      }
                    >
                      <Link
                        className="nav-link"
                        to={
                          link.path +
                          (link.passClinicId
                            ? props.userData &&
                              props.userData.selectedClinic &&
                              props.userData.selectedClinic.ClinicID
                              ? `?clinicId=${props.userData.selectedClinic.ClinicID}`
                              : ""
                            : "")
                        }
                      >
                        {link.icon}
                        <p> {link.label} </p>
                      </Link>
                    </li>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </ul>
          </div>
        </div>
        <div className="main-panel">
          {/* Navbar */}
          <nav className="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top ">
            <div className="container-fluid">
              <div className="navbar-wrapper">
                <div className="navbar-minimize">
                  <button
                    id="minimizeSidebar"
                    className="btn btn-just-icon btn-white btn-fab btn-round"
                    ref={sidebarToggleBtnRef}
                  >
                    <i className="material-icons text_align-center visible-on-sidebar-regular">
                      more_vert
                    </i>
                    <i className="material-icons design_bullet-list-67 visible-on-sidebar-mini">
                      view_list
                    </i>
                  </button>
                </div>
                <a className="navbar-brand" href="javascript:;">
                  {_showActiveLinkLabel()}
                </a>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                aria-controls="navigation-index"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
                <span className="navbar-toggler-icon icon-bar" />
              </button>
              <div className="collapse navbar-collapse justify-content-start"></div>
              <div className="collapse navbar-collapse justify-content-end">
                <button
                  className="quickPatientSearchBtn"
                  onClick={_showQuickSearch}
                >
                  <i className="fa fa-users" aria-hidden="true" />
                  &nbsp; Patient Quick Search
                </button>
                <ul className="navbar-nav">
                  {userInfo && userInfo.account ? (
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link headerUser"
                        href="javascript:;"
                        id="navbarDropdownProfile"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i className="material-icons">person</i>
                        {userInfo.account.name}
                        <p className="d-lg-none d-md-block">Account</p>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="navbarDropdownProfile"
                      >
                        {sideLinks.map((link, linkIndex) => (
                          <>
                            {link.hasAccess(userAccess) &&
                            link.isManageSection ? (
                              <a
                                key={linkIndex}
                                className="dropdown-item"
                                href="#"
                                onClick={(e) => e.preventDefault()}
                              >
                                <Link
                                  className="nav-link"
                                  to={
                                    link.path +
                                    (link.passClinicId
                                      ? props.userData &&
                                        props.userData.selectedClinic &&
                                        props.userData.selectedClinic.ClinicID
                                        ? `?clinicId=${props.userData.selectedClinic.ClinicID}`
                                        : ""
                                      : "")
                                  }
                                >
                                  <div className="manage-section">
                                    {link.icon}
                                    <span> {link.label} </span>
                                  </div>
                                </Link>
                              </a>
                            ) : (
                              <></>
                            )}
                          </>
                        ))}
                        <div className="dropdown-divider" />
                        <a
                          className="dropdown-item"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setISLogoutPromptVisible(true);
                          }}
                        >
                          Log out
                        </a>
                      </div>
                    </li>
                  ) : null}
                </ul>
              </div>
            </div>
          </nav>
          {/* End Navbar */}
          <div className="content">{props.children}</div>
          <footer className="footer">
            <div className="container-fluid">
              <nav className="float-left">
                <ul>
                  <li>
                    <a href="#">Keck</a>
                  </li>
                </ul>
              </nav>
              <div className="copyright float-right"></div>
            </div>
          </footer>
        </div>
      </div>
      <Prompt
        isVisible={isLogoutPromptVisible}
        onCancel={() => setISLogoutPromptVisible(false)}
        onConfirm={_logout}
        bodyText={"Are you sure to logout?"}
        confirmText={"Logout"}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(Header);
