const english = {
  firstname: "First Name",
  lastname: "Last Name",
  dob: "Date of Birth",
  dobformat: "Date Format: mm/dd/yyyy",
  phonenumber: "Phone Number",

  phoneformat: "Phone Format: 123-456-7890",
  hasappointment: "Have An Appointment?",
  requiredfields: "* Required Fields",
  requiredwarning: "You must enter all required fields before continuing",
  dobwarning:
    "Date of Birth is invalid. Please enter a date of birth using in the format of: mm/dd/yyyy. Please note: The year value must be between 1919 and this year",
  phonenowarning:
    "Phone Number entered is invalid. Please enter a valid phone number",
  helpbtntext: "Help",
  homebutton: "Home",
  scanid: "Scan My ID",
  next: "Next",
  providerinfotitle: "Who are you here to see?",
  providerinfotxt: "Please select the provider you are here to see.",
  successtitle: "Succesfully Signed In!",
  successtxt:
    "You have successfully signed into the Kiosk System. Please have a seat as someone from our staff will be contacting you shortly!",
  helptitle: "Kiosk Help",
  helptxt1:
    "If you need help you will need to go to the window and cancel your electronic checkin.",
  helptxt2:
    "If you wish to continue with your electronic checkin, click the Close button.",
  helpclose: "Close",
  visitReason: "Reason for visit",
  previous: "Previous",
  callnumberlabel: "Four-digit Number (Unique ID to notify you of services) ",
  callnowarning:
    "Call Number entered is invalid. Please enter a 4 digit call number",
  startover: "Start Over",
  donthavephonenumber: "I DON'T HAVE A PHONE NUMBER",
  callnumberdisplaylabel: "Your call number is ",
  waittext: "Please be seated and someone will call you" 
};

export default english;
