import React, { Component } from "react";
import "./clinic-system-settings-page.scss";
import UpdateSettingsPrompt from "modules/clinic-system-settings-module/components/update-settings-prompt/update-settings-prompt";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { connect } from "react-redux";
import { deepClone } from "helper-methods";
import { getAllClinicsSystemSettingsOptions } from "http-calls";
import { getClinicSystemSettingsOptions } from "http-calls";
import { showToast } from "helper-methods";
import { setClinicSystemSettingOptions } from "http-calls";
import { getAvailableFlowStates } from "http-calls";
import STAGES from "config/stages.json"
import { loadClinicApps } from "redux/actions/clinic-settings";

const initialState = {
  allSettings: [],
  currentSettings: [],
  clinicId: null,
  systemSettings: [],
  settingsUpdatePrompt: {
    isVisible: false,
    selectedSettingsTypeId: null,
  },
  allStates: [],
};

class ClinicSystemSettingsPage extends Component {
  state = deepClone(initialState);

  async componentDidMount() {
    this.props.showLoader("Loading");
    await this._setInitialValues();
    try {
      await this.props.loadClinicApps();
      await this._fetchAllSettingsOptions();
      await this._fetchClinicSettings();
      await this._prepareSettings();
      this.props.hideLoader();
    } catch (error) {
      showToast("Server error", "error");
      this.props.hideLoader();
    }
  }

  _setInitialValues = () => {
    return new Promise((resolve, reject) => {
      const {
        selectedClinic: { ClinicID },
      } = this.props.userData;
      this.setState({ clinicId: ClinicID }, () => {
        resolve();
      });
    });
  };

  _fetchAllSettingsOptions = () => {
    return new Promise(async (resolve, reject) => {
      const allSettings = await getAllClinicsSystemSettingsOptions();
      this.setState({ allSettings }, () => {
        resolve();
      });
    });
  };

  _fetchClinicSettings = () => {
    return new Promise(async (resolve, reject) => {
      const { clinicId } = this.state;
      try {
        const currentSettings = await getClinicSystemSettingsOptions(clinicId);
        this.setState({ currentSettings }, () => {
          resolve();
        });
      } catch (error) {
        resolve();
      }
    });
  };

  _prepareSettings = () => {
    return new Promise((resolve, reject) => {
      const { currentSettings, allSettings } = this.state;
      let systemSettings = allSettings.map((setting) => {
        let systemSetting = {
          ClinicSystemSettingTypeID: setting.ClinicSystemSettingTypeID,
          ClinicSystemSettingType: setting.ClinicSystemSettingType,
          isEnabled: false,
          fields: {
            field1: {
              label: setting.SettingValue1,
              value: "",
            },
            field2: {
              label: setting.SettingValue2,
              value: "",
            },
          },
        };
        // Check if system setting is available for current clinic
        const currentSetting = currentSettings.find(
          (s) => s.ClinicSystemSettingType === setting.ClinicSystemSettingType
        );
        if (currentSetting) {
          systemSetting.fields.field1.value = currentSetting.SettingValue1;
          systemSetting.fields.field2.value = currentSetting.SettingValue2;
          systemSetting.isEnabled = true;
        }
        return systemSetting;
      });
      this.setState({ systemSettings }, () => {
        resolve();
      });
    });
  };

  _toggleSettings = (setting) => {
    console.log("setting :>> ", setting);
    const { systemSettings, currentSettings } = this.state;
    const systemSettingIndex = systemSettings.findIndex(
      (systemSetting) =>
        systemSetting.ClinicSystemSettingTypeID ===
        setting.ClinicSystemSettingTypeID
    );
    if (!systemSettings[systemSettingIndex].isEnabled) {
      this._showUpdatePrompt(setting);
    } else {
      const preparedSettings = {
        clinicID: this.props.userData.selectedClinic.ClinicID,
        ClinicSystemSettings: [],
      };
      systemSettings.forEach((setting) => {
        if (setting.isEnabled) {
          preparedSettings.ClinicSystemSettings.push({
            ClinicSystemSettingTypeID: setting.ClinicSystemSettingTypeID,
            SettingValue1: setting.fields.field1.value,
            SettingValue2: setting.fields.field2.value,
          });
        }
      });
      const settingsIndex = preparedSettings.ClinicSystemSettings.findIndex(
        (s) => s.ClinicSystemSettingTypeID === setting.ClinicSystemSettingTypeID
      );
      preparedSettings.ClinicSystemSettings.splice(settingsIndex, 1);
      this._onSettingsUpdate(preparedSettings);
    }
  };

  _showUpdatePrompt = (setting) => {
    console.log("setting :>> ", setting);
    const { settingsUpdatePrompt } = this.state;
    settingsUpdatePrompt.isVisible = true;
    settingsUpdatePrompt.selectedSettingsTypeId =
      setting.ClinicSystemSettingTypeID;
    this.setState({ settingsUpdatePrompt });
  };

  _hideUpdatePrompt = () => {
    const { settingsUpdatePrompt } = this.state;
    settingsUpdatePrompt.isVisible = false;
    settingsUpdatePrompt.selectedSettingsTypeId = null;
    this.setState({ settingsUpdatePrompt });
  };

  _onSettingsUpdate = async (updatedSettings) => {
    try {
      this.props.showLoader("Updating");
      await setClinicSystemSettingOptions(updatedSettings);
      await this._fetchClinicSettings();
      await this._prepareSettings();
      this._hideUpdatePrompt();
      this.props.hideLoader();
    } catch (error) {
      showToast("Unable to update settings", "error");
      this.props.hideLoader();
    }
  };

  render() {
    const { systemSettings, settingsUpdatePrompt, allStates } = this.state;

    return (
      <>
        <div className="systemSettingsWrapper">
          <div className="systemSettingsWrapperCard">
            <div className="settingOptions">
              {systemSettings.map((systemSetting, systemSettingIndex) => (
                <div className="option" key={systemSettingIndex}>
                  <div className="label">
                    {systemSetting.ClinicSystemSettingType}
                  </div>
                  <div className="actions">
                    <input
                      id={"c1" + systemSettingIndex}
                      type="checkbox"
                      onClick={(e) => this._toggleSettings(systemSetting)}
                      checked={systemSetting.isEnabled}
                    />
                    <button
                      disabled={!systemSetting.isEnabled}
                      onClick={(e) => this._showUpdatePrompt(systemSetting)}
                    >
                      Update
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <UpdateSettingsPrompt
          isVisible={settingsUpdatePrompt.isVisible}
          onDiscard={this._hideUpdatePrompt}
          onUpdate={this._onSettingsUpdate}
          selectedSettingId={settingsUpdatePrompt.selectedSettingsTypeId}
          allSettings={systemSettings}
          clinicId={this.props.userData.selectedClinic.ClinicID}
          allStates={STAGES}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    loadClinicApps: () => dispatch(loadClinicApps()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClinicSystemSettingsPage);
