import React, { Component } from "react";
import { deepClone } from "helper-methods";
import Modal from "react-awesome-modal";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import NormalSelect from 'modules/general/components/normal-select/normal-select';

const initialState = {
  formFields: {
    deviceName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    deviceLocation: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    deviceTypeId: {
      value: "1",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  selectedUser: null,
  generalError: "",
};

class DeviceAddEditDialog extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    if (this.props.editMode) {
      this._initializeFields();
    } else {
      this._resetFields();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // Opened
      if (this.props.editMode) {
        this._initializeFields();
      } else {
        this._resetFields();
      }
    } else if (prevProps.isVisible && !this.props.isVisible) {
      this._resetFields();
    }
  }

  _initializeFields = () => {
    if (this.props.selectedDevice && Object.keys(this.props.selectedDevice)) {
      const { formFields } = this.state;
      formFields.deviceName.value = this.props.selectedDevice.Device;
      formFields.deviceTypeId.value = this.props.selectedDevice.DeviceTypeID;
      formFields.deviceLocation.value = this.props.selectedDevice.ClinicDeviceLocation;
      this.setState({ formFields });
    }
  };

  _resetFields = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "deviceName": {
            if (formFields.deviceName.value.length >= 1) {
              formFields.deviceName.isValid = true;
            } else {
              formFields.deviceName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "deviceLocation": {
            if (formFields.deviceLocation.value.length >= 1) {
              formFields.deviceLocation.isValid = true;
            } else {
              formFields.deviceLocation.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _discard = () => {
    this.props.onDiscard();
  };

  _submit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { formFields, isFormValid } = this.state;
    const { clinicId, selectedDevice } = this.props;

    if (isFormValid) {
      try {
        if (this.props.editMode) {
          const updatedDevice = {
            ClinicID: clinicId,
            Device: formFields.deviceName.value,
            ClinicDeviceLocation: formFields.deviceLocation.value,
            DeviceID: selectedDevice.DeviceID,
            DeviceTypeID: formFields.deviceTypeId.value
          };
          this.props.onDeviceUpdate(updatedDevice);
        } else {
          const newDevice = {
            ClinicID: clinicId,
            Device: formFields.deviceName.value,
            ClinicDeviceLocation: formFields.deviceLocation.value,
            DeviceID: "0",
            DeviceTypeID: formFields.deviceTypeId.value
          };
          this.props.onDeviceAdd(newDevice);
        }
      } catch (error) {
        console.log("error :>> ", error);
      }
    }
  };

  render() {
    const { formFields, generalError } = this.state;
    return (
      <>
        <Modal
          visible={this.props.isVisible}
          // visible={true}
          width="550"
          height="500"
          effect="fadeInUp"
          onClickAway={this._discard}
        >
          <div id="modalFormWrapper">
            <div className="crossWrapper" onClick={this._discard}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
            <h3>{this.props.editMode ? "Update device" : "Add new device"}</h3>
            <div className="promptFormWrapper">
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <CustomInput
                    labelText="Device Name"
                    id="float"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: formFields.deviceName.value,
                      onChange: (e) =>
                        this._updateFieldValue("deviceName", e.target.value),
                      onBlur: () => this._markAsDirty("deviceName"),
                    }}
                  />
                  <p className="formErrorText">
                    {formFields.deviceName.isDirty &&
                    !formFields.deviceName.isValid
                      ? "Cannot be empty"
                      : ""}
                  </p>
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <CustomInput
                    labelText="Device Location"
                    id="float"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: formFields.deviceLocation.value,
                      onChange: (e) =>
                        this._updateFieldValue(
                          "deviceLocation",
                          e.target.value
                        ),
                      onBlur: () => this._markAsDirty("deviceLocation"),
                    }}
                  />
                  <p className="formErrorText">
                    {formFields.deviceLocation.isDirty &&
                    !formFields.deviceLocation.isValid
                      ? "Cannot be empty"
                      : ""}
                  </p>
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                <NormalSelect
                  options={this.props.deviceOptions}
                  label={"Select Device Type"}
                  value={formFields.deviceTypeId.value}
                  onSelect={(e) =>
                    this._updateFieldValue("deviceTypeId", e.target.value)
                  }
                />
                </div>
              </div>
              <div className="formActionWrapper">
                <Button
                  color="success"
                  className="saveButton"
                  onClick={this._submit}
                >
                  {this.props.editMode ? "Update" : "Create"}
                </Button>
                <Button
                  color="danger"
                  className="discardButton"
                  onClick={this._discard}
                >
                  Discard
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default DeviceAddEditDialog;
