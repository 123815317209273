/* eslint-disable no-use-before-define */
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState, useCallback } from "react";
import { debounce } from "lodash";
import { searchAzureUsers } from "http-calls";

let globalInputValue = "";

export default function AzureUserSearch(props) {
  const [inputValue, setInputValue] = useState("");
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    graphToken = null,
    labelProperty = "label",
    options = [],
    inputLabel = "Select",
    onSelect = (e) => {},
    width = 320,
  } = props;

  const _onKeyPress = (value) => {
    setInputValue(value.trim());
  };

  const _fetchUsers = async (searchQuery) => {
    try {
      const usersResponse = await searchAzureUsers(searchQuery, graphToken);
      if (!globalInputValue.length) {
        setUsers([]);
      } else {
        setUsers(usersResponse.value || []);
      }
      setIsLoading(false);
    } catch (error) {
      setUsers([]);
      setIsLoading(false);
    }
  };

  const _searchUsers = useCallback(
    debounce(
      (query) => {
        // Make azure calls
        _fetchUsers(query);
      },
      500,
      {
        leading: false,
        trailing: true,
      }
    ),
    []
  );

  useEffect(() => {
    globalInputValue = inputValue;
    if (inputValue && inputValue.length) {
      setIsLoading(true);
      _searchUsers(inputValue);
    } else {
      setUsers([]);
    }
  }, [inputValue]);

  return (
    <Autocomplete
      id="combo-box-demo"
      name={+new Date()}
      options={users}
      fullWidth
      getOptionLabel={(option) =>
        `${option.displayName} (${option.userPrincipalName}) `
      }
      style={{ width, zIndex: { modal: 66666666 } }}
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} variant="outlined" />
      )}
      disablePortal
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      loading={isLoading}
      noOptionsText={inputValue.length ? "No options" : "Type name to search"}
      loadingText={"Searching..."}
      onInputChange={(e, v, r) => {
        _onKeyPress(v);
      }}
    />
  );
}
