const russian = {
  firstname: "Имя",
  lastname: "Фамилия",
  dob: "Дата рождения",
  dobformat: "Формат даты: mm/dd/yyyy",
  phonenumber: "Номер телефона",
  phoneformat: "Формат телефона: 123-456-7890",
  hasappointment: "Есть назначение?",
  requiredfields: "* обязательные поля",
  requiredwarning:
    "Вы должны ввести все обязательные поля, прежде чем продолжить",
  dobwarning:
    "Дата рождения недействительна. Пожалуйста, введите дату рождения в формате: mm/dd/yyyy. Обратите внимание: значение года должно быть между 1919 и этим годом.",
  phonenowarning:
    "Введенный номер телефона недействителен. Пожалуйста, введите действующий телефонный номер",
  helpbtntext: "Помогите",
  homebutton: "Главная",
  scanid: "Сканировать мой идентификатор",
  next: "следующий",
  providerinfotitle: "Кто ты здесь, чтобы увидеть?",
  providerinfotxt:
    "Пожалуйста, выберите поставщика, которого вы здесь, чтобы увидеть.",
  successtitle: "Успешно Войти!",
  successtxt:
    "Вы успешно вошли в систему киоска. Пожалуйста, присаживайтесь, так как кто-то из наших сотрудников скоро с вами свяжется!",
  helptitle: "Киоск Помощь",
  helptxt1:
    "Если вам нужна помощь, вам нужно будет подойти к окну и отменить электронную регистрацию.",
  helptxt2:
    "Если вы хотите продолжить электронную регистрацию, нажмите кнопку Закрыть.",
  helpclose: "близко",
  visitReason: "Причина посещения",
  previous: "Предыдущий",
  callnumberlabel:
    "Четырехзначный номер (уникальный идентификатор для уведомления об услугах) ",
  callnowarning:
    "Введенный номер вызова недействителен. Пожалуйста, введите четырехзначный телефонный номер",
  startover: "Начать сначала",
  donthavephonenumber: "У МЕНЯ НЕТ ТЕЛЕФОНА",
  callnumberdisplaylabel: "Ваш телефонный номер ",
  waittext: "Пожалуйста, присаживайтесь и кто-нибудь вам позвонит",
};

export default russian;
