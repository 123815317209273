import React, { Component } from "react";
import "./dashboard-stat.scss";

class DashboardStat extends Component {
  state = {};

  _getValueClass = () => {
    const { subHeaderLabel = '' } = this.props;
    let valueClass = 'statsValue';
    if (!(subHeaderLabel && subHeaderLabel.length)) {
      valueClass += "  largeText"
    }
    return valueClass;
  }

  _getHeaderClass = () => {
    const { type = "primary" } = this.props;
    let headerClass = 'statsHeader';
    if (type === "secondary") {
      headerClass += "  secondary"
    }
    return headerClass;
  }

  render() {
    const { headerLabel, value, subHeaderLabel = '' } = this.props;

    return (
      <div className="stats">
        <div className={this._getHeaderClass()}>{headerLabel}</div>
        <div className={this._getValueClass()}>{value}</div>
        {
          subHeaderLabel && subHeaderLabel.length? (
            <div className="statsSubLabel">{subHeaderLabel}</div>
          ): <></>
        }
      </div>
    );
  }
}

export default DashboardStat;
