import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import MultiLang from "../../../../multi-lang";
import "./language-prompt.scss";

const lanuageOptions = [
  { label: "English", languageKey: "english" },
  { label: "Español", languageKey: "spanish" },
  { label: "中文", languageKey: "chinese" },
  { label: "Հայերեն", languageKey: "armenian" },
  { label: "Русский", languageKey: "russian" },
  { label: "العربية", languageKey: "arabic" },
  { label: "Tiếng Việt", languageKey: "vietnamese" },
  { label: "한국어", languageKey: "korean" },
  { label: "Tagalog", languageKey: "tagalog" },
];

const LanguagePrompt = (props) => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const _selectLanguage = (language) => {
    setSelectedLanguage(language);
    // Update language in redux
    MultiLang.setCurrentLang(language);
    // props.updateSelectedLanguage(selectedLanguage);
    props.onHide();
  };

  useEffect(() => {
    setSelectedLanguage(MultiLang.currentLangCode);
  }, [props.isVisible]);

  return (
    <>
      <Modal
        visible={props.isVisible}
        width="900"
        height="560"
        effect="fadeInUp"
        onClickAway={props.onHide}
      >
        <div id="languagePrompt">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h2 className="header">Update language</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            {lanuageOptions.map((language, languageIndex) => (
              <GridItem xs={12} sm={4} md={4} lg={4} key={languageIndex}>
                <Button
                  size="lg"
                  className={
                    selectedLanguage === language.languageKey
                      ? "languageSelector selected"
                      : "languageSelector"
                  }
                  onClick={(e) => _selectLanguage(language.languageKey)}
                >
                  {language.label}
                </Button>
              </GridItem>
            ))}
          </GridContainer>
          <div className="actionWrapper">
            <button className="dismiss" onClick={props.onHide}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LanguagePrompt;
