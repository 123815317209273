import AccessGuard from "guards/access-guard";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../config/authProvider";
import Header from "modules/general/containers/header/header";
import { Component, default as React } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import CalledPatientsTableCustomizer from "./components/called-patients-table-organizer";
import SigninPatientsTableCustomizer from "./components/signed-in-patients-table-organizer";
import "./tv-table-customizer.scss";

class TvTableCustomizer extends Component {
  state = {
    activeTabIndex: 1,
  };

  _getTabClass = (tabIndex) => {
    const { activeTabIndex } = this.state;
    let classname = "tab ";
    if (tabIndex === activeTabIndex) {
      classname += "active";
    }
    return classname;
  };

  _getTabBodyClass = (tabIndex) => {
    const { activeTabIndex } = this.state;
    let classname = "tabBody ";
    if (tabIndex !== activeTabIndex) {
      classname += " hide";
    }
    return classname;
  };

  _setActiveTab = (index) => {
    this.setState({ activeTabIndex: index });
  };

  render() {
    return (
      <>
        <AzureAD provider={authProvider} forceLogin={true}>
          <AccessGuard
            accessLevel={[
              "SuperAdmin",
              "ClinicAdmin",
              "Registration",
              "OrderActivation",
            ]}
          >
            <Header>
              <div className="tvTableOrganizer">
                <div className="tabOuterWrapper">
                  <div className="tabOptions">
                    <div
                      className={this._getTabClass(1)}
                      onClick={() => this._setActiveTab(1)}
                    >
                      Signed In patients
                    </div>
                    <div
                      className={this._getTabClass(2)}
                      onClick={() => this._setActiveTab(2)}
                    >
                      Called Patients
                    </div>
                  </div>
                  <div className={this._getTabBodyClass(1)}>
                    {/* <CalledPatientsTableCustomizer /> */}
                  </div>
                  <div className={this._getTabBodyClass(2)}>
                    {/* <SigninPatientsTableCustomizer /> */}
                  </div>
                </div>
              </div>
            </Header>
          </AccessGuard>
        </AzureAD>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TvTableCustomizer);
