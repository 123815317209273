import React, { useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import "./patient-communication-prompt.scss";
import Loader from "react-loader-spinner";
import { getSMSLog } from "http-calls";
import moment from "moment";
import { sendMessage } from "http-calls";
// import { getVideoAuthToken } from "http-calls";
import { showBottomLoader } from "redux/actions/loader-data";
import { hideBottomLoader } from "redux/actions/loader-data";
import { connect } from "react-redux";
import { showToast } from "helper-methods";
import { getCannedMessages } from "http-calls";

let timerIntervalRef = null;

const PatientCommunicationPrompt = React.memo((props) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCallInitiating, setIsCallInitiating] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [helperText, setHelperText] = useState("");
  const [cannedMessages, setCannedMessages] = useState([]);
  
  const {
    isVisible = false,
    onDismiss = () => {},
    selectedPatient = null,
    userInfo,
  } = props;

  const _loadMessages = async (disableLoader = false) => {
    if (!disableLoader) {
      setIsLoading(true);
    }
    try {
      console.log('selectedPatient.MobileNumber :>> ', selectedPatient.MobileNumber);
      const response = await getSMSLog(selectedPatient.MobileNumber);
      let messages = [];
      if (response.length) {
        messages = JSON.parse(response);
      }
      if (Array.isArray(messages)) {
        setMessages(messages.reverse());
      } else {
        setMessages([]);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const _appendNewMessage = (message) => {
    setMessages([message, ...messages]);
  }

  const _sendMessage = async (message) => {
    if (message?.SystemSMS?.length) {
      setHelperText("Sending Message");
      setIsSending(true);
      const payload = {
        Message: message.SystemSMS,
        ToPhoneNumber: selectedPatient.MobileNumber,
        PatientVisitID: selectedPatient.PatientVisitID,
        Sender: userInfo.email,
      };
      try {
        await sendMessage(payload);
        setIsSending(false);
        setHelperText("Message sent!");
        _destroyCheckTimer();
        _appendNewMessage({
          MessageType: 'MessageSent',
          Message: message.SystemSMS,
          DateSent: +new Date()
        })
        _initiateBackgroundCheck();
        setMessageText("");
        setTimeout(() => {
          setHelperText("");
        }, 2000);
      } catch (error) {
        setIsSending(false);
        setHelperText("Message sending failed!");
        setTimeout(() => {
          setHelperText("");
        }, 2000);
      }
    } else {
      setHelperText("Please enter a text to send");
      setTimeout(() => {
        setHelperText("");
      }, 2000);
    }
  };

  // const _startCall = async () => {
  //   try {
  //     setIsCallInitiating(true);
  //     const authTokenResponse = JSON.parse(await getVideoAuthToken());
  //     const staffVideoCallParams = `?room=pvid${
  //       selectedPatient.PatientVisitID
  //     }&user=${userInfo.email.split("@")[0]}&authencode=${
  //       authTokenResponse.AuthenCode
  //     }`;
  //     const userVideoCallParams = `?room=pvid${selectedPatient.PatientVisitID}&user=${selectedPatient.FirstName}&authencode=${authTokenResponse.AuthenCode}`;
  //     // Open the link in new tab
  //     let win = window.open(
  //       "https://d3luoc1jmeqeuu.cloudfront.net/video-call" +
  //         staffVideoCallParams,
  //       "_blank"
  //     );
  //     win.focus();
  //     // Send messgae
  //     const payload = {
  //       Message: `Please join the call here: https://d3luoc1jmeqeuu.cloudfront.net/video-call${userVideoCallParams}`,
  //       ToPhoneNumber: selectedPatient.MobileNumber,
  //       PatientVisitID: selectedPatient.PatientVisitID,
  //       Sender: userInfo.email,
  //     };
  //     setIsCallInitiating(false);
  //     showToast("Call initiated");
  //     try {
  //       await sendMessage(payload);
  //     } catch (error) {
  //       setIsSending(false);
  //       setIsCallInitiating(false);
  //     }
  //   } catch (error) {
  //     setIsSending(false);
  //     setIsCallInitiating(false);
  //   }
  // };

  const _initiateBackgroundCheck = () => {
    if (timerIntervalRef) {
      _destroyCheckTimer();
    }
    timerIntervalRef = setInterval(() => {
      _loadMessages(true);
    }, 3000);
  };

  const _destroyCheckTimer = () => {
    clearInterval(timerIntervalRef);
    timerIntervalRef = null;
  };

  const _loadCannedMessages = async () => {
    try {
      const response = await getCannedMessages();
      console.log('response :>> ', response);
      setCannedMessages(response);
    } catch (error) {
      
    }
  }

  useEffect(() => {
    _loadCannedMessages();
  }, [])

  useEffect(() => {
    if (isVisible) {
      _loadMessages();
      _initiateBackgroundCheck();
    } else {
      _destroyCheckTimer();
    }
  }, [isVisible]);

  return (
    <>
      <Modal
        visible={isVisible}
        width="580"
        height="750"
        effect="fadeInUp"
        onClickAway={onDismiss}
      >
        <div id="extendedActionsWrapper">
          <div className="modalHeader">
            <button className="dismissBtn" onClick={onDismiss}>
              Dismiss
            </button>
          </div>
          <div className="actionBar">
            <div className="patientInfoWrapper">
              <p>
                {" "}
                Patient :{" "}
                {selectedPatient &&
                selectedPatient.FirstName &&
                selectedPatient.LastName
                  ? selectedPatient.FirstName + " " + selectedPatient.LastName
                  : ""}
              </p>
              <p className="callNumber">
                CallNumber:{" "}
                {selectedPatient && selectedPatient.CallNumber
                  ? selectedPatient.CallNumber
                  : ""}
              </p>
            </div>
            {/* <div className="additionalActions">
              {isCallInitiating ? (
                <>
                  <Loader type="Puff" color="#212121" height={40} width={40} />
                </>
              ) : (
                <button className="videoCall" onClick={_startCall}>
                  <i className="fa fa-video-camera" aria-hidden="true"></i>
                  &nbsp; Call
                </button>
              )}
            </div> */}
          </div>
          <div className="composeWrapper">
            <div className="presetMessagesWrapper">
              {
                cannedMessages?.map(message=> (
              <div className="predefinedMessage" onClick={() => _sendMessage(message)} key={message.SystemSMSID}>
                {message.SystemSMS}<i className="fa fa-paper-plane" aria-hidden="true"></i>
              </div>
                ))
              }
            </div>
            {/* <div className="inputWrapper">
              <textarea
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                rows="4"
                placeholder="Compose message..."
              />
            </div>
            <div className="sendWrapper">
              {
                messageText?.length? (
                  <button
                    onClick={_sendMessage}
                    className={isSending && "disable"}
                    disabled={isSending ? true : null}
                  >
                    <i className="fa fa-paper-plane" aria-hidden="true"></i>
                  </button>
                ) : <></> 
              }
            </div> */}
          </div>
          <div className="helperText">
            {helperText.length ? helperText : <>&nbsp;</>}
          </div>
          {isLoading ? (
            <>
              <div className="loaderWrapper">
                <Loader type="Puff" color="#212121" height={40} width={40} />
                <h5>Checking for messages</h5>
              </div>
            </>
          ) : (
            <>
              {/* {messages.length ? ( */}
              {messages.length ? (
                <>
                  <h3>Messages</h3>
                  <div className="messageLogsWrapper">
                    {messages.map((message, messageIndex) => (
                      <>
                        {message.MessageType === "MessageSent" ? (
                          <div
                            className="messageLog sentWrapper"
                            key={messageIndex}
                          >
                            <div className="sentContainer">
                              <div className="messageHeader">
                                <div className="typeLabel">Sent</div>
                                <div className="timeLabel">
                                  {moment(message.DateSent).format(
                                    "MM/DD/YYYY hh:mm a"
                                  )}
                                </div>
                              </div>
                              <div className="messageBody">
                                {message.Message}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div
                            className="messageLog recieveWrapper"
                            key={messageIndex}
                          >
                            <div className="recieveContainer">
                              <div className="messageHeader">
                                <div className="typeLabel">Recieved</div>
                                <div className="timeLabel">
                                  {moment(message.DateSent).format(
                                    "MM/DD/YYYY hh:mm a"
                                  )}
                                </div>
                              </div>
                              <div className="messageBody">
                                {message.Message}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <h3>No messages</h3>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
});

const mapDispatchToProps = (dispatch) => {
  return {
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientCommunicationPrompt);
