import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PatientStatusPage from "./pages/patient-status-page/patient-status-page";

class PatientStatusModule extends Component {
  state = {};
  render() {
    return (
      <div id="patient-status-module">
        <Switch>
          <Route path="/patient-status" component={PatientStatusPage} />
        </Switch>
      </div>
    );
  }
}

export default PatientStatusModule;
