import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import "./user-selection-view.scss";
import { getPhlebotomistUsers } from "http-calls";
import { hideCharactersPartiallyInAString } from "helper-methods";

const UserSelectionView = (props) => {
  const [users, setUsers] = useState([]);

  const _fetchUserList = async () => {
    try {
      props.showLoader('Loading');
      const users = await getPhlebotomistUsers(props.clinicId);
      setUsers(users);
      props.hideLoader();
    } catch (error) {
      console.log('error :>> ', error);
      props.hideLoader();
    }
  };

  useEffect(() => {
    _fetchUserList();
  }, []);

  const _selectUser = (user) => {
    props.selectUser(user);
  };

  return (
    <div id="languageSelectorPage">
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <h1 className="header">Who is performing action?</h1>
        </GridItem>
      </GridContainer>
      <GridContainer>
        {users.map((user, index) => (
          <GridItem xs={12} sm={12} md={4} lg={4} key={index}>
            <Button
              size="lg"
              className={"languageSelector"}
              onClick={(e) => _selectUser(user)}
            >
             {hideCharactersPartiallyInAString(user.FirstName) +
                        " " +
                        hideCharactersPartiallyInAString(user.LastName)}
            </Button>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(UserSelectionView);
