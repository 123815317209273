import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import "./mat-suggestion-input.scss";

export default function MatSuggestionInput(props) {
  const {
    labelFormatter = (option) => `${option.label}`,
    options = [],
    inputLabel = "Select",
    onSelect = (e) => {},
    width = "100%",
    value = "",
    maxSuggestionVisible = 100,
  } = props;
  let index = 0;

  return (
    <Autocomplete
      id="size-small-standard"
      name={+new Date()}
      options={options}
      fullWidth
      getOptionLabel={labelFormatter}
      getOptionSelected={e => console.log('e :>> ', e)}

      style={{ width, zIndex: { modal: 66666666 } }}
      renderInput={(params) => (
        <TextField {...params} label={inputLabel} variant="standard" />
      )}
      disablePortal
      onChange={(event, newValue) => {
        onSelect(newValue);
      }}
      loadingText={"Searching..."}
      value={value}
    />
  );
}
