import { getAvgWaitTime } from "http-calls";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Progress from "react-progressbar";
import { connect } from "react-redux";
import LanguageParsedText from "../../../../multi-lang/language-parser";
import { hideLoader, showLoader } from "../../../../redux/actions/loader-data";
import { updateWizardData } from "../../../../redux/actions/wizard-data";
import QRCode from "react-qr-code";
import "./registration-success.scss";

let timerInterval = null;
let seconds = 0;
const RegistrationSuccess = (props) => {
  const [waitSeconds, setWaitSeconds] = useState(0);
  const [waitTime, setWaitTime] = useState(null);

  const _transformToMinutesAndHours = (timeInSeconds) => {
    const d = Number(timeInSeconds);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute " : " minutes ") : "";
    return hDisplay + mDisplay;
  };

  const _fetchWaitTime = async () => {
    const { visitSettings } = props;
    const isEstimatedWaitTimeEnabled = visitSettings.find(
      (s) => s.PatientVisitOption === "Estimated Wait Times"
    );
    if (isEstimatedWaitTimeEnabled) {
      props.showLoader("Please wait");
      const date = moment().format("MM/DD/YYYY");
      try {
        const dateResponse = await getAvgWaitTime({
          StartDate: date, //MM/DD/YYYY
          EndDate: date, //MM/DD/YYYY
          StartStage: visitSettings.SettingValue1, //StageID
          EndState: visitSettings.SettingValue2, //stageID
          ClinicID: props.clinicId,
        });
        if (dateResponse && dateResponse.AvgTime) {
          const avgTimeInMinuteAndHours = _transformToMinutesAndHours(
            dateResponse.AvgTime
          );
          setWaitTime(avgTimeInMinuteAndHours);
        }
        props.hideLoader();
      } catch (error) {
        props.hideLoader();
      }
    }
  };

  useEffect(() => {
    _fetchWaitTime();
    timerInterval = setInterval(() => {
      seconds++;
      setWaitSeconds(seconds);
      if (seconds === parseInt(process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME)) {
        _reset();
      }
    }, 1000);
  }, []);

  const _reset = () => {
    clearInterval(timerInterval);
    setWaitSeconds(0);
    props.onRestart();
  };

  const _getPercentage = () => {
    const percentage =
      100 *
      (1 -
        waitSeconds / parseInt(process.env.REACT_APP_SUCCESS_VIEW_WAIT_TIME));
    // Always return positive value, the plugin doesn't support negative values
    if (percentage >= 0) {
      return percentage;
    } else {
      return 0;
    }
  };

  const { callNo = "123456", isPreviousRegistration = false } = props;
  const qrLink = `${window.location.origin}/patient-status?id=${props.patientVisitId}`;
  const shouldShowQr = props.visitSettings.find(s => s.PatientVisitOption === "QRCode Visit Status");
  return (
    <div id="registrationSuccess">
      {props.patientVisitId && shouldShowQr ? (
        <div className="qrCode">
          <QRCode value={qrLink} size={128} />
          <p>Scan the code to check your status</p>
        </div>
      ) : (
        <></>
      )}
      <h3>
        <LanguageParsedText value="callnumberdisplaylabel" />{" "}
        <span>{callNo}</span>.<br />
        <LanguageParsedText value="waittext" />
        {waitTime && (
          <div style={{ fontSize: "2rem", fontWeight: "600" }}>
            Estimated wait time: {waitTime}
          </div>
        )}
      </h3>
      <button className="resetBtn" onClick={_reset}>
        New Registration
      </button>
      <div className="progressWrapper">
        <Progress completed={_getPercentage()} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wizardData: state.wizardData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateWizardData: (wizardData) => dispatch(updateWizardData(wizardData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationSuccess);
