import NavPills from "components/NavPills/NavPills.js";
import { authProvider } from "config/authProvider";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import MyRegistrations from "modules/lab-assistant-module/components/my-registrations/my-registrations";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getSTaffUserInfo } from "../../../../http-calls/index";
import Header from "../../../general/containers/header/header";
import CheckinsView from "../../components/checkins-view/checkins-view";
import "./lab-assistant-view.scss";
import NoAnswerView from "modules/lab-assistant-module/components/no-answer-view/no-answer-view";
import PatientEditPrompt from "modules/lab-assistant-module/components/patient-edit-prompt/patient-edit-prompt";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";




const LabAssistantView = (props) => {
  const [activeView, setActiveView] = useState("myRegistraions");
  const [myRegistrationsCount, setMyRegistrationsCount] = useState(0);
  const [toBeRegisteredCount, setToBeRegisteredCount] = useState(0);
  const [noAnswerCount, setNoAnswerCount] = useState(0);
  const [clinicId, setClinicId] = useState("-1");
  const [idMappedCustomStates, setIdMappedCustomStates] = useState({});
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [isSmsCommunicationEnabled, setIsSmsCommunicationEnabled] = useState(
    false
  );

  const _updateActiveView = (viewIndex) => {
    if (viewIndex === 0) {
      setActiveView("myRegistraions");
    } else if (viewIndex === 1) {
      setActiveView("allCheckins");
    } else  if (viewIndex === 2) {
      setActiveView("noAnswer");
    }
  };

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  useEffect(() => {
    if (clinicId > -1) {
      _fetchClinicSettings();
    }
  }, [clinicId, props.clinicSettings]);

  const _fetchClinicSettings = async () => {
    const { clinicSettings } = props;
    let isSmsCommunicationEnabled = false;
    if (clinicSettings?.clinicApps?.length) {
      const communicationApp = clinicSettings.clinicApps.find(
        (app) => app.AppAddOn === "SMS Communication"
      );
      if (communicationApp) {
        isSmsCommunicationEnabled = true;
      }
    }
    setIsSmsCommunicationEnabled(isSmsCommunicationEnabled);
  };

  const _getActiveIndex = () => {
    if (activeView === "myRegistraions") {
      return 0;
    } else if (activeView === "allCheckins") {
      return 1;
    } else if (activeView === "noAnswer") {
      return 2;
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    const userInfo = authProvider.getAccountInfo();
    // console.log('userInfo :>> ', userInfo);
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _generateTabLabel = (tabButtonLabel, patientCount) => {
    return (
      <>
        <div className="tabButtonWrapper">
        {tabButtonLabel}
        {
          patientCount && parseInt(patientCount) > 0? (
            <div className="customBadge">{patientCount}</div>
          ): <></>
        }
        </div>
      </>
    );
  };

  const _fetchAndStoreCustomStates = async () => {
    try {
      await ClinicStateHelper.fetchClinicStates();
      const { clinicCustomStates } = ClinicStateHelper;
      const idMappedCustomStates = {};
      clinicCustomStates.forEach(state => {
        idMappedCustomStates[state.StatusID] = state.CustomStatusName;
      })
      setIdMappedCustomStates(idMappedCustomStates);
    } catch (error) {
      console.log('error', error);
      setIdMappedCustomStates({});
    }
  }

  const _formatTabOptions = (tabs) => {
    return tabs.map(tab => {
      if (idMappedCustomStates[tab.statusId]) {
        tab.label = idMappedCustomStates[tab.statusId];
      }
      return tab;
    })
  };

  useEffect(() => {
    _checkIfUserHasAccess();
    _setClinicIdFromRedux();
    _fetchAndStoreCustomStates();
    // const clinicId = 1;
    // setClinicId(clinicId);
  }, []);

  const tabs = [{
    activeViewId: "myRegistraions",
    statusId: 2,
    label: "My Registrations",
    patientCount: myRegistrationsCount,
  },{
    activeViewId: "allCheckins",
    statusId: 1,
    label: "To be registered",
    patientCount: toBeRegisteredCount,
  },{
    activeViewId: "noAnswer",
    statusId: 8,
    label: "No Answer",
    patientCount: noAnswerCount,
  }];
  const formattedTabs = _formatTabOptions(tabs);

  return (
    <>
      <LogoutNotifier isVisible={isLogoutNotifierVisible} />
      <Header>
        {clinicId > -1 ? (
          <>
            <NavPills
              active={_getActiveIndex()}
              color="info"
              onChange={_updateActiveView}
              tabs={formattedTabs.map(tab => ({
                tabButton: (_generateTabLabel(tab.label, tab.patientCount)),
                tabContent: <></>,
              }))
                }
            />
            <MyRegistrations
              setActiveView={setActiveView}
              activeView={activeView}
              clinicId={clinicId}
              setPatientCount={setMyRegistrationsCount}
              isSmsCommunicationEnabled={isSmsCommunicationEnabled}
            />
            <CheckinsView
              activeView={activeView}
              clinicId={clinicId}
              setActiveView={setActiveView}
              logout={_logout}
              setPatientCount={setToBeRegisteredCount}
            />
            <NoAnswerView
              activeView={activeView}
              clinicId={clinicId}
              setActiveView={setActiveView}
              logout={_logout}
              setPatientCount={setNoAnswerCount}
            />
          </>
        ) : (
          <div className="clinicIdError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}
        <PatientEditPrompt />
      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    clinicSettings: state.clinicSettings,
  };
};

export default connect(mapStateToProps, null)(LabAssistantView);
