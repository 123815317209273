import NavPills from "components/NavPills/NavPills.js";
import { authProvider } from "config/authProvider";
import LogoutNotifier from "modules/general/components/logout-notifier/logout-notifier";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getSTaffUserInfo,
  getVisitSystemSettingsOptions,
} from "../../../../http-calls/index";
import Header from "../../../general/containers/header/header";
import NoAnswerView from "modules/lab-assistant-module/components/no-answer-view/no-answer-view";
import OrderActivationTable from "modules/user-activation/containers/order-activation-table/order-activation-table";
import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";

const OrderActivationView = (props) => {
  const [activeView, setActiveView] = useState("orderActivationView");
  const [isSmsCommunicationEnabled, setIsSmsCommunicationEnabled] = useState(
    false
  );
  const [clinicId, setClinicId] = useState("-1");
  const [isLogoutNotifierVisible, setIsLogoutNotifierVisible] = useState(false);
  const [orderActivationCount, setOrderActivationCount] = useState(0);
  const [noAnswerCount, setNoAnswerCount] = useState(0);
  const [idMappedCustomStates, setIdMappedCustomStates] = useState({});

  const _updateActiveView = (viewIndex) => {
    if (viewIndex === 0) {
      setActiveView("orderActivationView");
    } else if (viewIndex === 1) {
      setActiveView("noAnswer");
    }
  };

  const _setClinicIdFromRedux = () => {
    setClinicId(props.userData.selectedClinic.ClinicID);
  };

  const _getActiveIndex = () => {
    if (activeView === "orderActivationView") {
      return 0;
    } else if (activeView === "noAnswer") {
      return 1;
    }
  };

  const _logout = () => {
    setIsLogoutNotifierVisible(true);
  };

  const _checkIfUserHasAccess = async () => {
    const userInfo = authProvider.getAccountInfo();
    // console.log('userInfo :>> ', userInfo);
    try {
      if (userInfo && userInfo.account && userInfo.account.userName) {
        const res = await getSTaffUserInfo(userInfo.account.userName);
      } else {
        throw "error";
      }
    } catch (error) {
      console.log("error :>> ", error);
      _logout();
    }
  };

  const _generateTabLabel = (tabButtonLabel, patientCount) => {
    return (
      <>
        <div className="tabButtonWrapper">
        {tabButtonLabel}
        {
          patientCount && parseInt(patientCount) > 0? (
            <div className="customBadge">{patientCount}</div>
          ): <></>
        }
        </div>
      </>
    );
  };

  const _fetchAndStoreCustomStates = async () => {
    try {
      await ClinicStateHelper.fetchClinicStates();
      const { clinicCustomStates } = ClinicStateHelper;
      const idMappedCustomStates = {};
      clinicCustomStates.forEach(state => {
        idMappedCustomStates[state.StatusID] = state.CustomStatusName;
      })
      setIdMappedCustomStates(idMappedCustomStates);
    } catch (error) {
      console.log('error', error);
      setIdMappedCustomStates({});
    }
  }

  const _formatTabOptions = (tabs) => {
    return tabs.map(tab => {
      if (idMappedCustomStates[tab.statusId]) {
        tab.label = idMappedCustomStates[tab.statusId];
      }
      return tab;
    })
  };

  const tabs = [{
    activeViewId: "orderActivationView",
    statusId: 3,
    label: "Order Activations",
    patientCount: orderActivationCount,
  },{
    activeViewId: "noAnswer",
    statusId: 8,
    label: "No Answer",
    patientCount: noAnswerCount,
  }];

  const formattedTabs = _formatTabOptions(tabs);

  useEffect(() => {
    _checkIfUserHasAccess();
    _setClinicIdFromRedux();
    _fetchAndStoreCustomStates();
    // const clinicId = 1;
    // setClinicId(clinicId);
  }, []);

  return (
    <>
      <LogoutNotifier isVisible={isLogoutNotifierVisible} />

      <Header>
        {clinicId > -1 ? (
          <>
            <NavPills
              active={_getActiveIndex()}
              color="info"
              onChange={_updateActiveView}
              tabs={formattedTabs.map(tab => ({
                tabButton: (_generateTabLabel(tab.label, tab.patientCount)),
                tabContent: <></>,
              }))}
            />
            <OrderActivationTable
              setActiveView={setActiveView}
              activeView={activeView}
              clinicId={clinicId}
              setPatientCount={setOrderActivationCount}
            />
            <NoAnswerView
              activeView={activeView}
              clinicId={clinicId}
              setPatientCount={setNoAnswerCount}
              setActiveView={setActiveView}
              logout={_logout}
            />
          </>
        ) : (
          <div className="clinicIdError">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
            <p>
              This seems to be a configuration error. <br /> Please notify the
              front desk.
            </p>
          </div>
        )}
      </Header>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(OrderActivationView);
