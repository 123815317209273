import { extractQueryParams } from "helper-methods";
import {
  getAllColumnsOfTvScreen,
  getSelectedColumnsOfATvScreen,
} from "http-calls";
import React, { Component } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./manage-columns.scss";
import { deepClone } from "helper-methods";
import { updateColumnsOfATvScreen } from "http-calls";
import BreadCrumbNavigator from "modules/tv-table-customizer/components/breadcrumb-navigator/breadcrumb-navigator";

const allOptions = [
  "First Name",
  "Last Name",
  "Reason for visit",
  "Status",
  "Wait time per stage",
  "Wait time of visit",
];

class ManageColumns extends Component {
  state = {
    tableOptions: {
      remainingOptions: [],
      selectedOptions: [],
    },
    fetchedOptions: {
      remainingOptions: [],
      selectedOptions: [],
    },
    allColumns: [],
    selectedColumns: [],
    screenId: 1,
    breadcrumbLinks: []
  };

  componentDidMount() {
    this._initialize();
    this._generateBreadCrumbLinks();
  }

  _generateBreadCrumbLinks = () => {
    const urlParms = extractQueryParams();
    const breadcrumbLinks = [{
      path: "/screen-builder/manage-devices",
      label: "All Devices",
      icon: <i className="fas fa-home"></i>
    }, {
      path: `/screen-builder/manage-screens?deviceId=${urlParms.deviceId}`,
      label: "Screens",
      icon: <i className="fa fa-desktop" aria-hidden="true"></i> 
    }, {
      path: `/screen-builder/manage-screens?deviceId=${urlParms.screenId}`,
      label: "Manage Columns",
      icon: <i className="fa fa-columns" aria-hidden="true"></i>,
      isActive: true
    }];
    this.setState({ breadcrumbLinks });
  }

  _extractRemainingOptions = (allColumns, selectedColumns) => {
    const selectedColumnsIds = selectedColumns.map(
      (selectedColumn) => selectedColumn.ScreenColumnTypeID
    );
    const remainingColumns = allColumns.filter((column) => {
      if (selectedColumnsIds.indexOf(column.ScreenColumnTypeID) === -1) {
        return true;
      }
    });
    return remainingColumns.map((remainingColumn) => ({
      columnName: remainingColumn.ScreenColumnDisplayName,
      ScreenColumnTypeID: remainingColumn.ScreenColumnTypeID,
    }));
  };

  _formatSelectedOptions = (allColumns, selectedOptions) => {
    const idMappedAllColumns = {};
    allColumns.forEach((column) => {
      idMappedAllColumns[column.ScreenColumnTypeID] = column;
    });
    const selectedColumns = selectedOptions.map((selectedOption) => {
      return idMappedAllColumns[selectedOption.ScreenColumnTypeID];
    });
    return selectedColumns.map((selectedColumn) => ({
      columnName: selectedColumn.ScreenColumnDisplayName,
      ScreenColumnTypeID: selectedColumn.ScreenColumnTypeID,
    }));
  };

  _fetchData = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let { screenId, tableOptions } = this.state;
        // Fetch data
        let allColumns = [];
        let selectedColumns = [];
        try {
          allColumns = await getAllColumnsOfTvScreen();
        } catch (error) {}
        try {
          selectedColumns = await getSelectedColumnsOfATvScreen(screenId);
        } catch (error) {}
        // Format and store
        tableOptions.remainingOptions = this._extractRemainingOptions(
          allColumns,
          selectedColumns
        );
        tableOptions.selectedOptions = this._formatSelectedOptions(
          allColumns,
          selectedColumns
        );
        this.setState(
          { tableOptions, fetchedOptions: deepClone(tableOptions) },
          () => {
            resolve();
          }
        );
      } catch (error) {
        resolve();
      }
    });
  };

  _reset = () => {
    const { fetchedOptions } = this.state;
    this.setState({ tableOptions: deepClone(fetchedOptions) });
  };

  _initialize = async () => {
    // Try to extract screen id
    await this._setScreenId();
    // Get all availabe columns
    this.props.showLoader("Loading columns");
    await this._fetchData();
    this.props.hideLoader();
  };

  _setScreenId = async () => {
    return new Promise((resolve, reject) => {
      const urlParms = extractQueryParams();
      if (urlParms.screenId && urlParms.screenId.length) {
        this.setState({ screenId: urlParms.screenId }, () => {
          resolve();
        });
      } else {
        this.props.history.push("/screen-builder");
      }
    });
  };

  _getTabClass = (tabIndex) => {
    const { activeTabIndex } = this.state;
    let classname = "tab ";
    if (tabIndex === activeTabIndex) {
      classname += "active";
    }
    return classname;
  };

  _setActiveTab = (index) => {
    this.setState({ activeTabIndex: index });
  };

  _onDragEnd = (result) => {
    const { tableOptions } = this.state;
    if (
      result.source &&
      result.source.droppableId &&
      result.destination &&
      result.destination.droppableId
    ) {
      if (result.source.droppableId === result.destination.droppableId) {
        // Re order in same array
        const [reorderedItem] = tableOptions[result.source.droppableId].splice(
          result.source.index,
          1
        );
        tableOptions[result.source.droppableId].splice(
          result.destination.index,
          0,
          reorderedItem
        );
      } else {
        // Transfer
        // Cut it from source array
        const [reorderedItem] = tableOptions[result.source.droppableId].splice(
          result.source.index,
          1
        );
        // Paste it to destination array
        tableOptions[result.destination.droppableId].splice(
          result.destination.index,
          0,
          reorderedItem
        );
      }
      this.setState({ tableOptions });
    }
  };

  _hasChangesMade = () => {
    const { tableOptions, fetchedOptions } = this.state;
    return (
      JSON.stringify(tableOptions.selectedOptions) !==
      JSON.stringify(fetchedOptions.selectedOptions)
    );
  };

  _save = async () => {
    const {
      tableOptions: { selectedOptions },
      screenId,
    } = this.state;

    const selectedColumns = selectedOptions.map((selectedOption, index) => ({
      ScreenColumnTypeID: selectedOption.ScreenColumnTypeID,
      ColumnOrder: index,
    }));
    this.props.showLoader("Updating columns");
    await updateColumnsOfATvScreen({
      DeviceScreenID: screenId,
      ScreenColumns: selectedColumns,
    });
    await this._fetchData();
    this.props.hideLoader();
  };

  render() {
    const { tableOptions, breadcrumbLinks } = this.state;

    return (
      <>
      <BreadCrumbNavigator
        links={breadcrumbLinks}
        {...this.props}
      />
        <div className="columnsCustomizer">
          <div className="tabBody">
            <DragDropContext onDragEnd={this._onDragEnd}>
              <div className="availableOptions">
                <p>Available Options</p>
                <Droppable
                  droppableId="remainingOptions"
                  direction="horizontal"
                >
                  {(provided) => (
                    <div
                      className="optionsWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {tableOptions.remainingOptions.map(
                        (remainingOption, remainingOptionIndex) => (
                          <Draggable
                            draggableId={remainingOption.ScreenColumnTypeID}
                            index={remainingOptionIndex}
                            key={remainingOptionIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="option"
                              >
                                {remainingOption.columnName}
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="selectedOptions">
                <p>Selected Options</p>
                <Droppable droppableId="selectedOptions" direction="horizontal">
                  {(provided) => (
                    <div
                      className="optionsWrapper"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {tableOptions.selectedOptions.map(
                        (selectedOption, selectedOptionIndex) => (
                          <Draggable
                            draggableId={selectedOption.ScreenColumnTypeID}
                            index={selectedOptionIndex}
                            key={selectedOptionIndex}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="option"
                              >
                                {selectedOption.columnName}
                              </div>
                            )}
                          </Draggable>
                        )
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            </DragDropContext>
            {this._hasChangesMade() ? (
              <div className="actionsWrapper">
                <button className="updateBtn" onClick={this._save}>
                  Save
                </button>
                <button className="resetBtn" onClick={this._reset}>
                  Reset
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageColumns);
