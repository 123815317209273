import { authProvider } from "config/authProvider";
import { sleepTime } from "helper-methods";
import { hideLoader, showLoader } from "redux/actions/loader-data";
import { updateUserData } from "redux/actions/user-data";
import { updateUtilsData } from "redux/actions/utils-data";
import { store } from "redux/store";

export const setSessionRefresher = (interval = 55) => {
  bindWindowActivityEvents();
  setInterval(async () => {
    console.log("Token refresh logic initiated");
    await refreshToken();
  }, interval * 60 * 1000);
};

export const refreshToken = async () => {
  clearMsalCacheSession();
  await sleepTime(500);
  const { utilsData } = store.getState();
  console.log("Trying to get token")
  if (utilsData && utilsData.isWindowActive) {
    // Window is active
    const authenticationParameters = {
      //scopes: ["openid", "AccessReview.Read.All"],
      scopes: ["user.read", "openid", "profile"],
    };
    try {
      const userInfo = await authProvider.acquireTokenSilent(
        authenticationParameters
      );
      console.log(
        `Setting new token at ${new Date()}: ${userInfo.idToken.rawIdToken}`
      );
      store.dispatch(updateUserData({ token: userInfo.idToken.rawIdToken }));
      store.dispatch(
        updateUtilsData({
          isTokenRefreshPending: false,
        })
      );
    } catch (error) {
      console.log("error :>> ", error);
      store.dispatch(
        updateUtilsData({
          isTokenRefreshPending: true,
        })
      );
    }
  } else {
    console.log("Token restore process skipped");
    // Window is not active
    // So add a pending refresh request to state
    store.dispatch(
      updateUtilsData({
        isTokenRefreshPending: true,
      })
    );
  }
};

export const clearMsalCacheSession = () => {
  const allLocalStorageItems = Object.entries(localStorage);
  const allKeys = allLocalStorageItems.map((item) => item[0]);
  const msalKeys = allKeys.filter((item) => item !== "root");
  msalKeys.forEach((key) => {
    localStorage.removeItem(key);
  });
};

export const bindWindowActivityEvents = () => {
  window.onfocus = async () => {
    store.dispatch(
      updateUtilsData({
        isWindowActive: true,
      })
    );
    // Also check if token refresh is pending or not
    const state = store.getState();
    if (state && state.utilsData && state.utilsData.isTokenRefreshPending) {
      store.dispatch(showLoader("Restoring session"));
      await refreshToken();
      store.dispatch(hideLoader());
    }
  };

  window.onblur = () => {
    store.dispatch(
      updateUtilsData({
        isWindowActive: false,
      })
    );
  };
};
