import React, { Component } from "react";
import "./bottom-loader.scss";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";

class BottomLoader extends Component {
  state = {};
  render() {
    const { loaderData } = this.props;
    if (loaderData && loaderData.isBottomLoaderVisible) {
      return (
        <div id="bottomLoaderWrapper">
          <div className="bottomLoader">
            <div className="spinnerWrapper">
              <div class="loader"></div>
            </div>
            <p>{loaderData.bottomLoaderText}</p>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loaderData: state.loaderData,
  };
};

export default connect(mapStateToProps, null)(BottomLoader);
