import ClinicStateHelper from "modules/flow-customizer/helper/clinic-state-helper";
import React, { Component, useState } from "react";
import { useEffect } from "react";
import Modal from "react-awesome-modal";
import "./extended-action.scss";

const ExtendedActions = (props) => {
  const [
    standardNameMappedCustomStates,
    setStandardNameMappedCustomStates,
  ] = useState({});
  const {
    isVisible = false,
    onCancel = () => {},
    actions = [],
    selectedPatient = null,
    modalHeight = "410",
  } = props;

  const _fetchAndStoreCustomStates = async () => {
    try {
      const mergedStates = await ClinicStateHelper.fetchClinicStates();

      const standardNameMappedCustomStates = {};
      mergedStates.forEach((state) => {
        standardNameMappedCustomStates[state.standardState] = state.Status;
      });
      setStandardNameMappedCustomStates(standardNameMappedCustomStates);
    } catch (error) {
      console.log("error", error);
      setStandardNameMappedCustomStates({});
    }
  };

  useEffect(() => {
    if (actions?.length) {
      _fetchAndStoreCustomStates();
    }
  }, [actions]);

  const _formatActions = () => {
    return actions?.map((action) => {
      if (standardNameMappedCustomStates[action.label]) {
        action.label = standardNameMappedCustomStates[action.label];
      }
      return action;
    });
  };

  const formattedActions = _formatActions();

  return (
    <Modal
      visible={isVisible}
      width="550"
      height={modalHeight}
      effect="fadeInUp"
      onClickAway={onCancel}
    >
      <div id="extendedActionsWrapper">
        <div className="modalHeader">
          <button className="dismissBtn" onClick={onCancel}>
            Dismiss
          </button>
        </div>
        <div className="modalTitleWrapper">
          <p>
            Patient:{" "}
            <span>
              {selectedPatient &&
              selectedPatient.FirstName &&
              selectedPatient.LastName
                ? selectedPatient.FirstName + " " + selectedPatient.LastName
                : ""}
            </span>
          </p>
          <p className="callNumber">
            CallNumber:{" "}
            {selectedPatient && selectedPatient.CallNumber
              ? selectedPatient.CallNumber
              : ""}
          </p>
          <p className="actionsLabel">Available Actions</p>
        </div>
        <div className="actionsWrapper">
          {formattedActions.map((action, actionIndex) => (
            <button
              onClick={action.onClick}
              className="modalActionBtn"
              key={actionIndex}
            >
              {action.label}
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default ExtendedActions;
