import Axios from "axios";
import querystring from "querystring";
import { makeGetRequest, makePostRequest } from "../http-connectors/index";

const { default: DexEncryptor } = window.DexEncryption;
const encryptor = new DexEncryptor();

export const checkIfPatientExists = (phoneNumber, clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getpatientvisit/${clinicId}/${phoneNumber}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("checkIfPatientExists error: ", e);
        reject(e);
      });
  });
};
export const submitPatientData = (patient) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/patientvisit?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false,
      patient
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("submitPatientData error: ", e);
        reject(e);
      });
  });
};

export const getAllWorkflows = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getworkflows/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllWorkflows error: ", e);
        reject(e);
      });
  });
};

export const setWorkflow = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/workflow/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setWorkflow error: ", e);
        reject(e);
      });
  });
};

export const getAllCheckins = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/1?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllCheckins error: ", e);
        reject(e);
      });
  });
};

export const getNoAnswerPatients = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/8/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getNoAnswerPatients error: ", e);
        reject(e);
      });
  });
};

export const getMyRegistrations = (userName = "testest", clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getregistrationpatients/${clinicId}/${userName}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const updatePatientStatus = (patient) => {
  console.log("patient2222 :>> ", {
    url: `${process.env.REACT_APP_BASE_URL}/UpdatePatientVisit?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
    method: "POST",
    bodyParams: patient,
  });
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/UpdatePatientVisit?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      patient
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("assignToMySelf error: ", e);
        reject(e);
      });
  });
};

export const getOrderActivations = (clinicId, statusId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/${statusId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const getPhlebotomistPatientsInQueue = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/4?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    );
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/4?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPhlebotomistPatientsInQueue error: ", e);
        reject(e);
      });
  });
};

export const getMyPhlebotomistPatients = (userId, clinicId) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/5/${userId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const getPhlebotomistUsers = (clinicID) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/GetStaffUsers/${clinicID}/5?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const getSignedPatients = (clinicId) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/getpatientsignedinlist/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const getCalledPatients = (clinicId) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/getpatientvisits/${clinicId}/5/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMyRegistrations error: ", e);
        reject(e);
      });
  });
};

export const getSTaffUserInfo = (username) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/staffinfo?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSTaffUserInfo error: ", e);
        reject(e);
      });
  });
};

export const getClinicDetails = (clinicID) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/clinic/${clinicID}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSTaffUserInfo error: ", e);
        reject(e);
      });
  });
};

export const updateClinic = (clinic) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/UpdateClinic?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      clinic
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateClinic error: ", e);
        reject(e);
      });
  });
};

export const addClinic = (clinic) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/AddClinic?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      clinic
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addClinic error: ", e);
        reject(e);
      });
  });
};

export const checkIfEmailExistsInAzure = (emailId, graphToken) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `https://graph.microsoft.com/v1.0/users/${emailId}`,
      graphToken
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicStaffList error: ", e);
        reject(e);
      });
  });
};

export const getClinicList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/clinic?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicList error: ", e);
        reject(e);
      });
  });
};

export const getClinicUsers = (clinicID) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/GetStaffUsers/${clinicID}/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicUsers error: ", e);
        reject(e);
      });
  });
};

export const getClinicRoles = (clinicID) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/staffroles/${clinicID}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicRoles error: ", e);
        reject(e);
      });
  });
};

export const updateUser = (user) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/UpdateStaff?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      user
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateUser error: ", e);
        reject(e);
      });
  });
};

export const addUser = (user) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/AddStaff?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      user
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("addClinic error: ", e);
        reject(e);
      });
  });
};

export const getGraphApiToken = () => {
  return new Promise((resolve, reject) => {
    const params = {
      grant_type: "client_credentials",
      client_id: process.env.REACT_APP_AZURE_CLIENT_ID,
      scope: "https://graph.microsoft.com/.default",
      client_secret: process.env.REACT_APP_AZURE_CLIENT_SECRET,
    };
    const formData = new URLSearchParams();
    Object.keys(params).forEach((key) => {
      formData.append(key, params[key]);
    });
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/graphtoken?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      params
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("e :>> ", e);
      });
  });
};

export const getAllAzureUsers = (graphToken) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`https://graph.microsoft.com/v1.0/users/`, graphToken)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllAzureUsers error: ", e);
        reject(e);
      });
  });
};

export const searchAzureUsers = (searchQuery, graphToken) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `https://graph.microsoft.com/v1.0/users?$filter=startswith(displayName,'${searchQuery}')`,
      graphToken
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllAzureUsers error: ", e);
        reject(e);
      });
  });
};

export const getAllDevicesOfAClinic = (clinicId, deviceId=0) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getclinicdevices/${clinicId}/${deviceId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllDevicesOfAClinic error: ", e);
        reject(e);
      });
  });
};

export const setClinicDevice = (device) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/clinicdevice/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      device
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setClinicDevice error: ", e);
        reject(e);
      });
  });
};

export const getAllScreensOfADevice = (deviceId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getdevicescreens/${deviceId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllScreensOfADevice error: ", e);
        reject(e);
      });
  });
};

export const setScreen = (screen) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/devicescreen/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      screen
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setScreen error: ", e);
        reject(e);
      });
  });
};

export const getAvgWaitTime = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/avgstagewatitimes/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAvgWaitTime error: ", e);
        reject(e);
      });
  });
};
export const getDailyVisits = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/dailyvisits/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDailyVisits error: ", e);
        reject(e);
      });
  });
};

export const signin = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/user/signin?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      {}
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signin error: ", e);
        reject(e);
      });
  });
};

export const getVisitReasons = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getclinicreasonforvisitsettings/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getVisitReasons error: ", e);
        reject(e);
      });
  });
};

export const updateVisitReasons = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/clinicreasonforvisitsetting?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateVisitReason error: ", e);
        reject(e);
      });
  });
};

export const searchVisits = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/searchvisits?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("searchVisits error: ", e);
        reject(e);
      });
  });
};

export const getPatientDetailsById = (pvid) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/patientvisitdetails/${pvid}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPatientDetailsById error: ", e);
        reject(e);
      });
  });
};

export const getCurrentClinicDetails = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/clinic/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getCurrentClinicDetails error: ", e);
        reject(e);
      });
  });
};

export const getDashboardPatientsStats = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/searchvisitsdetails?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDashboardPatientsStats error: ", e);
        reject(e);
      });
  });
};

export const getPatientStatus = (patientVisitId) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/patientvisitstatus/${patientVisitId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        console.log("res :>> ", res);
        resolve(res);
      })
      .catch((e) => {
        console.log("getPatientStatus error: ", e);
        reject(e);
      });
  });
};

export const getVisitSystemSettingsOptions = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getclinicvisitsettings/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getVisitSystemSettingsOptions error: ", e);
        reject(e);
      });
  });
};

export const getAllVisitSystemSettingsOptions = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/visitoptions?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllVisitSystemSettingsOptions error: ", e);
        reject(e);
      });
  });
};

export const setClinicVisitSettingOptions = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/clinicvisitsetting?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setClinicVisitSettingOptions error: ", e);
        reject(e);
      });
  });
};

export const getAllClinicsSystemSettingsOptions = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/systemsettingtypes?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllClinicsSystemSettingsOptions error: ", e);
        reject(e);
      });
  });
};

export const getClinicSystemSettingsOptions = (clinicId) => {
  return new Promise((resolve, reject) => {
    encryptor
      .makeRequest({
        url: `${process.env.REACT_APP_BASE_URL}/getclinicsystemsettings/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
        method: "GET",
      })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAvailableFlowStates error: ", e);
        reject(e);
      });
  });
};

export const setClinicSystemSettingOptions = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/clinicsystemsetting?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setClinicSystemSettingOptions error: ", e);
        reject(e);
      });
  });
};

export const getAvailableFlowStates = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/visitstatustypes?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAvailableFlowStates error: ", e);
        reject(e);
      });
  });
};

export const getAllColumnsOfTvScreen = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getdevicecolumntypes/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllColumnsOfTvScreen error: ", e);
        reject(e);
      });
  });
};

export const getSelectedColumnsOfATvScreen = (screenId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getscreencolumns/${screenId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSelectedColumnsOfATvScreen error: ", e);
        reject(e);
      });
  });
};

export const updateColumnsOfATvScreen = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/devicescreencolumn/?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateColumnsOfATvScreen error: ", e);
        reject(e);
      });
  });
};

export const getDeviceDisplaySettings = (deviceId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/devicedisplaysettings/${deviceId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDeviceDisplaySettings error: ", e);
        reject(e);
      });
  });
};

export const updatePatient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/UpdatePatient?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPatientStatus error: ", e);
        reject(e);
      });
  });
};

export const getLanguageStats = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/detailedstats?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPatientStatus error: ", e);
        resolve();
      });
  });
};

export const getAvailableStatesForCurrentState = (stateId, workFlowId = 2) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getworkflowconnections/${workFlowId}/${stateId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      false
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getDeviceDisplaySettings error: ", e);
        reject(e);
      });
  });
};

export const getSMSLog = (cellfrom) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/TwilioGetSMSReponse?cellfrom=${cellfrom}&code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSMSLog error: ", e);
        reject(e);
      });
  });
};

export const sendMessage = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/TwilioSendSMS?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        if (res.indexOf("The 'To' number") > -1) {
          reject();
        } else {
          resolve(res);
        }
      })
      .catch((e) => {
        console.log("sendMessage error: ", e);
        reject(e);
      });
  });
};

export const getCannedMessages = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/systemsms?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSMSLog error: ", e);
        reject(e);
      });
  });
};

export const getClinicCustomStates = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getcustomstatusnames/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicCustomStates error: ", e);
        reject(e);
      });
  });
};

export const setClinicCustomStates = (payload) => {
  console.log('payload :>> ', payload);
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/cliniccustomstatusnames?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("setClinicCustomStates error: ", e);
        reject(e);
      });
  });
};

export const getAllAppSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/appaddontypes?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllAppSettings error: ", e);
        reject(e);
      });
  });
};

export const getClinicAppSettings = (clinicId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      `${process.env.REACT_APP_BASE_URL}/getclinicappaddon/${clinicId}?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getClinicAppSettings error: ", e);
        reject(e);
      });
  });
};
export const updateClinicAppSettings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${process.env.REACT_APP_BASE_URL}/clinicappaddon?code=${process.env.REACT_APP_ENDPOINT_CODE}&clientId=${process.env.REACT_APP_ENDPOINT_CLIENT_ID}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateClinicAppSettings error: ", e);
        reject(e);
      });
  });
};