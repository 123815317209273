import React, { Component } from "react";
import "./numpad.scss";

const Numpad = (props) => {
  const _registerKeyStroke = (keyCode) => {
    if (keyCode === -1) {
      // Backspace
      props.onDelete();
    } else {
      props.onKeyPress(keyCode);
    }
  };

  return (
    <div className="numberPadOuterWrapper">
      <div className="numberPadInnerWrapper">
        <div className="numRow">
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(1)}>
              1
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(2)}>
              2
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(3)}>
              3
            </button>
          </div>
        </div>
        <div className="numRow">
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(4)}>
              4
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(5)}>
              5
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(6)}>
              6
            </button>
          </div>
        </div>
        <div className="numRow">
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(7)}>
              7
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(8)}>
              8
            </button>
          </div>
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(9)}>
              9
            </button>
          </div>
        </div>
        <div className="numRow lastRow">
          <div className="keyWrapper">
            <button className="numKey" onClick={(e) => _registerKeyStroke(0)}>
              0
            </button>
          </div>
          <div className="keyWrapper">
            <button
              className="numKey deleteKey"
              onClick={(e) => _registerKeyStroke(-1)}
            >
              {"<-"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numpad;
