import React, { Component } from "react";
import "./time-tange-picker.scss";
import TimeRange from 'react-time-range';
import moment from "moment";

const TimeRangePicker = ({startTime, endTime, onChange}) => {
  return (
    <>
      <div
        className="time-range-picker-wrapper
"
      >
        <TimeRange
          startMoment={startTime}
          endMoment={endTime}
          onChange={onChange}
        />
        <button className="resetTimeRange" onClick={e => {
          onChange({
            startTime: moment("00:00", "hh:mm").toISOString(),
            endTime: moment("23:59", "hh:mm").toISOString(),
          })
        }}>
        <i class="fas fa-redo"></i>
        </button>
      </div>
    </>
  );
};

export default TimeRangePicker;
