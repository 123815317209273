import { deepClone, sleepTime } from "helper-methods";
import {
  getMyPhlebotomistPatients,
  getPhlebotomistPatientsInQueue,
  updatePatientStatus,
} from "http-calls";
import ExtendedActions from "modules/general/components/extended-actions/extended-actions";
import SearchInput from "modules/general/components/search-input/search-input";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  hideBottomLoader,
  hideLoader,
  showBottomLoader,
  showLoader,
} from "redux/actions/loader-data";
import "./patient-table.scss";
import { hideCharactersPartiallyInAString } from "helper-methods";

let intervalRef = null;

const PatientTable = (props) => {
  const [patientsInQueue, setPatientsInQueue] = useState([]);
  const [filteredRowsForMyPatients, setFilteredRowsForMyPatients] = useState(
    []
  );
  const [
    filteredRowsForOtherPatients,
    setFilteredRowsForOtherPatients,
  ] = useState([]);
  const [
    searchValueForOtherPatients,
    setSearchValueForOtherPatients,
  ] = useState("");
  const [isActionModalVisible, setIsActionModalVisible] = useState(false);
  const [searchValueForMyPatients, setSearchValueForMyPatients] = useState("");
  const [myPatients, setMyPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);

  const _loadPatients = async () => {
    _initiateBackgroundRefresher();
    try {
      const myPatientsResponse = await getMyPhlebotomistPatients(
        props.selectedUser.Username,
        props.clinicId
      );
      setMyPatients(myPatientsResponse);
    } catch (error) {
      console.log("error :>> ", error);
      setMyPatients([]);
      // showToast("Error while assigning");
      props.hideLoader();
    }
    try {
      const patientsInQueueResponse = await getPhlebotomistPatientsInQueue(
        props.clinicId
      );
      setPatientsInQueue(patientsInQueueResponse);
      props.hideLoader();
    } catch (error) {
      console.log("error :>> ", error);
      // showToast("Error while assigning");
      setPatientsInQueue([]);
      await sleepTime(700);
      props.hideLoader();
    }
  };

  const _filterRowsForMyPatients = () => {
    let filteredRows = deepClone(myPatients);
    const lowerCasedSearchValue = searchValueForMyPatients.toLowerCase().trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForMyPatients(filteredRows);
  };

  const _filterRowsForOtherPatients = () => {
    let filteredRows = deepClone(patientsInQueue);
    const lowerCasedSearchValue = searchValueForOtherPatients
      .toLowerCase()
      .trim();
    if (lowerCasedSearchValue && lowerCasedSearchValue.length) {
      filteredRows = filteredRows.filter((row) => {
        if (row.CallNumber.toLowerCase().indexOf(lowerCasedSearchValue) > -1) {
          return true;
        }
        if (
          `${row.FirstName} ${row.LastName}`
            .toLowerCase()
            .indexOf(lowerCasedSearchValue) > -1
        ) {
          return true;
        }
      });
    }
    setFilteredRowsForOtherPatients(filteredRows);
  };

  const _initiateBackgroundRefresher = () => {
    if (!intervalRef) {
      intervalRef = setInterval(() => {
        _loadPatients();
      }, 5000);
    }
  };

  useEffect(() => {
    props.showLoader("Loading");
    _loadPatients();
    return () => {
      clearInterval(intervalRef);
    };
  }, []);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [myPatients]);

  useEffect(() => {
    _filterRowsForMyPatients();
  }, [searchValueForOtherPatients]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [patientsInQueue]);

  useEffect(() => {
    _filterRowsForOtherPatients();
  }, [searchValueForOtherPatients]);

  const _updatePatientStatus = async (patient, status) => {
    console.log("patient :>> ", patient);
    // Call API to update the status
    props.showBottomLoader("Updating");
    let PatientStatusTypeID = status;
    await updatePatientStatus({
      ...patient,
      UpdatedBy: props.selectedUser.Username,
      PatientStatusTypeID,
    });
    props.logout();
    props.hideLoader();
  };

  const myPatientActions = props.availableStates.called.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
    onInlineButtonClick: (patient) => {
      _updatePatientStatus(patient, state.StatusID);
    },
  }));

  const allPatientActions = props.availableStates.phlebotomist.map((state) => ({
    label: state.Status,
    onClick: () => {
      setIsActionModalVisible(false);
      _updatePatientStatus(selectedPatient, state.StatusID);
    },
    onInlineButtonClick: (patient) => {
      _updatePatientStatus(patient, state.StatusID);
    },
  }));

  const _showActionModal = (patient) => {
    console.log("patient :>> ", patient);
    setSelectedPatient(patient);
    setIsActionModalVisible(true);
  };

  if (myPatients.length) {
    return (
      <div className="tableWrapper">
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={myPatientActions}
          modalHeight={200 + 80 * myPatientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <h3>My Patients</h3>
        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForMyPatients}
              onChange={setSearchValueForMyPatients}
            />
          </div>
          <div className="tableInternalScroll">
            <table>
              <thead>
                <tr>
                  <th>Call Number</th>
                  <th>Name</th>
                  <th>Reason for Visit</th>
                  <th>Comment</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredRowsForMyPatients.map((patient, patientIndex) => (
                  <tr key={patientIndex}>
                    <td>{patient.CallNumber}</td>
                    <td>
                      {hideCharactersPartiallyInAString(patient.FirstName) +
                        " " +
                        hideCharactersPartiallyInAString(patient.LastName)}
                    </td>
                    <td>{patient.VisitType.toUpperCase()}</td>
                    <td>{patient.internalcomments}</td>
                    <td>
                      <div className="actions">
                        <div className="buttonsWrapper">
                          {myPatientActions.length > 2 ? (
                            <button
                              className="actions"
                              onClick={(e) => _showActionModal(patient)}
                            >
                              <i className="fa fa-cog" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <>
                              {myPatientActions.map((action, actionIndex) => (
                                <button
                                  key={actionIndex}
                                  onClick={(e) =>
                                    action.onInlineButtonClick(patient)
                                  }
                                  className="completeBtn"
                                >
                                  {action.label}
                                </button>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {myPatients.length === 0 && (
              <div id="noCell">No Patients Available</div>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="tableWrapper">
        <ExtendedActions
          isVisible={isActionModalVisible}
          onCancel={(e) => setIsActionModalVisible(false)}
          actions={allPatientActions}
          modalHeight={200 + 80 * allPatientActions.length + "px"}
          selectedPatient={selectedPatient}
        />
        <h3>Available Patients</h3>
        <div id="table" className="pTable">
          <div className="tableActions centered">
            <SearchInput
              value={searchValueForOtherPatients}
              onChange={setSearchValueForOtherPatients}
            />
          </div>
          <div className="tableInternalScroll">
            <table>
              <thead>
                <tr>
                  <th>Call Number</th>
                  <th>Name</th>
                  <th>Reason for Visit</th>
                  <th>Comment</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredRowsForOtherPatients.map((patient, patientIndex) => (
                  <tr key={patientIndex}>
                    <td>{patient.CallNumber}</td>
                    <td>
                      {hideCharactersPartiallyInAString(patient.FirstName) +
                        " " +
                        hideCharactersPartiallyInAString(patient.LastName)}
                    </td>
                    <td>{patient.VisitType.toUpperCase()}</td>
                    <td>{patient.internalcomments}</td>
                    <td>
                      <div className="actions">
                        <div className="buttonsWrapper">
                          {allPatientActions.length > 2 ? (
                            <button
                              className="actions"
                              onClick={(e) => _showActionModal(patient)}
                            >
                              <i className="fa fa-cog" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <>
                              {allPatientActions.map((action, actionIndex) => (
                                <button
                                  key={actionIndex}
                                  onClick={(e) =>
                                    action.onInlineButtonClick(patient)
                                  }
                                  className="completeBtn"
                                >
                                  {action.label}
                                </button>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {patientsInQueue.length === 0 && (
              <div id="noCell">No Patients Available</div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    showBottomLoader: (text) => dispatch(showBottomLoader(text)),
    hideBottomLoader: () => dispatch(hideBottomLoader()),
  };
};

export default connect(null, mapDispatchToProps)(PatientTable);
