import React, { Component } from "react";
import parse from "html-react-parser";

import "./custom-clinic-view.scss";

const CustomClinicView = (props) => {
  const { clinicSettings, proceedToStep } = props;
  const _extractCustomContent = () => {
    let customContent = null;
    if (clinicSettings?.length) {
      const customHomePageSettings = clinicSettings.find(
        (s) => s.ClinicSystemSettingType === "Custom Home Page"
      );
      if (customHomePageSettings?.SettingValue1?.length) {
        customContent = {
          title: customHomePageSettings.SettingValue1,
        };
      }
      if (customHomePageSettings?.SettingValue2?.length) {
        customContent = {
          body: customHomePageSettings.SettingValue2,
        };
      }
    }
    return customContent;
  };
  const customContent = _extractCustomContent();
  if (customContent) {
    return (
      <>
        {customContent?.title ? customContent?.title : ""}
        {customContent?.body ? (
          <div className="container">
            <div className="row">
              <div className="clinicCustomViewWrapper">
                <div className="intakeFormLink">
                  <button onClick={(e) => proceedToStep("languageSelector")}>
                    Register here
                  </button>
                </div>
                {parse(customContent.body)}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default CustomClinicView;
