import { getDashboardPatientsStats } from "http-calls";
import moment from "moment";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import PatientsColumnChart from "../patients-column-chart/patients-column-chart";
import PatientsCSVDownloader from "../patients-csv-downloader/patients-csv-downloader";
import PatientsVisitDetailsTable from "../patients-visit-details-table/patients-visit-details-table";
import SidebarFilter from "../sidebar-filter/sidebar-filter";
import TimeRangePicker from "../time-tange-picker/time-tange-picker";
import "./detailed-stats.scss";
import { deepClone } from "helper-methods";
import { showLoader } from "redux/actions/loader-data";
import { hideLoader } from "redux/actions/loader-data";
import { connect } from "react-redux";

class DetailedStats extends Component {
  state = {
    allPatientsLogs: [],
    filterOptions: {
      registeredByList: [],
      calledByList: [],
      visitReasonList: [],
    },
    filters: {
      registeredBy: [],
      calledBy: [],
      visitReason: [],
      startTime: moment("00:00", "hh:mm").toISOString(),
      endTime: moment("23:59", "hh:mm").toISOString(),
    },
    isLoading: false,
  };

  componentDidMount() {
    this._fetchStats();
  }

  _toggleLoader = (isLoading) => {
    this.setState({ isLoading });
  };

  _onTimeChange = ({ startTime, endTime }) => {
    console.log("startTime, endTime :>> ", startTime, endTime);
    const { filters } = this.state;
    filters.startTime = startTime;
    filters.endTime = endTime;
    this.setState({
      filters,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevProps.dateRange) !==
      JSON.stringify(this.props.dateRange)
    ) {
      this._fetchStats();
    }
  }

  _setFilters = (filterType, filterOptions) => {
    const { filters } = this.state;
    filters[filterType] = filterOptions;
    this.setState({ filters });
  };

  _extractFilterLists = (logs) => {
    const registeredByMap = {};
    const calledByMap = {};
    const visitReasonMap = {};
    logs.forEach((log) => {
      if (log.Status === "Registered") {
        if (log?.UpdatedBy?.length) {
          registeredByMap[log.UpdatedBy] = true;
        }
      }
      if (log.Status === "Called") {
        if (log?.UpdatedBy?.length) {
          calledByMap[log.UpdatedBy] = true;
        }
      }
      if (log?.VisitType?.length) {
        visitReasonMap[log.VisitType] = true;
      }
    });
    this.setState({
      filterOptions: {
        registeredByList: Object.keys(registeredByMap),
        calledByList: Object.keys(calledByMap),
        visitReasonList: Object.keys(visitReasonMap),
      },
    });
  };

  _fetchStats = async () => {
    try {
      this._toggleLoader(true);
      const { dateRange } = this.props;
      const StartDate = moment(dateRange[0]).format("MM/DD/YYYY");
      const EndDate = moment(dateRange[1]).format("MM/DD/YYYY");
      const ClinicID =
        this.props.userData && this.props.userData.selectedClinic
          ? this.props.userData.selectedClinic.ClinicID
          : 1;
      const response = await getDashboardPatientsStats({
        StartDate,
        EndDate,
        ClinicID,
      });
      // Prepare data for chart view
      const allPatientsLogs = JSON.parse(response);
      console.log("allPatientsLogs :>> ", allPatientsLogs);
      this._extractFilterLists(allPatientsLogs);
      // const chartData = this._prepareChartData(allPatientsLogs);
      // Prepare data for table view
      // const tableData = this._prepareTableData(allPatientsLogs);
      this.setState({ allPatientsLogs });
      this._toggleLoader(false);
    } catch (error) {
      console.log("error :>> ", error);
      this.setState({ allPatientsLogs: [] });
      this._toggleLoader(false);
    }
  };

  _filterLogs = () => {
    // this.props.showLoader("Filtering");
    const { filters, allPatientsLogs } = this.state;
    // First filter by registered status
    let filteredPatientIds = {};
    let filteredLogs = deepClone(allPatientsLogs);
    if (filters.registeredBy.length) {
      filteredLogs = this._filterLogsHavingStatus(filteredLogs, "Registered");
    }
    if (filters.calledBy.length) {
      filteredLogs = this._filterLogsHavingStatus(filteredLogs, "Called");
    }
    if (filters.registeredBy.length) {
      // Filters are available
      for (let log of filteredLogs) {
        if (log.Status === "Registered") {
          if (filters.registeredBy.indexOf(log.UpdatedBy) > -1) {
            filteredPatientIds[log.PatientID] = true;
          }
        }
      }
    } else {
      // Filters are not set
      for (let log of allPatientsLogs) {
        filteredPatientIds[log.PatientID] = true;
      }
    }
    // Next filter by called status
    if (filters.calledBy.length) {
      // Filters are available
      for (let log of filteredLogs) {
        if (log.Status === "Called") {
          if (filters.calledBy.indexOf(log.UpdatedBy) === -1) {
            // Not available
            // Check if still exists in filteredPatientIds
            if (filteredPatientIds[log.PatientID]) {
              delete filteredPatientIds[log.PatientID];
            }
          }
        }
      }
    }
    // Next filter by visitreason
    if (filters.visitReason.length) {
      // Filters are available
      for (let log of filteredLogs) {
        if (filters.visitReason.indexOf(log.VisitType) === -1) {
          // Not available
          // Check if still exists in filteredPatientIds
          if (filteredPatientIds[log.PatientID]) {
            delete filteredPatientIds[log.PatientID];
          }
        }
      }
    }
    // Filter by start and end time
    if (filters?.startTime?.length && filters?.endTime?.length) {
      // Filters are available
      for (let log of filteredLogs) {
        const startTimeInMoment = moment(
          moment(filters.startTime).format("h:mma"),
          "h:mma"
        );
        const endTimeInMoment = moment(
          moment(filters.endTime).format("h:mma"),
          "h:mma"
        );
        const logUpdateTimeInMoment = moment(
          moment(log.UpdateDate).format("h:mma"),
          "h:mma"
        );
        if (
          !(
            logUpdateTimeInMoment.isBefore(endTimeInMoment) &&
            logUpdateTimeInMoment.isAfter(startTimeInMoment)
          )
        ) {
          // Not available
          // Check if still exists in filteredPatientIds
          if (filteredPatientIds[log.PatientID]) {
            delete filteredPatientIds[log.PatientID];
          }
        }
      }
    }
    const filteredPatients = filteredLogs.filter(
      (log) => !!filteredPatientIds[log.PatientID]
    );
    // this.props.hideLoader();
    return filteredPatients;
  };

  _generateChartData = (patientsLogs) => {
    // Group patients
    const patients = {};
    patientsLogs.forEach((patientLog) => {
      if (!patients[patientLog.PatientVisitID]) {
        patients[patientLog.PatientVisitID] = {
          name: `${patientLog.Firstname} ${patientLog.LastName}`,
          times: {},
        };
      }
      patients[patientLog.PatientVisitID].times[patientLog.Status] = +new Date(
        patientLog.UpdateDate
      );
    });
    // Calculate total lab time
    for (let patientVisitId in patients) {
      patients[patientVisitId].totalLabTime = this._calculateTotalLabTime(
        patients[patientVisitId].times
      );
    }
    // Prepare chart format
    const chartData = [];
    for (let patientVisitId in patients) {
      chartData.push([
        patients[patientVisitId].name,
        patients[patientVisitId].totalLabTime,
      ]);
    }
    return chartData;
  };

  _calculateTotalLabTime = (patientTimes) => {
    const firstPhase = this._determineFirstPhase(patientTimes);
    const lastPhase = this._determineLastPhase(patientTimes);
    const firstPhaseTime = patientTimes[firstPhase];
    const lastPhaseTime = patientTimes[lastPhase];
    const time = this._toFixedIfNecessary(
      (lastPhaseTime - firstPhaseTime) / (1000 * 60)
    );
    if (time > 300) {
      return 0;
    } else {
      return time;
    }
  };

  _generateTableData = (allPatientsLogs) => {
    // Group patients
    const patients = {};
    allPatientsLogs.forEach((patientLog) => {
      if (!patients[patientLog.PatientVisitID]) {
        patients[patientLog.PatientVisitID] = {
          ...patientLog,
          stages: {},
        };
      }
      patients[patientLog.PatientVisitID].stages[patientLog.Status] = {
        updatedBy: patientLog.UpdatedBy,
        updatedAt: patientLog.UpdateDate,
      };
    });
    // Calculate and prepare table data
    const tableData = [];
    for (let patientVisitId in patients) {
      const tableRow = {
        registeredBy:
          patients[patientVisitId].stages["Registered"] &&
          patients[patientVisitId].stages["Registered"].updatedBy
            ? patients[patientVisitId].stages["Registered"].updatedBy
            : "--",
        calledBy:
          patients[patientVisitId].stages["Called"] &&
          patients[patientVisitId].stages["Called"].updatedBy
            ? patients[patientVisitId].stages["Called"].updatedBy
            : "--",
        patientCallNumber: patients[patientVisitId].Callnumber,
        firstName: patients[patientVisitId].Firstname,
        lastName: patients[patientVisitId].LastName,
        times: {},
        patientData: patients[patientVisitId],
      };
      const { stages } = patients[patientVisitId];
      tableRow.times.signInToRegister = this._calculateTimeDifference(
        {
          from: "SignedIn",
          to: "Registered",
        },
        stages
      );
      tableRow.times.registerToOrderActivation = this._calculateTimeDifference(
        {
          from: "Registered",
          to: "Ready For Order Activation",
        },
        stages
      );
      tableRow.times.orderActivationToPendingCollection = this._calculateTimeDifference(
        {
          from: "Ready For Order Activation",
          to: "Pending Collection",
        },
        stages
      );
      tableRow.times.pendingCollectionToCalled = this._calculateTimeDifference(
        {
          from: "Pending Collection",
          to: "Called",
        },
        stages
      );
      tableRow.times.calledToCompleted = this._calculateTimeDifference(
        {
          from: "Called",
          to: "Completed",
        },
        stages
      );
      tableRow.times.totalCompletion = this._sum([
        tableRow.times.signInToRegister,
        tableRow.times.registerToOrderActivation,
        tableRow.times.orderActivationToPendingCollection,
        tableRow.times.pendingCollectionToCalled,
        tableRow.times.calledToCompleted,
      ]);
      tableRow.times.totalLabTime = this._calculateTimeDifference(
        {
          from: "Registered",
          to: "Completed",
        },
        stages
      );
      tableRow.times.noAnswer = "--";
      tableRow.times.orderIssueTime = "--";
      tableData.push(tableRow);
    }
    return tableData;
  };

  _toFixedIfNecessary = (value, dp = 2) => {
    return +parseFloat(Math.abs(value)).toFixed(dp);
  };

  _calculateTimeDifference = (stages, allStates) => {
    const { from, to } = stages;
    if (
      allStates[from] &&
      Object.keys(allStates[from]).length &&
      allStates[to] &&
      Object.keys(allStates[to]).length
    ) {
      console.log("allStates[from].updatedAt :>> ", allStates[from].updatedAt);
      console.log("allStates[to].updatedAt :>> ", allStates[to].updatedAt);
      return this._toFixedIfNecessary(
        (parseInt(+new Date(allStates[to].updatedAt)) -
          parseInt(+new Date(allStates[from].updatedAt))) /
          (1000 * 60)
      );
    } else {
      return "--";
    }
  };

  _sum = (values) => {
    let sum = 0;
    values.forEach((value) => {
      if (!isNaN(value)) {
        sum += parseFloat(value);
      }
    });
    return this._toFixedIfNecessary(sum);
  };

  _determineLastPhase = (patientTimes) => {
    const phasesInDescendingOrder = [
      "SignedIn",
      "Registered",
      "Ready For Order Activation",
      "Pending Collection",
      "Called",
      "Completed",
      "Order Issues",
      "No Answer",
    ];
    for (let phase of phasesInDescendingOrder.reverse()) {
      if (patientTimes[phase] && patientTimes[phase] > 0) {
        return phase;
      }
    }
  };

  /**
   * Will filter logs which are having the passed status
   */
  _filterLogsHavingStatus = (logsToBeFiltered = [], status = "") => {
    const patientIdsWithStatus = {};
    for (let log of logsToBeFiltered) {
      if (log.Status === status) {
        patientIdsWithStatus[log.PatientID] = true;
      }
    }
    return logsToBeFiltered.filter(
      (log) => !!patientIdsWithStatus[log.PatientID]
    );
  };

  _determineFirstPhase = (patientTimes) => {
    const phasesInDescendingOrder = [
      "SignedIn",
      "Registered",
      "Ready For Order Activation",
      "Pending Collection",
      "Called",
      "Completed",
      "Order Issues",
      "No Answer",
    ];
    for (let phase of phasesInDescendingOrder) {
      if (patientTimes[phase] && patientTimes[phase] > 0) {
        return phase;
      }
    }
  };

  render() {
    const { isLoading, filterOptions, filters } = this.state;
    const { isActive } = this.props;

    const filteredLogs = this._filterLogs();

    const chartData = this._generateChartData(filteredLogs);
    const tableData = this._generateTableData(filteredLogs);

    const columns = [
      {
        id: "first",
        displayName: "First column",
      },
      {
        id: "second",
        displayName: "Second column",
      },
    ];

    const datas = [
      {
        first: "foo",
        second: "bar",
      },
      {
        first: "foobar",
        second: "foobar",
      },
    ];

    if (isActive) {
      if (isLoading) {
        return (
          <div className="dashboardLoaderWrapper">
            <Loader type="ThreeDots" color="black" height={50} width={50} />
          </div>
        );
      } else {
        return (
          <>
            <div className="detailedStatsWrapper">
              <div className="dashboardSidebarWarpper">
                <SidebarFilter
                  headerLabel={"Registered By"}
                  allOptions={filterOptions?.registeredByList || []}
                  onOptionsUpdate={(registeredByFilters) =>
                    this._setFilters("registeredBy", registeredByFilters)
                  }
                />
                <SidebarFilter
                  headerLabel={"Called By"}
                  allOptions={filterOptions?.calledByList || []}
                  onOptionsUpdate={(calledByFilters) =>
                    this._setFilters("calledBy", calledByFilters)
                  }
                />
                <SidebarFilter
                  headerLabel={"Reason for visit"}
                  allOptions={filterOptions?.visitReasonList || []}
                  onOptionsUpdate={(visitReasonFilters) =>
                    this._setFilters("visitReason", visitReasonFilters)
                  }
                />
              </div>
              <div className="detailedStats">
                <div className="dashboardTimeRangePicker">
                  <TimeRangePicker
                    startTime={filters.startTime}
                    endTime={filters.endTime}
                    onChange={this._onTimeChange}
                  />
                </div>
                <div className="sectionHeader">
                  Total Completion by First Name & Last Name
                </div>
                <PatientsColumnChart chartData={chartData} />
                <div className="csvDownloadPromptBtnWrapper">
                  <PatientsCSVDownloader data={tableData}>
                    <button className="csvDownloadPromptBtn">
                      <i className="fa fa-download" aria-hidden="true"></i>
                      Download CSV
                    </button>
                  </PatientsCSVDownloader>
                </div>
                <div className="sectionHeader tableHeader">
                  Patient Visit Process
                </div>
                <PatientsVisitDetailsTable tableData={tableData} />
              </div>
            </div>
          </>
        );
      }
    } else {
      return <></>;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailedStats);
