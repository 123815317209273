const spanish = {
  firstname: "Primer Nombre",
  lastname: "Apellido",
  dob: "Fecha de Nacimiento",
  dobformat: "Formato de Fecha: mm/dd/yyyy",
  phonenumber: "Número de Teléfono",
  phoneformat: "Formato del teléfono: 123-456-7890",
  hasappointment: "¿Tengo una Cita?",
  requiredfields: "* Campos Requeridos",
  requiredwarning:
    "Debe ingresar todos los campos obligatorios antes de continuar",
  dobwarning:
    "La fecha de nacimiento no es válida. Ingrese una fecha de nacimiento con el formato: mm/dd/yyyy. Tenga en cuenta: el valor del año debe estar entre 1919 y este año",
  phonenowarning:
    "El número de teléfono ingresado no es válido. Por favor ingrese un número de teléfono válido",
  helpbtntext: "Ayuda",
  homebutton: "Casa",
  scanid: "Escanear mi ID",
  next: "Próximo",
  providerinfotitle: "¿A quién estás aquí para ver?",
  providerinfotxt: "Seleccione el proveedor que está aquí para ver.",
  successtitle: "¡Se ha iniciado sesión correctamente!",
  successtxt:
    "Has iniciado sesión correctamente en el sistema de quiosco. Tome asiento ya que alguien de nuestro personal se comunicará con usted en breve.",
  helptitle: "Ayuda de quiosco",
  helptxt1:
    "Si necesita ayuda, deberá ir a la ventana y cancelar su registro electrónico.",
  helptxt2:
    "Si desea continuar con su registro electrónico, haga clic en el botón Cerrar.",
  helpclose: "Cerrar",
  visitReason: "Razón de la visita",
  previous: "Previa",
  callnumberlabel:
    "Número de cuatro dígitos (identificación única para notificarle de los servicios) ",
  callnowarning:
    "El número de llamada ingresado no es válido. Ingrese un número de llamada de 4 dígitos",
  startover: "Comenzar de nuevo",
  donthavephonenumber: "NO TENGO NÚMERO DE TELÉFONO",
  callnumberdisplaylabel: "Tu número de llamada es ",
  waittext: "Siéntate y alguien te llamará",
};

export default spanish;
