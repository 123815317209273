import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./patient-edit-prompt.scss";
import CustomInput from "components/CustomInput/CustomInput";
import MaskedInput from "react-input-mask";
import { deepClone } from "helper-methods";
import moment from "moment";
import { submitPatientData } from "http-calls";
import Swal from "sweetalert2";
import { showToast } from "helper-methods";
import { checkIfPatientExists } from "http-calls";
import { EventEmitter } from "utils/event-emitter";
import { updatePatient } from "http-calls";

const initialState = {
  formFields: {
    firstName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    lastName: {
      value: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    dateOfBirth: {
      value: "",
      isValid: false,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  generalError: "",
  isLoaderActive: false,
  patient: null,
  isVisible: false,
};

class PatientEditPrompt extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    this._registerEvents();
  }

  _registerEvents = () => {
    EventEmitter.subscribe("edit-patient", (patient) => {
      this._loadPatient(patient);
    });
  };

  _loadPatient = (patient) => {
    const { formFields } = this.state;
    formFields.firstName.value = patient.FirstName;
    formFields.lastName.value = patient.LastName;
    formFields.dateOfBirth.value = moment(patient.DOB).format("MM/DD/YYYY");
    this.setState({ patient, formFields, isVisible: true });
  };

  _hidePrompt = () => {
    this._resetState();
  };

  _resetState = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _isDateValid = (dateOfBirth) => {
    let unMaskedDob = dateOfBirth.replace(/_/g, "");
    unMaskedDob = unMaskedDob.replace(/\//g, "");
    if (unMaskedDob.length !== 8) {
      return false;
    }
    if (!moment(dateOfBirth, "MM/DD/YYYY").isValid()) {
      return false;
    }
    if (!moment(unMaskedDob, "MM/DD/YYYY").isBefore(moment())) {
      return false;
    }
    if (
      !moment(unMaskedDob, "MM/DD/YYYY").isAfter(
        moment("31/12/1918", "DD/MM/YYYY")
      )
    ) {
      return false;
    }
    return true;
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { visitReasons } = this.props;

      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "firstName": {
            if (formFields.firstName.value.length >= 1) {
              formFields.firstName.isValid = true;
            } else {
              formFields.firstName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "lastName": {
            if (formFields.lastName.value.length >= 1) {
              formFields.lastName.isValid = true;
            } else {
              formFields.lastName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "dateOfBirth": {
            if (
              formFields.dateOfBirth.value &&
              formFields.dateOfBirth.value.length &&
              this._isDateValid(formFields.dateOfBirth.value)
            ) {
              formFields.dateOfBirth.isValid = true;
            } else {
              formFields.dateOfBirth.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _updatePatient = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { formFields, isFormValid } = this.state;
    if (isFormValid) {
      const { patient } = this.state;
      this._toggleLoader(true);
      try {
        const payload = {
          FirstName: formFields.firstName.value,
          LastName: formFields.lastName.value,
          DOB: formFields.dateOfBirth.value,
          PatientID: patient.PatientID,
        };
        await updatePatient(payload);
        this._toggleLoader(false);
        showToast("Patient updated successfully", "success", {
          positon: "bottom-center",
        });
        this._hidePrompt();
      } catch (error) {
        this._toggleLoader(false);
        console.log("error :>> ", error);
        this.setState({ generalError: "Failed to update patient" }, () => {});
      }
    }
  };

  _toggleLoader = (status) => {
    this.setState({ isLoaderActive: status });
  };

  render() {
    const { formFields, generalError, isLoaderActive, isVisible } = this.state;

    return (
      <>
        <Modal
          visible={isVisible}
          width="600"
          height="360"
          effect="fadeInUp"
          onClickAway={this._hidePrompt}
        >
          <div className="patientRegistrationPromptWrapper">
            <div className="modalHeader">
              <button className="dismissBtn" onClick={this._hidePrompt}>
                Close
              </button>
            </div>
            <h3>Update patient information</h3>
            <div className="promptFormWrapper">
              <div className="promptFormRow">
                <div className="promptInputWrapper">
                  <div className="inputWrapper">
                    <CustomInput
                      labelText="First Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: formFields.firstName.value,
                        onChange: (e) =>
                          this._updateFieldValue("firstName", e.target.value),
                        onBlur: () => this._markAsDirty("firstName"),
                      }}
                    />
                    <div className="errorText">
                      {!formFields.firstName.isValid &&
                      formFields.firstName.isDirty
                        ? "Please provide valid first name"
                        : ""}
                    </div>
                  </div>
                  <div className="inputWrapper">
                    <CustomInput
                      labelText="Last Name"
                      id="float"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: formFields.lastName.value,
                        onChange: (e) =>
                          this._updateFieldValue("lastName", e.target.value),
                        onBlur: () => this._markAsDirty("lastName"),
                      }}
                    />
                    <div className="errorText">
                      {!formFields.lastName.isValid &&
                      formFields.lastName.isDirty
                        ? "Please provide valid last name"
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
              <div className="promptFormRow">
                <div className="promptInputWrapper dobInput">
                  <div className="dobLabel">Date of birth: </div>
                  <MaskedInput
                    {...this.props}
                    mask="99/99/9999"
                    // alwaysShowMask
                    value={formFields.dateOfBirth.value}
                    onChange={(e) =>
                      this._updateFieldValue("dateOfBirth", e.target.value)
                    }
                    placeholder={"MM/DD/YYYY"}
                    onBlur={(e) => this._markAsDirty("dateOfBirth")}
                  />
                </div>
              </div>
              <div className="outerErrorText">
                {!formFields.dateOfBirth.isValid &&
                formFields.dateOfBirth.isDirty
                  ? "Please provide valid Date of Birth"
                  : ""}
              </div>

              <div className="bottomAction">
                {isLoaderActive ? (
                  <div className="loader"></div>
                ) : (
                  <button className="submitBtn" onClick={this._updatePatient}>
                    Update Patient
                  </button>
                )}
              </div>
              <div className="generalError">{generalError}</div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default PatientEditPrompt;
