const initialState = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  callNumber: '',
  dob: '',
  visitReason: '',
  language: 'english'
}

export const wizardDataReducer = (state = initialState, action) => {
    let newState = { ...state };
    switch (action.type) {
        case 'UPDATE_WIZARD_DATA': {
            newState = {
              ...newState,
              ...action.payload.wizardData
            }
            break;
        }
        case 'CLEAR_WIZARD_DATA': {
            newState = initialState;
            break;
        }
        default: {
            
        }
    }
    return newState;
}
