export const updateUtilsData = utilsData => {
  return {
      type: "UPDATE_UTILS_DATA",
      payload: {
          utilsData
      }
  }
}

export const clearUtilsData = () => {
  return {
      type: "CLEAR_UTILS_DATA"
  }
}