import React, { Component } from "react";
import { Chart } from "react-google-charts";
import "./patients-column-chart.scss";

class PatientColumnChart extends Component {
  state = {};
  render() {
    const { chartData } = this.props;

    return (
      <div className="patientCOlumnChartWrapper">
        <Chart
          width={"100%"}
          height={"500px"}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={[["Patient Name", "Time (In minutes)"], ...chartData]}
          options={{
            chartArea: { width: "90%", height: "40%" },
            backgroundColor: "#EEEEEE",
            legend: { position: "none" },
            hAxis: {
              direction: -1,
              slantedText: true,
              slantedTextAngle: 90,
              minValue: 0,
            },
            vAxis: {
              // title: "City",
            },
          }}
          // For tests
          rootProps={{ "data-testid": "1" }}
        />
      </div>
    );
  }
}

export default PatientColumnChart;
