import React, { Component } from "react";
import { deepClone } from "helper-methods";
import Modal from "react-awesome-modal";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import ColorPicker from "material-ui-color-picker";
import "./update-settings-prompt.scss";

import MatSuggestionInput from "modules/general/components/mat-suggestion-input/mat-suggestion-input";
import RichTextEditor from "core/rich-text-editor/rich-text-editor";

const initialState = {
  formFields: {
    field1: {
      value: "",
      label: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
    field2: {
      value: "",
      label: "",
      isValid: true,
      isDirty: false,
      isRequired: true,
    },
  },
  isFormValid: false,
  selectedUser: null,
  generalError: "",
};

class UpdateSettingsPrompt extends Component {
  state = deepClone(initialState);

  componentDidMount() {
    this._initializeFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isVisible && this.props.isVisible) {
      // Opened
      this._initializeFields();
    } else if (prevProps.isVisible && !this.props.isVisible) {
      this._resetFields();
    }
  }

  _initializeFields = () => {
    if (this.props.selectedSettingId) {
      const { formFields } = this.state;
      const { selectedSettingId, allSettings } = this.props;
      // Determine the setting
      const currentSetting = allSettings.find(
        (s) => s.ClinicSystemSettingTypeID === selectedSettingId
      );
      formFields.field1.label = currentSetting.fields.field1.label;
      formFields.field1.value = this._parseValue(
        currentSetting.fields.field1.value,
        currentSetting.fields.field1.label
      );
      formFields.field2.label = currentSetting.fields.field2.label;
      formFields.field2.value = this._parseValue(
        currentSetting.fields.field2.value,
        currentSetting.fields.field2.label
      );
      this.setState({ formFields });
    }
  };

  _parseValue = (value, label) => {
    const { allStates } = this.props;
    switch (label) {
      case "Start Stage":
      case "End Stage": {
        const state = allStates.find((s) => s.StatusID == value);
        if (state) {
          return {
            label: state.Status,
            key: state.StatusID,
          };
        } else {
          return "";
        }
      }
      default: {
        return value;
      }
    }
  };

  _resetFields = () => {
    this.setState(deepClone(initialState));
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields }, () => {
      if (formFields[fieldName].isDirty) {
        // Validate
        this._validateForm();
      }
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "field1": {
            if (
              formFields.field1.value.length >= 1 ||
              Object.keys(formFields.field1.value).length >= 1
            ) {
              formFields.field1.isValid = true;
            } else {
              formFields.field1.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "field2": {
            if (
              formFields.field2.value.length >= 1 ||
              Object.keys(formFields.field2.value).length >= 1
            ) {
              formFields.field2.isValid = true;
            } else {
              formFields.field2.isValid = false;
              isFormValid = false;
            }
            break;
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _discard = () => {
    this.props.onDiscard();
  };

  _submit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    let { formFields, isFormValid } = this.state;
    const { selectedSettingId, allSettings, clinicId } = this.props;
    if (isFormValid) {
      // Prepare settings to send
      const preparedSettings = {
        clinicID: clinicId,
        ClinicSystemSettings: [],
      };
      const settingsIndex = allSettings.findIndex(
        (s) => s.ClinicSystemSettingTypeID === selectedSettingId
      );
      allSettings[settingsIndex].fields.field1.value =
        typeof formFields.field1.value === "string"
          ? formFields.field1.value
          : formFields.field1.value.key;
      allSettings[settingsIndex].fields.field2.value =
        typeof formFields.field2.value === "string"
          ? formFields.field2.value
          : formFields.field2.value.key;
      allSettings[settingsIndex].isEnabled = true;
      allSettings.forEach((setting) => {
        if (setting.isEnabled) {
          preparedSettings.ClinicSystemSettings.push({
            ClinicSystemSettingTypeID: setting.ClinicSystemSettingTypeID,
            SettingValue1: setting.fields.field1.value,
            SettingValue2: setting.fields.field2.value,
          });
        }
      });
      this.props.onUpdate(preparedSettings);
    }
  };

  _renderInput = (field) => {
    const { formFields } = this.state;
    const { allStates } = this.props;

    const allStatesOptions = allStates.map((s) => ({
      label: s.Status,
      key: s.StatusID,
    }));
    switch (formFields[field].label) {
      case "Color": {
        return (
          <>
            <ColorPicker
              style={{ width: "100%" }}
              // defaultValue={formFields[field].value}
              value={formFields[field].value}
              onChange={(e) => this._updateFieldValue(field, e)}
              floatingLabelText="Color picker"
            />
          </>
        );
      }
      case "HTML": {
        return (
          <RichTextEditor
            value={formFields[field].value}
            onChange={(value) => this._updateFieldValue(field, value)}
          />
        );
      }
      case "Time Elapsed":
      case "Rotation Time":
      case "Number of rows":
      case "Title":
      case "Time Elasped": {
        return (
          <>
            <CustomInput
              labelText={formFields[field].label}
              id="float"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: formFields[field].value,
                onChange: (e) => this._updateFieldValue(field, e.target.value),
                onBlur: () => this._markAsDirty(field),
              }}
            />
          </>
        );
      }
      case "Start Stage": {
        return (
          <>
            <MatSuggestionInput
              options={allStatesOptions}
              labelFormatter={(option) =>
                option && option.label ? option.label : ""
              }
              inputLabel={"Select Start Stage"}
              onSelect={(statusId) => this._updateFieldValue(field, statusId)}
              value={formFields[field].value}
            />
          </>
        );
      }
      case "End Stage": {
        return (
          <>
            <MatSuggestionInput
              options={allStatesOptions}
              labelFormatter={(option) =>
                option && option.label ? option.label : ""
              }
              inputLabel={"Select End Stage"}
              onSelect={(statusId) => this._updateFieldValue(field, statusId)}
              value={formFields[field].value}
            />
          </>
        );
      }
    }
  };

  _generateModalSize = () => {
    const { formFields } = this.state;
    const labels = Object.values(formFields).map((f) => f.label);
    let width = "550";
    let height = "400";
    if (labels.indexOf("") > -1) {
      width = "";
      height = "";
    } else if (labels.indexOf("HTML") > -1) {
      width = "90%";
      height = "90%";
    }
    return { width, height };
  };

  render() {
    const { formFields, generalError } = this.state;
    const { width, height } = this._generateModalSize();
    if (this.props.isVisible && width?.length) {
      return (
        <>
          <Modal
            visible={true}
            width={width}
            height={height}
            effect="fadeInUp"
            onClickAway={this._discard}
          >
            <div id="modalFormWrapper">
              <div className="crossWrapper" onClick={this._discard}>
                <i className="fa fa-times" aria-hidden="true"></i>
              </div>
              <h3>Update Settings</h3>
              <div className="promptFormWrapper">
                <div className="promptFormRow">
                  <div className="promptInputWrapper">
                    {this._renderInput("field1")}
                    <p className="formErrorText">
                      {formFields.field1.isDirty && !formFields.field1.isValid
                        ? "Cannot be empty"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="promptFormRow">
                  <div className="promptInputWrapper">
                    {this._renderInput("field2")}
                    <p className="formErrorText">
                      {formFields.field2.isDirty && !formFields.field2.isValid
                        ? "Cannot be empty"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="formActionWrapper">
                  <Button
                    color="success"
                    className="saveButton"
                    onClick={this._submit}
                  >
                    Save
                  </Button>
                  <Button
                    color="danger"
                    className="discardButton"
                    onClick={this._discard}
                  >
                    Discard
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </>
      );
    } else {
      return <></>;
    }
  }
}

export default UpdateSettingsPrompt;
