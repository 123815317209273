import React, { Component } from "react";
import Modal from "react-awesome-modal";
import VisitReasonRow from "../visit-reason-row/visit-reason-row";
import "./manage-visit-reasons.scss";
import { deepClone } from "helper-methods";

class ManageVisitReasons extends Component {
  state = {
    options: [],
    newReasonTextInputValue: "",
    errorDisplay: {
      isVisible: false,
      errorText: "",
    },
  };

  componentDidMount() {
    this._syncOptionsFromProps();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps) !== JSON.stringify(this.props)
    ) {
      this._syncOptionsFromProps();
    }
  }

  _syncOptionsFromProps = () => {
    const { options = [] } = deepClone(this.props);
    this.setState({ options });
  };

  _onNewReasonTextInputUpdate = (newReasonTextInputValue) => {
    this.setState({ newReasonTextInputValue });
  };

  _onAdd = () => {
    const { options, newReasonTextInputValue } = this.state;
    const indexOfOption = options.findIndex(
      (option) => option.toLowerCase() === newReasonTextInputValue.toLowerCase()
    );
    if (indexOfOption > -1) {
      // Already exists
      this._showError("Duplicate option exists");
    } else {
      this._hideError();
      options.push(newReasonTextInputValue);
      this.setState({ options, newReasonTextInputValue: "" }, () => {
        this._scrollToBottomOfList();
      });
    }
  };

  _onSave = () => {
    const { options } = this.state;
    this.props.onOptionsUpdate(options);
  };

  _onDismiss = () => {
    this.props.dismiss();
  };

  _onOptionUpdate = (prevOptionText, newOptionText) => {
    const { options } = this.state;
    const selectedOptionIndex = options.findIndex(
      (option) => option === prevOptionText
    );
    options[selectedOptionIndex] = newOptionText;
    this.setState({ options });
  };

  _onOptionDelete = (optionText) => {
    const { options } = this.state;
    const selectedOptionIndex = options.findIndex(
      (option) => option === optionText
    );
    options.splice(selectedOptionIndex, 1);
    this.setState({ options });
  };

  _scrollToBottomOfList = () => {
    this.listEnd.scrollIntoView({ behavior: "smooth" });
  };

  _showError = (errorText) => {
    this.setState({
      errorDisplay: {
        isVisible: true,
        errorText,
      },
    });
  };

  _hideError = () => {
    this.setState({
      errorDisplay: {
        isVisible: false,
        errorText: "",
      },
    });
  };

  render() {
    const { options, newReasonTextInputValue, errorDisplay } = this.state;
    const { isVisible } = this.props;

    return (
      <>
        <div className="manageVisitReasonsModal">
          <Modal
            visible={isVisible}
            width="800"
            height="800"
            effect="fadeInUp"
            onClickAway={this._onDismiss}
          >
            <div className="manageVisitReasonsModalInnerWrapper">
              <div className="modalBody">
                <div className="header">
                  <h3>Manage Visit Reasons</h3>
                </div>
                <div className="optionsWrapper">
                  <div className="optionsRow headerRow">
                    <div className="namePart">Option Name</div>
                    <div className="actionPart">Actions</div>
                  </div>
                  <div className="optionsInnerWrapper">
                    {options.length ? (
                      <>
                        {options.map((option, optionIndex) => (
                          <VisitReasonRow
                            value={option}
                            onUpdate={(newText) =>
                              this._onOptionUpdate(option, newText)
                            }
                            onDelete={(e) => this._onOptionDelete(option)}
                            key={optionIndex}
                            showError={this._showError}
                            hideError={this._hideError}
                            options={options}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        <div className="noOptionWrapper">
                          No Options Available
                        </div>
                      </>
                    )}
                    <div
                      style={{ float: "left", clear: "both" }}
                      ref={(el) => {
                        this.listEnd = el;
                      }}
                    ></div>
                  </div>
                </div>
                <div className="createNewReason">
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (
                        newReasonTextInputValue &&
                        newReasonTextInputValue.length
                      ) {
                        this._onAdd();
                      }
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Type to add a new reason"
                      value={newReasonTextInputValue}
                      onChange={(e) =>
                        this._onNewReasonTextInputUpdate(e.target.value)
                      }
                    />
                    <button
                      type="submit"
                      className={
                        newReasonTextInputValue &&
                        newReasonTextInputValue.length
                          ? ""
                          : "disabledBtn"
                      }
                      onClick={(e) => {
                        if (
                          newReasonTextInputValue &&
                          newReasonTextInputValue.length
                        ) {
                          this._onAdd();
                        }
                      }}
                    >
                      <i className="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                      &nbsp; Add
                    </button>
                  </form>
                </div>
                <div className="errorDisplay">
                  {errorDisplay.isVisible && errorDisplay.errorText}
                </div>
              </div>
              <div className="bottomActionBar">
                <button className="saveBtn" onClick={this._onSave}>
                  Save
                </button>
                <button className="cancelBtn" onClick={this._onDismiss}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default ManageVisitReasons;
