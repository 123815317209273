const DEFAULT_FLOW_ACTIONS = {
  MY_REGISTRATION_ACTIONS: [
    { StatusID: 1, Status: "SignedIn" },
    { StatusID: 4, Status: "Pending Collection" },
    { StatusID: 3, Status: "Ready For Order Activation" },
    { StatusID: 7, Status: "Order Issues" },
    { StatusID: 6, Status: "Completed" },
    { StatusID: 10, Status: "Cancelled" },
  ],
  ORDER_ACTIVATION_ACTIONS: [
    { StatusID: 4, Status: "Pending Collection" },
    { StatusID: 7, Status: "Order Issues" },
    { StatusID: 9, Status: "Lab Order Issues" },
  ],
  PHLEBOTOMIST_ACTIONS: [
    { StatusID: 5, Status: "Called" },
    { StatusID: 8, Status: "No Answer" },
    { StatusID: 3, Status: "Ready For Order Activation" },
  ],
  PHLEBOTOMIST_CALLED_ACTIONS: [
    { StatusID: 6, Status: "Completed" },
    { StatusID: 8, Status: "No Answer" },
  ],
};

export default DEFAULT_FLOW_ACTIONS;
