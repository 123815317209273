import React, { Component } from "react";

class VisitReasonRow extends Component {
  state = {
    currentMode: "label", //  Two modes available to switch between: "label" and "input"
    inputValue: "",
  };

  componentDidMount() {
    this.setState({ inputValue: this.props.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }

  _turnOnEditMode = () => {
    this.setState({ currentMode: "input" });
  };

  _turnOffEditMode = () => {
    this.setState({ currentMode: "label" });
  };

  _onInputTextChange = (inputValue) => {
    this.setState({ inputValue });
  };

  _onSave = () => {
    const { onUpdate, options, showError, hideError } = this.props;
    const { inputValue } = this.state;
    const indexOfOption = options.findIndex(
      (option) => option.toLowerCase() === inputValue.toLowerCase()
    );
    if (indexOfOption > -1) {
      // Already exists
      showError("Duplicate option exists");
    } else {
      hideError();
      onUpdate(this.state.inputValue);
      this._turnOffEditMode();
    }
  };

  _onCancel = () => {
    this.setState({ inputValue: this.props.value }, () => {
      this._turnOffEditMode();
      this.props.hideError();
    });
  };

  render() {
    const { onDelete, value } = this.props;
    const { currentMode, inputValue } = this.state;

    if (currentMode === "label") {
      return (
        <>
          <div className="optionsRow">
            <div className="namePart editable" onClick={this._turnOnEditMode}>
              {value}
            </div>
            <div className="actionPart">
              <div className="iconBtn editBtn" onClick={this._turnOnEditMode}>
                <i className="fa fa-pencil" aria-hidden="true"></i>
              </div>{" "}
              <div className="iconBtn deleteBtn" onClick={onDelete}>
                <i className="fa fa-trash" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="optionsRow">
          <div className="namePart editable">
            <input
              type="text"
              value={inputValue}
              onChange={(e) => this._onInputTextChange(e.target.value)}
            />
          </div>
          <div className="actionPart">
            {inputValue && inputValue.length && (
              <div className="iconBtn saveBtn" onClick={this._onSave}>
                <i className="fa fa-check" aria-hidden="true"></i> Save
              </div>
            )}
            <div className="iconBtn cancelBtn" onClick={this._onCancel}>
              <i className="fa fa-times" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default VisitReasonRow;
